import React, { useEffect, useState } from "react";
import { getData, putData } from "../common/common";
import { userinfoStore } from "../zustand/userinfoStore";

import BookInfoModal from "./BookInfoModal";
import BookTab from "./BookTab";

import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import GroupIcon from "@mui/icons-material/Group";

import bdStyle from "../css/bookdetail.module.css";
import { useNavigate } from "react-router-dom";

export const BookDetail = (props) => {
  const user_info = userinfoStore((state) => state.user_info);
  const navigate = useNavigate();

  const [bookdata, SetBookdata] = useState([]);
  const [itemData, setItemData] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  async function fetchBookData() {
    const res = await getData(`book/${props.b_seq}/bottomcontent`);
    if (res.data) SetBookdata(res.data);
  }

  useEffect(() => {
    fetchBookData();

    getMyBook();
  }, [props.b_seq]);

  const getMyBook = async () => {
    const res = await getData(`/user/${user_info.u_seq}`);
    if (res.data.u_book) {
      setItemData(JSON.parse(res.data.u_book));
    }
  };

  const addBook = async () => {
    if (!user_info.u_seq) {
      alert("로그인 후 이용이 가능한 서비스 입니다.");
      return false;
    }

    let arr = [...itemData];
    if (!arr.includes(bookdata.b_seq)) {
      arr.push(bookdata.b_seq);
      bookdata.b_usernum++;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === bookdata.b_seq) {
          arr.splice(i, 1);
          i--;
        }
      }
    }
    let obj = {
      u_book: JSON.stringify(arr),
    };
    const res = await putData(`/user/${user_info.u_seq}`, { obj: obj });
    if (res.data === "success") {
      let obj2 = {
        b_usernum: bookdata.b_usernum,
      };
      const res2 = await putData(`/book/${bookdata.b_seq}`, { obj: obj2 });
      if (res2.data === "success") {
        fetchBookData();
        getMyBook();
        props.setForce(true);
      }
    }
  };

  const goodBook = async () => {
    if (!user_info.u_seq) {
      alert("로그인 후 이용이 가능한 서비스 입니다.");
      return false;
    }
    let arr;
    if (!bookdata.b_good || bookdata.b_good.length === 0) {
      arr = [];
    } else {
      arr = JSON.parse(bookdata.b_good);
    }

    if (!arr.includes(user_info.u_seq)) {
      arr.push(user_info.u_seq);
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === user_info.u_seq) {
          arr.splice(i, 1);
          i--;
        }
      }
    }
    let obj = {
      b_good: JSON.stringify(arr),
    };
    const res = await putData(`/book/${bookdata.b_seq}`, { obj: obj });
    if (res.data === "success") {
      fetchBookData();
      getMyBook();
    }
  };

  return (
    <>
      <div className={`${bdStyle.wrap} ${props.isShow && bdStyle.active}`}>
        <div className="d-flex">
          <HighlightOffSharpIcon
            className="ms-auto pointer"
            onClick={() => props.SetisShow(false)}
          />
        </div>
        <div className="d-flex align-items-center">
          <div
            className={`${bdStyle.bookimg} border me-3`}
            style={{ backgroundImage: `url(${bookdata.b_thumb})` }}
          ></div>
          <div className={`${bdStyle.booktext}`}>
            <h6>{bookdata.b_name}</h6>
            <p>
              {bookdata.b_publisher} / {bookdata.b_author} /{" "}
              {bookdata.b_pub_date} / {bookdata.b_sort} /{" "}
              {bookdata.b_target || "-"} / {bookdata.b_exam || "-"}
            </p>
            <p className="my-2 d-flex align-items-center">
              <GroupIcon className="me-1" style={{ fontSize: "20px" }} />
              현재 : {bookdata.users}
              {/* 누적 : {bookdata.b_usernum} */}
              <ThumbUpAltIcon
                className="ms-3 me-1"
                style={{ fontSize: "17px" }}
              />
              {bookdata.b_good ? JSON.parse(bookdata.b_good).length : 0}
            </p>
            {itemData.includes(bookdata.b_seq) && (
              <>
                <p className="text-primary">진행률 : - / 성과 : -</p>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start my-3 ms-2">
          {bookdata.b_good &&
          JSON.parse(bookdata.b_good).includes(user_info.u_seq) ? (
            <ThumbUpAltIcon
              className="pointer w-25"
              style={{ color: "#a82126" }}
              onClick={() => goodBook()}
            />
          ) : (
            <ThumbUpOffAltIcon
              className="pointer w-25"
              onClick={() => goodBook()}
            />
          )}

          {/* {itemData.includes(bookdata.b_seq) ? (
                <AddCircleIcon
                  className="pointer w-25"
                  style={{ color: "#a82126" }}
                  onClick={() => addBook()}
                />
              ) : (
                <ControlPointIcon
                  className="pointer  w-25"
                  onClick={() => addBook()}
                />
              )} */}
          <InfoOutlinedIcon
            className="pointer  w-25"
            onClick={() => setOpen(true)}
          />
          <ShoppingCartOutlinedIcon className="pointer w-25" />
        </div>
        {itemData.includes(bookdata.b_seq) && (
          <>
            <div
              className="d-flex align-items-center justify-content-between px-2 py-3 fw-bold border-top"
              style={{ fontSize: "14px" }}
              onClick={() => navigate(`/BookDetailView/${bookdata.b_seq}`)}
            >
              <span className="d-flex align-items-center">
                <InfoOutlinedIcon
                  style={{ fontSize: "20px", marginRight: "5px" }}
                />
                학습계획 및 상세정보
              </span>
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "15px" }} />
            </div>
          </>
        )}
      </div>
      <BookInfoModal open={open} setOpen={setOpen} b_seq={bookdata.b_seq} />
    </>
  );
};
