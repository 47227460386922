import React from "react";
import { InfoCircleFill } from "react-bootstrap-icons";

export const EmptyList = ({ text }) => {
  return (
    <div className="fw-bold my-5 d-flex align-items-center justify-content-center">
      <InfoCircleFill className="me-2 align-middle" />
      <div className="d-inline-block">{text}</div>
    </div>
  );
};
