import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getData } from "../common/common";
import { userinfoStore } from "../zustand/userinfoStore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const TopTen = (props) => {
  const [currData, setCurrData] = React.useState([
    { curri: "초급", data: [] },
    { curri: "중급", data: [] },
    { curri: "고급", data: [] },
    { curri: "전체", data: [] },
  ]);

  const type = React.useRef("");
  const openTopTen = (openType) => {
    type.current = openType;
    getAvg();
  };

  React.useEffect(() => {
    openTopTen("전체");
  }, []);

  const user_info = userinfoStore((state) => state.user_info);

  const getAvg = async () => {
    const res = await getData(`/test_detail/${props.b_seq}/topTen`);

    let temp = [...res.data];
    let totalArr = [];
    for (let k = 0; k <= 2; k++) {
      let scoreArray = [];

      if (temp[k].length > 0) {
        temp[k].forEach(function (v, i) {
          if (i === 0) {
            let obj = {
              id: v.u_seq,
              u_id: v.u_id,
              avg: Number(v.avg),
              curri: v.s_curri,
              count: 1,
            };
            scoreArray.push(obj);
          } else {
            let isCheck = true;
            scoreArray.forEach(function (vv, ii) {
              if (vv.id === v.u_seq) {
                vv.avg += Number(v.avg);
                vv.count += 1;
                isCheck = false;
              }
            });

            if (isCheck) {
              let obj = {
                id: v.u_seq,
                u_id: v.u_id,
                avg: Number(v.avg),
                curri: v.s_curri,
                count: 1,
              };
              scoreArray.push(obj);
            }
          }
        });
      }

      let scoreTemp = [];

      if (scoreArray.length > 0) {
        scoreArray.forEach(function (t) {
          scoreTemp.push({
            id: t.id,
            name: t.u_id,
            curri: t.curri,
            score: t.avg / t.count,
          });
        });
      }

      if (type.current !== "전체") {
        scoreTemp.sort(function (a, b) {
          return b.score - a.score;
        });
      }

      totalArr.push(scoreTemp);
    }

    let flatArr = [...totalArr].flat();
    flatArr.sort(function (a, b) {
      return b.score - a.score;
    });

    setCurrData([
      { curri: "초급", data: totalArr[0].slice(0, 10) },
      { curri: "중급", data: totalArr[1].slice(0, 10) },
      { curri: "고급", data: totalArr[2].slice(0, 10) },
      { curri: "전체", data: flatArr.slice(0, 10) },
    ]);
  };

  const Content = () => {
    if (type.current === "전체") {
      if (
        currData.filter((v) => v.curri === type.current)[0].data.length === 0
      ) {
        return <div>데이터가 존재하지 않습니다.</div>;
      } else {
        return (
          <table className="table table-borderd text-center">
            <thead>
              <tr>
                <th>순위</th>
                <th>아이디</th>
                <th>커리</th>
                <th>점수</th>
              </tr>
            </thead>
            <tbody>
              {currData
                .filter((v) => v.curri === type.current)[0]
                .data.map((v, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{v.name}</td>
                    <td>{v.curri}</td>
                    <td>{v.score}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        );
      }
    } else {
      if (
        currData.filter((v) => v.curri === type.current)[0].data.length === 0
      ) {
        return <div>데이터가 존재하지 않습니다.</div>;
      } else {
        return (
          <table className="table table-borderd text-center">
            <thead>
              <tr>
                <th>순위</th>
                <th>아이디</th>
                <th>점수</th>
              </tr>
            </thead>
            <tbody>
              {currData
                .filter((v) => v.curri === type.current)[0]
                .data.map((v, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{v.name}</td>
                    <td>{v.score}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        );
      }
    }
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen((prev) => !prev)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontWeight: "bold" }}
          >
            <div className="mb-3">
              <span
                className="btn btn-secondary btn-sm me-2"
                style={{ border: 0 }}
                onClick={() => openTopTen("전체")}
              >
                전체
              </span>
              <span
                className="btn btn-danger btn-sm me-2"
                style={{ background: "rgb(0, 143, 251)", border: 0 }}
                onClick={() => openTopTen("초급")}
              >
                초급
              </span>
              <span
                className="btn btn-danger btn-sm me-2"
                style={{ background: "rgb(0, 227, 150)", border: 0 }}
                onClick={() => openTopTen("중급")}
              >
                중급
              </span>
              <span
                className="btn btn-danger btn-sm me-2"
                style={{ background: "rgb(254, 176, 25)", border: 0 }}
                onClick={() => openTopTen("고급")}
              >
                고급
              </span>
            </div>
            {type.current} TOP 10
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <Content />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
