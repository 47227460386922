import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import ReactApexChart from "react-apexcharts";
import { getData } from "../common/common";
import { useScroll } from "framer-motion";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  overflowY: "scroll",
  maxHeight: "90%",
};

export const BookModal = ({
  open,
  setOpen,
  openModalType,
  b_seq,
  bookdata,
}) => {
  const [userData, setUserData] = React.useState([]);
  const [modalData, setModalData] = React.useState([]);
  const [modalData2, setModalData2] = React.useState([]);
  const [modalTableData, setTableData] = React.useState([]);
  const totalPer = React.useRef(0);

  React.useEffect(() => {
    getUser();
  }, []);

  React.useEffect(() => {
    // if (userData.length > 0) {
    //   myFetchDataAll();
    // }
    myFetchDataAll_fake();
  }, [userData]);

  const getUser = async () => {
    const res = await getData(`/test/${b_seq}/endUser`);
    console.log("res.data : ", res.data);
    setUserData(res.data);
  };

  async function myFetchDataAll() {
    let total = {
      times: 0,
      hd: 0,
    };
    let newbi = {
      times: 0,
      hd: 0,
    };
    // let tableData = [
    //   { curri: "초급", avg: 0, hd: 0, per: 0 },
    //   { curri: "중급", avg: 0, hd: 0, per: 0 },
    //   { curri: "고급", avg: 0, hd: 0, per: 0 },
    // ];
    let tableData = [
      {
        curri: "초급",
        avg: bookdata.b_fake_cho_1,
        hd: bookdata.b_fake_cho_2,
        per: bookdata.b_fake_cho_3,
      },
      {
        curri: "중급",
        avg: bookdata.b_fake_j_1,
        hd: bookdata.b_fake_j_2,
        per: bookdata.b_fake_j_3,
      },
      {
        curri: "고급",
        avg: bookdata.b_fake_go_1,
        hd: bookdata.b_fake_go_2,
        per: bookdata.b_fake_go_3,
      },
    ];

    userData.forEach(async function (v, i) {
      const res = await getData(`/book/${b_seq}/myAvg/total?u_seq=${v.u_seq}`);

      console.log("myFetchDataAll res : ", res.data);
      let curriCount = 0;
      // res.data.tableData.forEach(function (vv) {
      //   if (vv.curri) {
      //     curriCount++;
      //     tableData.forEach(function (item) {
      //       if (item.curri === vv.curri) {
      //         item.avg += vv.avg;
      //         item.hd += vv.hd;
      //         item.per += 1;
      //         totalPer.current += 1;
      //       }
      //     });
      //   }
      // });
      if (curriCount === 3) {
        newbi.times = newbi.times + res.data.times;
        newbi.hd = newbi.hd + res.data.hd;
      }
      total.times = total.times + res.data.times;

      total.hd = total.hd + res.data.hd;

      if (i === userData.length - 1) {
        let temp = [];
        let temp2 = [];
        // if (openModalType === "학습완료까지 평균 학습시간") {
        //   //temp = [total.times, newbi.times];
        // } else if (openModalType === "학습완료까지 평균 회독 수") {
        //   //temp = [total.hd, newbi.hd];
        // }
        temp = [bookdata.b_fake_c_alluser];
        temp2 = [bookdata.b_fake_h_alluser];

        setModalData(temp);
        setModalData2(temp2);
        setTableData(tableData);
      }
    });

    //setModalData(temp);
  }

  function myFetchDataAll_fake() {
    let tableData = [
      {
        curri: "초급",
        avg: bookdata.b_fake_cho_1,
        hd: bookdata.b_fake_cho_2,
        per: bookdata.b_fake_cho_3,
      },
      {
        curri: "중급",
        avg: bookdata.b_fake_j_1,
        hd: bookdata.b_fake_j_2,
        per: bookdata.b_fake_j_3,
      },
      {
        curri: "고급",
        avg: bookdata.b_fake_go_1,
        hd: bookdata.b_fake_go_2,
        per: bookdata.b_fake_go_3,
      },
    ];

    let temp = [];
    let temp2 = [];

    temp = [bookdata.b_fake_c_alluser];
    temp2 = [bookdata.b_fake_h_alluser];

    setModalData(temp);
    setModalData2(temp2);
    setTableData(tableData);
  }

  const chartInfo = {
    series: [
      {
        data: modalData,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: "auto",
        toolbar: { show: false },
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "30%",
          labels: { show: false },
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: ["전체 이용자"],
      },
    },
  };

  const chartInfo2 = {
    series: [
      {
        data: modalData2,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: "auto",
        toolbar: { show: false },
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "30%",
          labels: { show: false },
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: ["전체 이용자"],
      },
    },
  };
  // modalTableData

  const tableItem = (cn) => {
    let obj = {
      avg: "-",
      hd: "-",
      per: "-",
    };
    console.log("modalTableData >>", modalTableData);
    if (modalTableData.length > 0) {
      if (modalTableData.filter((v) => v.curri === cn).length > 0) {
        obj.avg = modalTableData.filter((v) => v.curri === cn)[0].avg + "점";
        obj.hd = modalTableData.filter((v) => v.curri === cn)[0].hd + "회독";
        // obj.per =
        //   (modalTableData.filter((v) => v.curri === cn)[0].per /
        //     totalPer.current) *
        //     100 +
        //   "%";
        obj.per = modalTableData.filter((v) => v.curri === cn)[0].per + "%";
      }
    }

    return obj;
  };

  const CurriTable = () => {
    return (
      <TableContainer sx={{ marginBottom: "1.5rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className="fw-bold">초급</TableCell>
              <TableCell className="fw-bold">중급</TableCell>
              <TableCell className="fw-bold">고급</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="fw-bold" style={{ padding: "7px 16px" }}>
                암기율
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {tableItem("초급").avg}
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {tableItem("중급").avg}
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {tableItem("고급").avg}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="fw-bold" style={{ padding: "7px 16px" }}>
                회독수
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {" "}
                {tableItem("초급").hd}
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {" "}
                {tableItem("중급").hd}
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {" "}
                {tableItem("고급").hd}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="fw-bold" style={{ padding: "7px 16px" }}>
                선택비중
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {tableItem("초급").per}
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {tableItem("중급").per}
              </TableCell>
              <TableCell style={{ padding: "5px 16px" }}>
                {tableItem("고급").per}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="fw-bold"
          ></Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {/* {openModalType === "커리큘럼별 통계" ? (
              <CurriTable />
            ) : (
              <ReactApexChart
                options={chartInfo.options}
                series={chartInfo.series}
                type="bar"
                height={350}
              />
            )} */}
            <div className="text-center">학습완료까지 평균 학습시간</div>
            <ReactApexChart
              options={chartInfo.options}
              series={chartInfo.series}
              type="bar"
              height={150}
            />
            <hr />
            <div className="text-center">학습완료까지 평균 회독 수</div>
            <ReactApexChart
              options={chartInfo2.options}
              series={chartInfo2.series}
              type="bar"
              height={150}
            />
            <hr />
            <div className="text-center mb-3">커리큘럼별 통계</div>
            <CurriTable />
          </Typography>
          <div
            className="text-danger text-end mb-2"
            style={{ fontSize: "13px" }}
          >
            ※ 기초수험생기준
          </div>
        </Box>
      </Modal>
    </div>
  );
};
