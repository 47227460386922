import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export const Confirm = ({
  title,
  message,
  isConfirm = false,
  setConfirm = () => {},
  sendHandler = () => {},
  cancelHandler = () => {},
  setConfirmText = () => {},
}) => {
  useEffect(() => {
    if (isConfirm) {
      let leftLabel = "",
        rightLabel = "";
      if (title === "제출하시겠습니까?") {
        leftLabel = "확인";
        rightLabel = "닫기";
      } else if (title === "응시를 취소하시겠습니까?") {
        leftLabel = "응시취소";
        rightLabel = "뒤로가기";
      } else if (title === "시험을 중단하시겠습니까?") {
        leftLabel = "중단하기";
        rightLabel = "계속하기";
      } else {
        leftLabel = "응시하기";
        rightLabel = "뒤로가기";
      }

      const rightOnclick = () => {
        if (title === "제출하시겠습니까?") {
          setConfirm((prev) => !prev);
        } else if (title === "응시를 취소하시겠습니까?") {
          setConfirm((prev) => !prev);
          setConfirmText({
            title: "제출하시겠습니까?",
            message: "결과조회 페이지에서 확인이 가능합니다.",
          });
        } else if (title === "시험을 중단하시겠습니까?") {
          setConfirm((prev) => !prev);
          setConfirmText({
            title: "제출하시겠습니까?",
            message: "결과조회 페이지에서 확인이 가능합니다.",
          });
        } else {
          cancelHandler();
        }
      };
      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: leftLabel,
            onClick: sendHandler,
          },
          {
            label: rightLabel,
            onClick: rightOnclick,
          },
        ],
      });
    }
  }, [isConfirm]);
  return <></>;
};
