/* eslint-disable react/jsx-no-bind */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Container, Button } from "react-bootstrap";
import {
  Link,
  UNSAFE_NavigationContext as NavigationContext,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Header } from "../components/Header";
import { Timer } from "../components/Timer";
import { Timer_v2 } from "../components/Timer_v2";
import { getData, postData, putData } from "../common/common";

import testStyles from "../css/test.module.css";
import { CheckForm } from "../components/CheckForm";
import correct from "../img/correct.png";
import wrong from "../img/wrong.png";
import { Confirm } from "../components/Confirm";
import { Toast } from "../components/Toast";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";
import { ResultComponent } from "../components/ResultComponent";
import { EmptyList } from "../components/EmptyList";

import {
  ArrowDownCircleFill,
  ArrowDownUp,
  ArrowUpCircleFill,
  Printer,
  JournalX,
  ListUl,
  UiRadiosGrid,
} from "react-bootstrap-icons";
import { PDFExport } from "@progress/kendo-react-pdf";
import { defineFont } from "@progress/kendo-drawing/pdf";

// defineFont({
//   "Arial Unicode MS": "../fonts/ARIALUNI.TTF",
// });

export function QuickTest(props) {
  const user_info = userinfoStore((state) => state.user_info);

  useEffect(() => {
    if (!user_info.u_seq) {
      window.sessionStorage.setItem("quizUrl", window.location.href);
      window.location.replace("/login");
    }
  }, []);
  /* 시험 쳤는지 유무 */
  const [isEnd, setEnd] = useState(false);
  /* -------------------------*/

  const [isLoading, setLoading] = useState(false);
  const [omrState, SetOmrState] = useState(false);
  const [title, setTitle] = useState("");
  const [cate, setCate] = useState("");
  const [testInfo, setTestInfo] = useState({});
  const [data, setData] = useState([]);

  const [timeAttack, SetTimeAttack] = useState("N");

  const [cor, setCor] = useState([]);

  const [detail, setDetail] = useState([]);

  let params = useParams();

  const navigate = useNavigate();

  const [min, setMin] = useState(0);
  const [min2, setMin2] = useState(0);
  const [isConfirm, setConfirm] = useState(false);
  const [isToast, setToast] = useState(false);
  const [isToastM, setToastM] = useState(false);

  const [isStep, setStep] = useState("");

  const [tempTime, setTempTime] = useState({
    isTime: "N",
    value: "",
  });

  const [isWhen, setWhen] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [page, setPage] = useState(1);
  const [prev, setPrev] = useState(1);
  const [pageTemp, setPageTemp] = useState([]);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);

  const handleChange = (e) => {
    if (!isPage) {
      setIsPage(true);
    }

    setPrev(page);
    setPage(e);
  };
  const Top = () => {
    let topArray = [];

    for (let i = 0; i < total; i++) {
      if (i === total - 1) {
        let obj = {
          start: i * 100 + 1,
          end: count,
        };

        topArray.push(obj);
      } else {
        let obj = {
          start: i * 100 + 1,
          end: (i + 1) * 100,
        };

        topArray.push(obj);
      }
    }

    return topArray.map((v, i) => (
      <span
        key={i + 1}
        className={`${testStyles.topSpan} pointer d-inline-block p-2 ${
          page === i + 1 ? testStyles.topOn : null
        }`}
        onClick={() => handleChange(i + 1)}
      >
        {v.start} ~ {v.end}
      </span>
    ));
  };

  const Numbering = ({ index }) => {
    if (page > 1) {
      const upIndex = 100 * (page - 1);
      return <span className={testStyles.font}>{upIndex + index + 1}. </span>;
    } else {
      return <span className={testStyles.font}>{index + 1}. </span>;
    }
  };

  function useBlocker(blocker) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
      if (!isWhen) return;

      const unblock = navigator.block((tx) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };
        blocker(autoUnblockingTx);
      });
      return unblock;
    }, [navigator, blocker, isWhen]);
  }

  function usePrompt() {
    const blocker = useCallback(
      (tx) => {
        cancelTest();
        // if (testInfo.t_type === "전국모의고사") {

        // } else {
        //   navigate(-1);
        // }
      },
      [testInfo.t_type]
    );

    useBlocker(blocker, isWhen);
  }

  usePrompt();

  const sendFetchData = async () => {
    if (
      confirmText.title === "제출하시겠습니까?" ||
      confirmText.title === "응시를 취소하시겠습니까?"
    ) {
      // if (res.data.type === "success") {
      //   endTest();
      //   setConfirm(false);
      //   setWhen(false);
      // }
      //console.log("sendData : ", sendData);

      const tempData = [...data];

      tempData.forEach(function (v) {
        detail.forEach(function (vv) {
          if (Number(vv.td_num) === Number(v.q_seq)) {
            if (Number(v.q_answer) === Number(vv.td_user_num)) {
              v.correct = 1;
            } else {
              v.correct = 0;
            }

            v.user_num = vv.td_user_num;
          }
        });
      });

      console.log("last tempData : ", tempData);

      setData(tempData);
      setConfirm(false);
      setWhen(false);
      isEnd(true);
    } else if (confirmText.title === "시험을 중단하시겠습니까?") {
      navigate(-1);
    } else {
      openTest();
    }
  };

  const openTest = () => {
    setStep("start");
    setMin(tempTime.value);
    SetTimeAttack(tempTime.isTime);

    setConfirmText({
      title: "제출하시겠습니까?",
      message: "결과조회 페이지에서 확인이 가능합니다.",
    });

    setConfirm(false);
  };

  const [confirmText, setConfirmText] = useState({
    title: "제출하시겠습니까?",
    message: "결과조회 페이지에서 확인이 가능합니다.",
  });

  const cancelHandler = () => {
    if (
      confirmText.title === "제출하시겠습니까?" ||
      confirmText.title === "응시를 취소하시겠습니까?"
    ) {
      setConfirm((prev) => !prev);
    } else {
      navigate(-1);
    }
  };
  const insertTestUser = async () => {
    if (user_info.u_seq && params.target) {
      let testObj = {
        t_seq: params.id,
        u_seq: user_info.u_seq,
        tu_u_seq: params.target,
      };
      const res = await postData(`/test_user`, { obj: testObj });
    }
  };
  useEffect(() => {
    if (params.type === "link") {
      insertTestUser();
    }
  }, []);

  useEffect(() => {
    if (isStep === "normal") {
      setConfirmText({
        title: "",
        message: "시험을 시작하시겠습니까?",
      });
    } else if (isStep === "start") {
      if (!isEnd) {
        setMin(tempTime.value);
        SetTimeAttack(tempTime.isTime);
      }
    }
  }, [isStep]);

  useEffect(() => {
    if (confirmText.title !== "제출하시겠습니까?") {
      setConfirm(true);
    }
  }, [confirmText.title]);

  useEffect(() => {
    drawContent();
  }, [page]);

  useEffect(() => {
    console.log("detail : ", detail);

    window.sessionStorage.setItem(`detail_${page}`, JSON.stringify(detail));
  }, [detail]);

  useEffect(() => {
    if (params.type !== "ox" && params.type !== "end") {
      startTest();
    }

    // drawTitle();
  }, []);

  useEffect(() => {
    if (isLoading) {
      endCheck();
    }
  }, [isLoading]);

  useEffect(() => {
    if (cor.length > 0) {
      setEnd(true);
    }
  }, [cor]);

  useEffect(() => {
    if (isEnd) {
      setData(drawCorrectAndWrong());
    }
  }, [isEnd]);

  const endCheck = async () => {
    const res = await getData(
      `/test_user/${params.id}/my?u_seq=${user_info.u_seq}`
    );
    setLoading(false);
    if (res.data.isEnd) {
      if (res.data.cor) setCor(res.data.cor);
    }
  };

  const drawCorrectAndWrong = () => {
    setWhen(false);
    let tempData = [...data];
    tempData.forEach(function (v) {
      cor.forEach(function (vv) {
        if (Number(vv.td_num) === Number(v.q_seq)) {
          v.correct = vv.td_check;
          v.user_num = vv.td_user_num;
        }
      });
    });

    return tempData;
  };

  const startTest = async () => {
    const sRes = await getData(
      `/test_user/${params.id}/start_check?u_seq=${user_info.u_seq}`
    );

    if (!sRes.data.isStart) {
      await putData(`/test_user/${params.id}/start?u_seq=${user_info.u_seq}`);
    }
  };

  const drawContent = async () => {
    const res = await getData(
      `/questions/${params.id}/all3?ppp=100&page=${page}`
    );
    //const temp = [res.data.content.map(v => ...v)]
    //setTitle(res.data.book);
    //setCate(res.data.b_cate);
    setTotal(res.data.totalPage);
    setCount(res.data.totalCount);
    let pt = [...pageTemp];
    let pageCheck = true;
    pt.forEach(function (v) {
      if (v === page) {
        pageCheck = false;
      }
    });

    if (pageCheck) {
      pt = [...pageTemp, page];
      setPageTemp(pt);
    }

    const temp = res.data.title.map((v) => {
      if (testInfo.t_type === "암기관리") {
        return {
          ...v,
          content: JSON.parse(v.q_contents),
        };
      } else {
        if (v.q_title.indexOf("①") !== -1) {
          console.log("v.q_title : ", v.q_title);
          var devide = v.q_title.split("①");

          var num1 = devide[1].split("②");
          var num2 = num1[1].split("③");
          var num3 = num2[1].split("④");
          var num4 = num3[1].split("⑤");

          //["①", "②", "③", "④", "⑤"];
          //devide[1].split('④')
          return {
            ...v,
            q_title: devide[0],
            content: [num1[0], num2[0], num3[0], num4[0]],
          };
        } else {
          return {
            ...v,
            content: JSON.parse(v.q_contents),
          };
        }
      }
    });

    let dArray = [];
    temp.forEach(function (t) {
      const obj = {
        t_seq: params.id,
        u_seq: user_info.u_seq,
        td_num: t.q_seq,
        td_user_num: -1,
      };
      dArray.push(obj);
    });

    if (isPage) {
      //window.sessionStorage.setItem(`detail_${page}`);
      if (window.sessionStorage.getItem(`detail_${page}`)) {
        let tempDetail = JSON.parse(
          window.sessionStorage.getItem(`detail_${page}`)
        );
        console.log("tempDetail : ", tempDetail);
        setDetail(tempDetail);
      } else {
        setDetail(dArray);
      }
    } else {
      setDetail(dArray);
    }
    window.sessionStorage.setItem(`data_${page}`, JSON.stringify(temp));
    setData(temp);
    setLoading(true);
  };

  const radioChange = (e) => {
    const obj = {
      t_seq: params.id,
      u_seq: user_info.u_seq,
      td_num: e.target.dataset.target,
      td_user_num: e.target.value,
    };

    let temp = [...detail];
    let isChk = false;

    temp.forEach(function (v) {
      console.log("v.td_num : ", v.td_num);
      console.log("e.target.dataset.target : ", e.target.dataset.target);
      if (Number(v.td_num) === Number(e.target.dataset.target)) {
        console.log("???");
        isChk = true;
        return false;
      }
    });

    if (isChk) {
      const t = temp.map((v) =>
        Number(v.td_num) === Number(e.target.dataset.target)
          ? { ...v, td_user_num: e.target.value }
          : v
      );
      setDetail(t);
    } else {
      setDetail([...detail, obj]);
    }
  };

  const toggleOMR = () => {
    SetOmrState((prev) => !prev);
  };

  useEffect(() => {
    if (!omrState) {
      if (detail.length > 0) {
        document.querySelectorAll(".form-check-input").forEach(function (v) {
          detail.forEach(function (vv) {
            if (Number(vv.td_num) === Number(v.dataset.target)) {
              if (Number(vv.td_user_num) === Number(v.value)) {
                v.checked = true;
              }
            }
          });
        });
      }
    }
  }, [omrState]);

  function button() {
    if (!omrState) {
      return (
        <Button
          variant="success"
          type="submit"
          className="ms-auto"
          onClick={toggleOMR}
        >
          <UiRadiosGrid className={testStyles.margin} />
        </Button>
      );
    } else {
      return (
        <Button
          variant="success"
          type="submit"
          className="ms-auto"
          onClick={toggleOMR}
        >
          <ListUl className={testStyles.margin} />
        </Button>
      );
    }
  }

  const [isScroll, setScroll] = useState(document.body.scrollHeight);
  const scrollCatch = () => {
    let scrollValue = "";
    if (window.pageYOffset > 0) {
      scrollValue = 0;
    } else {
      scrollValue = document.body.scrollHeight;
    }
    setScroll(scrollValue); // window 스크롤 값을 ScrollY에 저장
  };

  const scrollHandler = (scrollValue) => {
    // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top: scrollValue,
      behavior: "smooth",
    });
    setScroll(0); // ScrollY 의 값을 초기화
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", scrollCatch);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener("scroll", scrollCatch); // addEventListener 함수를 삭제
    };
  });

  function UpDown() {
    if (isScroll !== 0) {
      return (
        <ArrowDownCircleFill
          className={testStyles.upDown}
          onClick={() => scrollHandler(isScroll)}
        />
      );
    } else {
      return (
        <ArrowUpCircleFill
          className={testStyles.upDown}
          onClick={() => scrollHandler(isScroll)}
        />
      );
    }
  }

  const sendTest = () => {
    let isContinue = true;
    let qnumArr = [];

    if (testInfo.t_yn_time !== "Y") {
      data.forEach(function (dd, ii) {
        detail.forEach(function (d, i) {
          if (Number(dd.q_seq) === Number(d.td_num)) {
            if (d.td_user_num === -1) {
              const upIndex = 100 * (page - 1);
              if (page > 1) {
                qnumArr.push(upIndex + ii + 1);
              } else {
                qnumArr.push(ii + 1);
              }

              isContinue = false;
              setMsg(`${qnumArr.join(",")}번 문제에 정답을 체크해주세요.`);
              return false;
            }
          }
        });
      });
    }

    if (isContinue) {
      setConfirm(true);
    }

    //setConfirm(true);
  };

  const endTest = async () => {
    const res = await putData(
      `/test_user/${params.id}/end?u_seq=${user_info.u_seq}`
    );

    if (res.data === "success") {
      //alert("제출 완료되었습니다.");
      setToast(true);
      setLoading(true);
      //navigate(-1);
    }
  };

  const Grading = ({ gr }) => {
    if (gr === 1) {
      return (
        <img src={correct} className={`${testStyles.grading}`} alt="correct" />
      );
    } else if (gr === 0) {
      return (
        <img src={wrong} className={`${testStyles.grading}`} alt="wrong" />
      );
    } else {
      return <></>;
    }
  };

  const tdActive = (v, i, no) => {
    let className = ``;

    if (isEnd) {
      if (Number(v.user_num) === i) className = `${testStyles.active}`;
      if (Number(v.q_answer) === i) className += " text-danger";
    } else {
      detail.forEach(function (vv) {
        if (Number(vv.td_user_num) === i && Number(vv.td_num) === Number(no)) {
          className = `${testStyles.active}`;
        }
        return false;
      });
    }

    return className;
  };

  const omrActive = (no, target) => {
    const obj = {
      t_seq: params.id,
      u_seq: user_info.u_seq,
      td_num: target,
      td_user_num: no,
    };

    let temp = [...detail];
    let isChk = false;

    temp.forEach(function (v) {
      if (Number(v.td_num) === Number(target)) {
        isChk = true;
        return false;
      }
    });

    if (isChk) {
      const t = temp.map((v) =>
        Number(v.td_num) === Number(target) ? { ...v, td_user_num: no } : v
      );
      setDetail(t);
    } else {
      setDetail([...detail, obj]);
    }
  };

  const [msg, setMsg] = useState("");

  const toastMsg = () => {
    if (!msg) {
      return (
        <div className="toast-div">
          <div>수고하셨습니다!</div>
          <div>결과조회 페이지에서 확인이 가능합니다.</div>
        </div>
      );
    } else {
      return (
        <div className="toast-div">
          <div>{msg}</div>
        </div>
      );
    }
  };

  const labelArray = ["①", "②", "③", "④", "⑤"];

  const [scrap, setScrap] = useState([]);

  useEffect(() => {
    getScrap();
  }, []);

  const getScrap = async () => {
    const res = await getData(`/scrap/${params.id}/?u_seq=${user_info.u_seq}`);

    setScrap(res.data);
  };

  const onScrap = (e) => {
    const tempScrap = [...scrap];
    let isSCrapCheck = false;
    tempScrap.forEach(function (x) {
      if (Number(x.id) === Number(e)) {
        isSCrapCheck = true;
      }
    });

    if (isSCrapCheck) {
      const filter = tempScrap.filter((v) => v.id !== e);

      setScrap([...filter]);
      setMsg("해당 문제가 스크랩 취소 되었습니다.");
    } else {
      setScrap([...tempScrap, { id: e, type: "스크랩" }]);
      setMsg("해당 문제가 스크랩 되었습니다.");
    }
  };

  useEffect(() => {
    if (msg) {
      if (msg.indexOf("스크랩") !== -1) {
        scrapHandler();
      } else {
        setToastM(true);
      }
    }
  }, [msg]);

  const scrapHandler = async () => {
    let obj = {
      u_seq: user_info.u_seq,
      s_group: JSON.stringify(scrap),
    };

    const res = await postData(`/scrap`, { obj: obj });

    if (res.data === "success") {
      if (params.type === "ox") {
        //drawTitle();
        drawContent();
      }
      setToastM(true);
    }
  };

  useEffect(() => {
    if (isToastM) {
      setTimeout(() => {
        setMsg("");
        setToastM(false);
      }, 2000);
    }
  }, [isToastM]);

  const cancelTest = () => {
    setConfirmText({
      title: "응시를 취소하시겠습니까?",
      message: "취소시 재응시가 불가능 합니다.",
    });
  };

  const backTest = () => {
    if (isStep === "wait") {
      navigate(-1);
    } else {
      setConfirmText({
        title: "시험을 중단하시겠습니까?",
        message: "",
      });
    }
  };

  const pdfExportComponent = useRef(null);
  const handleExportWithComponent = () => {
    pdfExportComponent.current.save();
  };

  const testWord = (ex) => {
    let str = "";
    ex.split("").forEach(function (v) {
      if (v === "(") {
        str += `<span class="${testStyles.font} ${testStyles.under} text-primary">`;
      } else if (v === ")") {
        str += "</span>";
      } else if (v === "#") {
        str += '"';
      } else {
        str += v;
      }
    });

    return str;
  };

  async function limitUpdate(limit) {
    await putData(
      `/test_user/${params.id}/limit?u_seq=${user_info.u_seq}&tu_limit=${limit}`
    );
  }

  function ScopeTest() {
    let scope = data.sort((a, b) => a.q_num - b.q_num);

    return testInfo.b_copyright === 0 ? (
      <p>
        <span className="fw-bold">{scope[0]?.q_cate}</span> ~{" "}
        <span className="fw-bold">{scope[scope.length - 1]?.q_cate}</span>{" "}
        입니다.
      </p>
    ) : (
      <p>
        <span className="fw-bold">{1}</span>번 ~{" "}
        <span className="fw-bold">{count}</span>번 입니다.
      </p>
    );
  }

  return (
    <>
      {/* {params.type === "end" && <Print />} */}
      <UpDown />
      {isEnd ? (
        <Header title={title} />
      ) : timeAttack === "Y" ? (
        <Header title={min && <Timer title={title} bun={min} />} />
      ) : (
        <Header title={title} />
      )}
      {isEnd ? (
        <div className={`${testStyles.bottombtn} p-3`}>
          <Button
            variant="secondary"
            type="submit"
            className="w-100"
            onClick={() => navigate(-1)}
          >
            목록으로
          </Button>
        </div>
      ) : params.type === "ox" ? (
        <div className={`${testStyles.bottombtn} p-3`}>
          <Button
            variant="secondary"
            type="submit"
            className="w-100"
            onClick={() => navigate(-1)}
          >
            시험종료
          </Button>
        </div>
      ) : (
        <div className={`${testStyles.bottombtn} p-3`}>
          <Button
            variant="primary"
            type="submit"
            className="w-100"
            onClick={() => sendTest()}
          >
            채점하기
          </Button>
          <span className="mx-2"> </span>
          <Button
            variant="secondary"
            type="submit"
            className="w-100"
            onClick={() =>
              testInfo.t_type === "성과관리" ? cancelTest() : backTest()
            }
          >
            {testInfo.t_type === "성과관리" ? "응시취소" : "목록으로"}
          </Button>
        </div>
      )}
      <PDFExport ref={pdfExportComponent} paperSize="A4">
        <Container style={{ background: "#fff", maxWidth: "768px" }}>
          {/* <div
            style={{
              position: "fixed",
              top: "60px",
              background: "#fff",
              zIndex: 1000,
              borderTop: "1px solid #ddd",
              borderBottom: "1px solid #ddd",
              width: "100%",
              left: 0,
              overflowX: "scroll",
            }}
          >
            <div style={{ width: total * 100 + "px" }}>
              <Top />
            </div>
          </div> */}
          <div className={testStyles.nameWrap} style={{ marginTop: "70px" }}>
            {(params.type === "end" || isEnd) && (
              <div className="d-flex justify-content-center fw-bold mb-2">
                맞은갯수 :
                <span className="text-success ms-1">
                  {data.filter((v) => v.correct === 1).length}
                </span>{" "}
                / {data.length}
              </div>
            )}
            {/* <span>응시일자 : {testInfo.t_start_date} / </span> */}
            <span> {user_info.u_name} 님</span>
          </div>
          <main className={testStyles.main}>
            <div className={testStyles.testTop}>
              {testInfo.t_type} <span>{testInfo.b_cate}</span>
            </div>
            <div className={testStyles.testName}>단어장 시험</div>
            {params.type === "ox" && data.length === 0 && (
              <EmptyList text={"등록된 스크랩이 존재하지 않습니다."} />
            )}
            {omrState ? (
              <div class={testStyles.omrdiv}>
                <table className="table text-center mb-0">
                  <tbody>
                    {data.map((v, i) => (
                      <tr className="tr-omr" key={i}>
                        <td>{i + 1}</td>
                        {labelArray.map(
                          (label, ii) =>
                            ii + 1 <= v.content.length && (
                              <td
                                className={tdActive(v, ii + 1, v.q_seq)}
                                data-no={ii + 1}
                                key={ii}
                                onClick={() => omrActive(ii + 1, v.q_seq)}
                              >
                                {label}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              data.map((v, i) => (
                <div className="mb-5 position-relative" key={v.q_seq}>
                  <Grading gr={v.correct} />
                  {cate === "영어" ? (
                    <p className={testStyles.font}>
                      <Numbering index={i} />
                      {
                        <span
                          className={testStyles.font}
                          dangerouslySetInnerHTML={{
                            __html: testWord(v.q_explain),
                          }}
                        ></span>
                      }
                    </p>
                  ) : (
                    <pre className={testStyles.font}>
                      <Numbering index={i} /> {v.q_title.replace("•", "")}
                    </pre>
                  )}

                  <CheckForm
                    index={i}
                    radioChange={radioChange}
                    v={v}
                    detail={
                      detail.filter(
                        (d) => Number(d.td_num) === Number(v.q_seq)
                      )[0]?.td_user_num
                    }
                  />
                  {(params.type === "ox" || params.type === "end" || isEnd) && (
                    <>
                      {v.q_comment && (
                        <div className={`${testStyles.preTitle} text-primary`}>
                          해설
                        </div>
                      )}

                      {v.q_comment && (
                        <pre className={testStyles.pre}>{v.q_comment}</pre>
                      )}
                    </>
                  )}
                  {/* {(params.type === "ox" || params.type === "end") && (
                                <ResultComponent
                                  v={v}
                                  scrap={scrap}
                                  onScrap={onScrap}
                                  type={params.type}
                                  tid={params.id}
                                />
                              )} */}
                </div>
              ))
            )}
          </main>
        </Container>
      </PDFExport>
      {params.type === "end" || (
        <Confirm
          title={confirmText.title}
          message={confirmText.message}
          isConfirm={isConfirm}
          setConfirm={setConfirm}
          setConfirmText={setConfirmText}
          sendHandler={sendFetchData}
          cancelHandler={cancelHandler}
        />
      )}
      {isToast && (
        <Toast
          text={() => toastMsg()}
          type="info"
          time={2000}
          isToast={isToast}
          setToast={setToast}
        />
      )}
      {isToastM && (
        <Toast
          text={() => toastMsg("")}
          type="info"
          time={2000}
          isToast={isToastM}
          setToast={setToastM}
        />
      )}
    </>
  );
}
