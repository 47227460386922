import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Link, useNavigate } from "react-router-dom";

import { getData } from "../common/common";

import { Paging } from "../components/Paging";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export const Notice = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalcount, setTotalcount] = useState(0);

  useEffect(() => {
    getScrap();
  }, []);

  const getScrap = async () => {
    const res = await getData(`/notice/paging?&page=${page}&ppp=30`);
    setData(res.data.rows);
    setTotalcount(res.data.totalCount);
  };

  const goPage = (e) => {
    setPage(e);
  };

  const navigate = useNavigate();

  const goDetail = (id) => {
    navigate(`/notice_detail/${id}`);
  };

  return (
    <>
      <Header centerText="공지사항" />
      <Wrapper margin={170}>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">#</StyledTableCell>
                <StyledTableCell align="center">제목</StyledTableCell>
                <StyledTableCell align="center">등록일자</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((v, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {totalcount - ((page - 1) * 30 + i)}
                  </StyledTableCell>
                  <StyledTableCell
                    className="pointer"
                    align="left"
                    onClick={() => goDetail(v.n_seq)}
                  >
                    {v.n_title}
                  </StyledTableCell>
                  <StyledTableCell align="center">{v.n_date}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Wrapper>
      <div className="py-5">
        <Paging
          activePage={page}
          itemsCountPerPage={30}
          totalItemsCount={totalcount}
          onChange={goPage}
        ></Paging>
      </div>
    </>
  );
};
