import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import bdStyle from "../css/bookdetail.module.css";

//components
import { Header } from "../components/Header";
import BookTab from "../components/BookTab";
import gImage from "../img/gDefault.jpg";
//zustand
import { userinfoStore } from "../zustand/userinfoStore";

//axios
import { getData, postData, putData, currentDateTime } from "../common/common";

import GroupIcon from "@mui/icons-material/Group";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { BookModal } from "../components/BookModal";
import useCountUp from "../hook/useCountUp";
import { motion } from "framer-motion";
export const BookDetailView = () => {
  const user_info = userinfoStore((state) => state.user_info);
  const navigate = useNavigate();
  const params = useParams();

  const [bookdata, SetBookdata] = useState([]);
  const [itemData, setItemData] = React.useState([]);
  const [mydata, SetMydata] = useState([]);

  const [applyState, SetApplyState] = useState([]);

  async function fetchApplyState() {
    const res = await getData(
      `/apply/applystate/0?u_seq=${user_info.u_seq}&b_seq=${params.id}`
    );
    SetApplyState(res.data);
  }

  async function updateBookUserNum() {
    const res = await putData(
      `/book/${params.id}/updateUserNum?time=${currentDateTime()}`
    );
  }

  const fetchBookData = useCallback(async () => {
    const res = await getData(`book/${params.id}/bottomcontent`);
    if (res.data) SetBookdata(res.data);
  }, []);

  const getMyBook = async () => {
    const res = await getData(`/user/${user_info.u_seq}`);
    if (res.data.u_book) {
      let arr = JSON.parse(res.data.u_book);
      arr = arr.map((v) => Number(v));
      setItemData(arr);
    }
  };

  async function fetchMyInfo() {
    const res = await getData(
      `test/${user_info.u_seq}/bookinfo?b_seq=${params.id}&b_type=${params.type}`
    );
    if (res.data) SetMydata(res.data);
  }

  useEffect(() => {
    if (params.id) {
      fetchBookData();
    }
    fetchMyInfo();
    fetchApplyState();
    getMyBook();

    updateBookUserNum();
  }, []);

  async function finalApply() {
    if (window.confirm("전범위 시험을 신청하시겠습니까?")) {
      let obj = {
        u_seq: user_info.u_seq,
        b_seq: params.id,
      };
      const res = await postData(`/testall/apply/insert`, { obj: obj });
      if (res.data === "success") {
        alert("신청 되었습니다!");
        fetchMyInfo();
      }
    }
  }

  const itemApplyHanlder = () => {
    navigate(`/BookApply/${params.id}`);
  };

  const imageBg = (thm) => {
    if (!thm) {
      return gImage;
    } else {
      return thm;
    }
  };

  const [open, setOpen] = useState(false);
  const [openModalType, setOpenModalType] = useState("");

  const openModal = (title) => {
    setOpenModalType(title);
    setOpen(true);
  };

  const ModalInfo = () => {
    return (
      <div className={`${bdStyle.info} mt-3`}>
        <span
          className="btn btn-sm btn-primary"
          style={{ fontSize: "12px" }}
          onClick={() => openModal("학습완료까지 평균 학습시간")}
        >
          이책의학습통계
        </span>
      </div>
    );
  };

  return (
    <motion.div
      className="oxPage"
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ overflowY: "hidden", position: "relative" }}
    >
      <Header />
      {open && (
        <BookModal
          open={open}
          setOpen={setOpen}
          openModalType={openModalType}
          b_seq={params.id}
          bookdata={bookdata}
        />
      )}

      <div
        className="container"
        style={{ paddingTop: "75px", paddingBottom: "75px", maxWidth: "768px" }}
      >
        <div className="d-flex align-itmes-center mb-2">
          <div
            className={`${bdStyle.bookimg} border me-3`}
            style={{ backgroundImage: `url(${imageBg(bookdata.b_thumb)})` }}
          ></div>
          <div className={`${bdStyle.booktext}`}>
            <h6>{bookdata.b_name}</h6>
            <p>
              {bookdata.b_publisher &&
                bookdata.b_author &&
                `${bookdata.b_publisher} / ${bookdata.b_author} / `}
              {bookdata.b_pub_date &&
                bookdata.b_sort &&
                `${bookdata.b_pub_date} / ${bookdata.b_sort} / `}
              {((!bookdata.b_target && bookdata.b_exam) ||
                (bookdata.b_target && !bookdata.b_exam)) &&
                `${bookdata.b_target || "-"} / ${bookdata.b_exam || "-"}`}
            </p>
            {/* <p className="my-2 d-flex align-items-center">
              <GroupIcon className="me-1" style={{ fontSize: "20px" }} />
              현재 : {bookdata.b_usernum_fake ?? 0}
              <span className="ms-2"></span>누적 :{" "}
              {bookdata.b_usernum_fake2 ?? 0} */}
            {/* <ThumbUpAltIcon
                className="ms-3 me-1"
                style={{ fontSize: "17px" }}
              />
              {bookdata.b_good ? JSON.parse(bookdata.b_good).length : 0} */}
            {/* </p> */}
            <div>
              <div className={bdStyle.info}>
                현재까지
                <span
                  className="text-primary fw-bold d-inline-block text-center"
                  style={{ width: "30px" }}
                >
                  {useCountUp(bookdata.b_usernum_fake || 50, 0)}
                </span>
                명 학습자가{" "}
                <span className="text-success fw-bold">암기 중</span>
              </div>
              <div className={bdStyle.info}>
                현재까지
                <span
                  className="text-primary fw-bold d-inline-block text-center"
                  style={{ width: "30px" }}
                >
                  {useCountUp(bookdata.b_usernum_fake2 || 50, 0)}
                </span>
                명 학습자가{" "}
                <span className="text-danger fw-bold">학습완료</span>
              </div>
              {itemData.includes(Number(params.id)) ? (
                <ModalInfo />
              ) : (
                <button
                  className="btn btn-sm btn-success text-white w-100 mt-1"
                  onClick={itemApplyHanlder}
                  disabled={Number(applyState.apply_cnt) > 0 && true}
                >
                  {applyState.end_cnt > 0
                    ? `다시 시작하기`
                    : `이용신청${
                        Number(applyState.apply_cnt) > 0 ? "완료" : ""
                      }`}
                </button>
              )}
            </div>
          </div>
        </div>
        <BookTab
          itemData={itemData}
          b_seq={bookdata.b_seq}
          b_curriculum={bookdata.b_curriculum}
          hd={mydata[0]?.hoedock}
          now_curri={mydata[mydata.length - 1]?.t_curri}
          b_type={params.type}
          applyState={applyState}
          fetchApplyState={fetchApplyState}
          bookdata={bookdata}
        />
      </div>
    </motion.div>
  );
};
