import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import { getData } from "../common/common";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";

import QnaDetailStyles from "../css/qnadetail.module.css";
import { useParams, useNavigate } from "react-router-dom";

export const NoticeDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, SetData] = useState();
  useEffect(() => {
    Draw();
  }, []);

  async function Draw() {
    const res = await getData(`/notice/${params.id}`);
    if (res.data) SetData(res.data);
  }

  return (
    <>
      <Header />
      <Wrapper margin={100}>
        {data && (
          <div>
            <div
              className={`${QnaDetailStyles.titleTexxt} d-flex align-items-center`}
            >
              <div>{data.n_title || ""}</div>
              <div className="ms-auto" style={{ fontSize: "14px" }}>
                <span className={QnaDetailStyles.date}>{data.n_date}</span>
              </div>
            </div>
            <hr />
            <div className={QnaDetailStyles.contentText}>
              {data.n_contents && parse(data.n_contents)}
            </div>
            <hr />
          </div>
        )}
        <div className="d-flex">
          <span
            className="btn btn-sm btn-dark ms-auto"
            onClick={() => navigate(-1)}
          >
            목록
          </span>
        </div>
      </Wrapper>
    </>
  );
};
