import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider, Paper, Stack, styled, Tooltip } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

//components
import { BookTabCommunity } from "./BookTabCommunity";
import { SliderBox } from "./SliderBox";
import { Statistic } from "./Statistic";
import { StepperComp } from "./StepperComp";
import { TotalTestList } from "./TotalTestList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BookTab(props) {
  const {
    itemData,
    b_seq,
    b_curriculum,
    hd,
    now_curri,
    applyState,
    fetchApplyState,
    bookdata,
  } = props;

  const typeNumber = () => {
    let str = "";
    let type_n = Number(props.b_type);
    if (type_n === 1) {
      str = "암기관리";
    } else {
      str = "기출관리";
    }
    return str;
  };

  const [age, setAge] = React.useState(typeNumber());

  const [value, setValue] = React.useState(age === "기출관리" ? 1 : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const typeChangeNumber = () => {
    if (age === "암기관리") {
      return 1;
    } else if (age === "기출관리") {
      return 0;
    }
  };

  const selectHandleChange = (event) => {
    setAge(event.target.value);
  };
  const infoTableTr = (text, value) => {
    return (
      <>
        {value && (
          <tr>
            <th>{text}</th>
            <td>{value}</td>
          </tr>
        )}
      </>
    );
  };
  const infoTable = () => {
    return (
      <table
        className="table border text-center text-white"
        style={{ fontSize: "13px" }}
      >
        <tbody>
          {infoTableTr("교재명", bookdata.b_name)}
          {infoTableTr("과목", bookdata.b_cate)}
          {infoTableTr("출판사", bookdata.b_publisher)}
          {infoTableTr("저자", bookdata.b_author)}
          {infoTableTr("출간일자", bookdata.b_pub_date)}
          {infoTableTr("책종류", bookdata.b_jong)}
          {infoTableTr("책형태", bookdata.b_shape)}
          {infoTableTr("목차", bookdata.b_mock)}
          {infoTableTr("서평", bookdata.b_review)}
          {infoTableTr("ISBN-13", bookdata.b_isbn)}
          {infoTableTr("가격", bookdata.b_price)}
          {infoTableTr("페이지수", bookdata.b_page)}
          {infoTableTr("책크기", bookdata.b_size)}
          {infoTableTr("컬러", bookdata.b_color)}
          {infoTableTr("수험분야", bookdata.b_sort)}
          {infoTableTr("종이재질", bookdata.b_paper)}
          {infoTableTr("특징", bookdata.b_char)}
          {infoTableTr("이용대상", bookdata.b_target)}
          {infoTableTr("학습기간", bookdata.b_study)}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {itemData.includes(b_seq) ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {age !== "기출관리" && (
                <Tab
                  label="커리큘럼"
                  {...a11yProps(0)}
                  style={{ color: "white" }}
                />
              )}

              <Tab
                label="진도체크"
                {...a11yProps(1)}
                style={{ color: "white" }}
              />
              {age !== "기출관리" && (
                <Tab
                  label="전체시험"
                  {...a11yProps(2)}
                  style={{ color: "white" }}
                />
              )}
              {age !== "기출관리" && (
                <Tab
                  label="통계"
                  {...a11yProps(3)}
                  style={{ color: "white" }}
                />
              )}
              {/* <Tab label="전체시험" {...a11yProps(2)} />
              <Tab label="통계" {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {b_curriculum && (
              <img src={b_curriculum} alt="커리큘럼" className="w-100" />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* 암기관리 , 기출관리 */}
            <SliderBox
              sid={typeChangeNumber()}
              main={false}
              b_seq={b_seq}
              hd={hd}
              now_curri={now_curri}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TotalTestList
              sid={typeChangeNumber()}
              main={false}
              b_seq={b_seq}
              hd={hd}
              index={2}
              now_curri={now_curri}
              applyState={applyState}
              fetchApplyState={fetchApplyState}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <BookTabCommunity b_seq={b_seq} />
          </TabPanel>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="정보" {...a11yProps(0)} style={{ color: "white" }} />
              <Tab
                label="제공 서비스"
                {...a11yProps(1)}
                style={{ color: "white" }}
              />
              <Tab
                label="커뮤니티"
                {...a11yProps(2)}
                style={{ color: "white" }}
              />
              <Tab label="통계" {...a11yProps(2)} style={{ color: "white" }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            ></Stack> */}
            {infoTable()}
          </TabPanel>
          <TabPanel value={value} index={1}>
            제공 서비스
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <BookTabCommunity b_seq={b_seq} /> */}
            커뮤니티
          </TabPanel>
          <TabPanel value={value} index={3}>
            통계
          </TabPanel>
        </Box>
      )}
    </>
  );
}
