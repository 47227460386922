import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import CheckFormStyles from "../css/checkform.module.css";
import testStyles from "../css/test.module.css";
import parse from "html-react-parser";

export function CheckForm({ radioChange, v, index, detail, print }) {
  const labelArray = ["①", "②", "③", "④", "⑤"];

  const checked = (chk) => {
    let isCheck = false;
    if (chk) isCheck = true;
    return isCheck;
  };

  const textInputRef = useRef([]);

  return (
    <div key={index}>
      {labelArray.map((vv, i) =>
        checked(v.user_num)
          ? i < v.content.length && (
              <div className={`${CheckFormStyles.formWrap}`}>
                <Form.Check
                  type="radio"
                  label={`${vv}`}
                  name={`'pro-${v.q_seq}'`}
                  id={`'pro-id-${v.q_seq}-${i + 1}'`}
                  data-target={v.q_seq}
                  value={i + 1}
                  onChange={radioChange}
                  className={`${CheckFormStyles.form} mb-1 ${
                    Number(v.q_answer) === i + 1 && "fw-bold"
                  }`}
                  checked={Number(v.user_num) === i + 1}
                />
                {v.content.length > 0 && (
                  <div
                    className={`${CheckFormStyles.inline} ${testStyles.font} ${
                      Number(v.q_answer) === i + 1 && "fw-bold"
                    }`}
                  >
                    <pre className={`${testStyles.font} mb-0`}>
                      {v.content[i].includes("img")
                        ? parse(v.content[i])
                        : parse(
                            v.content[i].replace(/@/g, "'").replace(/#/g, '"')
                          )}
                    </pre>
                  </div>
                )}
              </div>
            )
          : i < v.content.length && (
              <div className={`${CheckFormStyles.formWrap}`}>
                {detail ? (
                  <Form.Check
                    type="radio"
                    label={`${vv}`}
                    name={`'pro-${v.q_seq}'`}
                    id={`'pro-id-${v.q_seq}-${i + 1}'`}
                    data-target={v.q_seq}
                    value={`${i + 1}`}
                    onChange={radioChange}
                    className={`${CheckFormStyles.form} mb-1`}
                    ref={(e) => (textInputRef.current[i] = e)}
                    checked={Number(detail) === i + 1}
                  />
                ) : (
                  <Form.Check
                    type="radio"
                    label={`${vv}`}
                    name={`'pro-${v.q_seq}'`}
                    id={`'pro-id-${v.q_seq}-${i + 1}'`}
                    data-target={v.q_seq}
                    value={`${i + 1}`}
                    onChange={radioChange}
                    className={`${CheckFormStyles.form} mb-1`}
                    ref={(e) => (textInputRef.current[i] = e)}
                  />
                )}
                {v.content.length > 0 && (
                  <div
                    className={`${CheckFormStyles.inline} ${testStyles.font}`}
                    onClick={() => {
                      textInputRef.current[i].click();
                    }}
                  >
                    <pre className={`${testStyles.font} mb-0`}>
                      {v.content[i].includes("img")
                        ? parse(v.content[i])
                        : parse(
                            v.content[i].replace(/@/g, "'").replace(/#/g, '"')
                          )}
                    </pre>
                  </div>
                )}
              </div>
            )
      )}
    </div>
  );
}
