import React, { useEffect, useState } from "react";
import { Form, Container } from "react-bootstrap";

//components
import { Header } from "../components/Header";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

//axios
import { instance, postData, putData } from "../common/common";

import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { FormLabel } from "@mui/material";
import PortraitIcon from "@mui/icons-material/Portrait";

import myPageStyles from "../css/myPage.module.css";

export const Mypage = () => {
  const user_info = userinfoStore((state) => state.user_info);
  const setUserinfo = userinfoStore((state) => state.setUserinfo);
  const navigate = useNavigate();

  const [passdata, setPassData] = useState({
    u_n_pass: "",
    u_n_pass2: "",
  });

  const infoOnChange = (e) => {
    const { name, value } = e.target;
    setUserinfo({
      ...user_info,
      [name]: value,
    });
  };

  const { u_n_pass, u_n_pass2 } = passdata;

  const PassOnChange = (e) => {
    const { name, value } = e.target;
    setPassData({
      ...passdata,
      [name]: value,
    });
  };

  function FindPass() {
    if (!u_n_pass) {
      alert("새비밀번호를 입력해주세요.");
      return false;
    } else if (u_n_pass !== u_n_pass2) {
      alert("새비밀번호확인을 정확히 입력해주세요.");
      return false;
    } else {
      if (window.confirm("비밀번호를 변경 하시겠습니까?")) {
        instance
          .post(`/user/update/pw`, {
            u_seq: user_info.u_seq,
            u_pass: u_n_pass,
          })
          .then((res) => {
            alert(`비밀번호가 변경되었습니다!`);
            navigate("/");
          });
      }
    }
  }

  function modifyClick() {
    if (!user_info.u_name) {
      alert("성명을 입력해주세요.");
      return false;
    } else if (!user_info.u_phone) {
      alert("휴대폰번호를 입력해주세요.");
      return false;
    } else if (!user_info.u_email) {
      alert("이메일을 입력해주세요.");
      return false;
    } else {
      if (window.confirm("수정 하시겠습니까?")) {
        instance
          .post(`/user/update/info`, {
            u_seq: user_info.u_seq,
            u_name: user_info.u_name,
            u_phone: user_info.u_phone,
            u_email: user_info.u_email,
          })
          .then((res) => {
            alert(`정보수정에 성공하였습니다!`);
          });
      }
    }
  }

  const fileChange = async (e) => {
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("dir", "dd");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = await postData(`/common/file/insert`, formData, config);

    if (res.data.length > 0) {
      console.log("file res.data : ", res.data);
      const updateRes = await postData(`/user/update/profile`, {
        u_seq: user_info.u_seq,
        u_profile: res.data[0].location,
      });
      console.log("updateRes.data : ", updateRes.data);
      if (updateRes.data === "success") {
        setUserinfo({
          ...user_info,
          u_profile: res.data[0].location,
        });
      }
    }
  };

  console.log("로드시 이미지 확인 : ", user_info?.u_profile);

  return (
    <>
      <Header />
      <Container
        style={{
          paddingTop: "100px",
          paddingBottom: "100px",
          maxWidth: "468px",
        }}
        className="mx-auto"
      >
        <h5>마이페이지</h5>
        <hr />
        <FormControl variant="standard" fullWidth className="mb-3">
          {user_info?.u_profile ? (
            <label
              htmlFor="component-simple"
              style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
            >
              내 프로필{" "}
              <div className="text-danger d-inline-block ms-2">
                * 변경을 원하시는 경우 등록하신 프로필을 클릭해주세요.
              </div>
            </label>
          ) : (
            <label
              htmlFor="component-simple"
              style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6)" }}
            >
              내 프로필
            </label>
          )}

          <Input
            name="u_profile"
            id="file-upload"
            type="file"
            className="d-none"
            onChange={fileChange}
            accept="image/*"
          />
          <div style={{ marginTop: "15px" }}>
            {user_info?.u_profile ? (
              <label
                className={myPageStyles.profile}
                style={{ backgroundImage: `url(${user_info?.u_profile})` }}
                htmlFor="file-upload"
              >
                {/* <img
                  src={user_info?.u_profile}
                  alt=""
                  style={{ width: "30%" }}
                /> */}
              </label>
            ) : (
              <label className="btn btn-success btn-sm" htmlFor="file-upload">
                <PortraitIcon /> 프로필 등록
              </label>
            )}
          </div>
        </FormControl>

        <FormControl variant="standard" fullWidth className="mb-3">
          <InputLabel htmlFor="component-simple">이름</InputLabel>
          <Input
            id="component-simple"
            name="u_name"
            value={user_info.u_name}
            readOnly={true}
            onChange={(e) => infoOnChange(e)}
          />
        </FormControl>

        <FormControl variant="standard" fullWidth className="mb-3">
          <InputLabel htmlFor="component-simple">휴대폰번호</InputLabel>
          <Input
            id="component-simple"
            name="u_phone"
            readOnly={true}
            value={user_info.u_phone}
            onChange={(e) => infoOnChange(e)}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth className="mb-3">
          <InputLabel htmlFor="component-simple">이메일</InputLabel>
          <Input
            id="component-simple"
            name="u_email"
            readOnly={true}
            value={user_info.u_email}
            onChange={(e) => infoOnChange(e)}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth className="mb-3">
          <InputLabel htmlFor="component-simple">지점</InputLabel>
          <Input
            id="component-simple"
            name="c_name"
            readOnly={true}
            value={user_info.c_name}
            onChange={(e) => infoOnChange(e)}
          />
        </FormControl>

        {/* <Button fullWidth variant="contained" onClick={() => modifyClick()}>
          수정하기
        </Button> */}

        <FormControl variant="standard" fullWidth className="mb-3">
          <InputLabel htmlFor="component-simple">비밀번호</InputLabel>
          <Input
            id="component-simple"
            name="u_n_pass"
            type="password"
            onChange={(e) => PassOnChange(e)}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth className="mb-3">
          <InputLabel htmlFor="component-simple">비밀번호 확인</InputLabel>
          <Input
            id="component-simple"
            name="u_n_pass2"
            type="password"
            onChange={(e) => PassOnChange(e)}
          />
        </FormControl>

        <Button fullWidth variant="contained" onClick={() => FindPass()}>
          비밀번호 변경
        </Button>
      </Container>
    </>
  );
};
