import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Net } from "./Net";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { getData, putData } from "../common/common";
import { userinfoStore } from "../zustand/userinfoStore";
import { Toast } from "./Toast";
import netStyles from "../css/net.module.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ isData, setForce }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setForce(true);
  };

  const book = ["국어", "영어"];

  const [itemData, setItemData] = React.useState([]);
  console.log("itemData: ", itemData);
  const itemHanlder = (id) => {
    const temp = [...itemData];
    let isDupli = false;
    temp.forEach(function (v) {
      if (v === id) {
        isDupli = true;
      }
      return false;
    });
    if (!isDupli) {
      setItemData([...itemData, id]);
    } else {
      const filterData = temp.filter((v) => v !== id);
      setItemData([...filterData]);
    }
  };

  //   React.useEffect(() => {
  //     setItemData([]);
  //   }, [open]);

  React.useEffect(() => {
    if (isData) {
      getMyBook();
    }
  }, []);
  const user_info = userinfoStore((state) => state.user_info);

  const getMyBook = async () => {
    const res = await getData(`/user/${user_info.u_seq}`);
    if (res.data.u_book) {
      if (JSON.parse(res.data.u_book).length > 0) {
        setItemData(JSON.parse(res.data.u_book));
      }
    }
  };

  const [isToast, setToast] = React.useState(false);

  const insertMyBook = async () => {
    let obj = {
      u_book: JSON.stringify(itemData),
    };
    const res = await putData(`/user/${user_info.u_seq}`, { obj: obj });

    if (res.data === "success") {
      setToast(true);
    }
  };

  React.useEffect(() => {
    if (isToast) {
      setTimeout(() => {
        setMsg("");
        setToast(false);
        handleClose();
      }, 1500);
    }
  }, [isToast]);

  const [msg, setMsg] = React.useState("");

  const toastMsg = () => {
    if (!msg) {
      return (
        <div className="toast-div">
          <div>교재 데이터가 변경되었습니다.</div>
        </div>
      );
    } else {
      return (
        <div className="toast-div">
          <div>{msg}</div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className={isData ? netStyles.ab : null}
        onClick={handleClickOpen}
        style={{ padding: "40px 0" }}
      >
        {!isData && <div className="mb-2">이용중인 교재가 없습니다.</div>}
        <AddCircleIcon />
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          교재를 선택해주세요.
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ paddingBottom: "30px" }}>
          {book.map((v, i) => (
            <Net
              key={i}
              icon={<LibraryBooksIcon />}
              title="과목별 교재"
              url={`/book/type/${v}`}
              type={v}
              itemHanlder={itemHanlder}
              itemData={itemData}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={insertMyBook}>
            선택 완료
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {isToast && (
        <Toast
          text={() => toastMsg()}
          type="info"
          time={2000}
          isToast={isToast}
          setToast={setToast}
        />
      )}
    </div>
  );
}
