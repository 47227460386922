import React, { createRef, useCallback, useEffect, useState } from "react";
import headerStyles from "../css/header.module.css";

//image
import logoimg from "../img/yg-logo-fill.png";
import { useNavigate } from "react-router-dom";

import { PersonFill, BellFill } from "react-bootstrap-icons";
import SearchIcon from "@mui/icons-material/Search";
import { SideSearch } from "./SideSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import { userinfoStore } from "../zustand/userinfoStore";

import { getData, putData, getDateDiff, currentDate } from "../common/common";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
function Imagefunc() {
  const navigate = useNavigate();
  function goHome() {
    navigate("/");
  }

  return (
    <img
      src={logoimg}
      className="pointer"
      width="40"
      alt="img"
      onClick={goHome}
    ></img>
    // <ArrowBackIosNewIcon onClick={goHome} />
  );
}

function menu() {
  return <Menu />;
}

export const Header = ({
  isActive = false,
  isDelay = true,
  setCategory,
  setActive,
  setDelay,
  logo = Imagefunc,
  title = "",
  button = menu,
  centerText = "",
  leftText = "",
  ani,
  setAni = () => {},
  isAb = true,
  setAb = () => {},
  dropDown = false,
  dropDownHander = () => {},
}) => {
  const user_info = userinfoStore((state) => state.user_info);
  const setUserinfo = userinfoStore((state) => state.setUserinfo);
  const navigate = useNavigate();

  const [mainCheck, setMainCheck] = useState(true);

  const [alarmList, setAlarmList] = useState([]);
  const [noticeData, setNoticeData] = useState("");

  useEffect(() => {
    let info = JSON.parse(localStorage.getItem("user_info"));
    if (info && info.length !== 0) {
      setUserinfo(info);
    }
  }, []);

  async function fetchAlarm() {
    const res = await getData(`alarm/myalarm/${user_info.u_seq}`);
    if (res.data) {
      setAlarmList(res.data);
    }
  }

  async function fetchNotice() {
    const res = await getData(`notice/0/lastdata`);
    if (res.data) {
      setNoticeData(res.data.n_date);
    }
  }

  useEffect(() => {
    if (
      window.location.href === "http://localhost:3000/" ||
      window.location.href === "https://young-ga.com/" ||
      window.location.href === "https://young-ga.com:81/"
    ) {
      setMainCheck(false);
    }

    fetchAlarm();
    fetchNotice();
  }, []);

  const openSide = () => {
    setAb((prev) => !prev);
  };

  useEffect(() => {
    if (isAb) {
      setAni((prev) => !prev);
    }
  }, [isAb]);

  const LogoAndText = useCallback(() => {
    return !centerText ? (
      logo()
    ) : dropDown ? (
      <select
        className={headerStyles.centerText}
        style={{ border: 0 }}
        onChange={dropDownHander}
      >
        <option value="단어장">단어장</option>
        <option value="기출문제">기출문제</option>
      </select>
    ) : (
      <span className={headerStyles.centerText}>{centerText}</span>
    );
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAlarm, setAnchorAlarm] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlarm = (event) => {
    setAnchorAlarm(event.currentTarget);
  };

  const handleAlarmClose = () => {
    setAnchorAlarm(null);
  };

  async function alarmClick(al_seq) {
    let obj = {
      al_yn: 1,
    };
    const res = await putData(`/alarm/${al_seq}`, { obj: obj });
    if (res.data === "success") {
      fetchAlarm();
      setAnchorAlarm(null);
    }
  }

  const logout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      sessionStorage.clear();
      localStorage.removeItem("user_info");
      window.location.replace("/");
    }
  };

  const goQuiz = () => {
    navigate(`/quiz`);
  };

  const goAll = () => {
    navigate(`/all`);
  };

  const goQnaStudy = () => {
    navigate(`/qna/study`);
  };

  const goQnaSystem = () => {
    navigate(`/qna/system`);
  };

  return (
    <>
      {/* <div
        className={`${headerStyles.toptitle} p-3 ${
          isAb ? (mainCheck ? "position-fixed" : "position-absolute") : null
        }`}
      > */}

      <div className={`${headerStyles.toptitleWrap} p-3 position-fixed`}>
        <div className={`${headerStyles.toptitle} mx-auto text-white`}>
          {title && <span>{title}</span>}
          {!title && !leftText && <LogoAndText />}
          {leftText && leftText}
          {button()}
          {!title && (
            <div className={headerStyles.personWrap}>
              {/* {!mainCheck && (
              <div className={`d-inline-block ${headerStyles.bgActive}`}>
                <SearchIcon
                  className={headerStyles.searchIcon}
                  onClick={openSide}
                />
              </div>
            )} */}
              <div className={`d-inline-block ${headerStyles.bgActive}`}>
                {!user_info.u_seq ? (
                  <span className="pointer" onClick={() => navigate("/login")}>
                    로그인
                  </span>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="position-relative">
                      {Number(getDateDiff(noticeData, currentDate())) > -3 && (
                        <div className={headerStyles.bell_number}>N</div>
                      )}
                      <PersonFill
                        className={headerStyles.person}
                        onClick={handleMenu}
                      />
                    </div>
                    <div className="ms-2 position-relative">
                      {alarmList.length !== 0 && (
                        <div className={headerStyles.bell_number}>
                          {alarmList.length}
                        </div>
                      )}
                    </div>
                    <BellFill
                      className={`${headerStyles.person}`}
                      onClick={handleAlarm}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                )}

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  style={{ top: "50px" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => navigate("/notice")}
                    style={{ fontSize: "14px" }}
                  >
                    공지사항
                    {console.log(getDateDiff(noticeData, currentDate()))}
                    {Number(getDateDiff(noticeData, currentDate())) > -3 && (
                      <div className={`${headerStyles.reddot} ms-1`}>N</div>
                    )}
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/mypage")}
                    style={{ fontSize: "14px" }}
                  >
                    내 정보
                  </MenuItem>
                  <MenuItem onClick={goQuiz} style={{ fontSize: "14px" }}>
                    쪽지시험
                  </MenuItem>
                  {/* <MenuItem onClick={goQnaStudy} style={{ fontSize: "14px" }}>
                    학습 QnA
                  </MenuItem> */}
                  {/* <MenuItem onClick={goQnaSystem} style={{ fontSize: "14px" }}>
                    시스템 QnA
                  </MenuItem> */}
                  {/* <MenuItem onClick={goAll} style={{ fontSize: "14px" }}>
                    전범위시험
                  </MenuItem> */}
                  <MenuItem onClick={logout} style={{ fontSize: "14px" }}>
                    로그아웃
                  </MenuItem>
                </Menu>

                {alarmList.length !== 0 && (
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorAlarm}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{ top: "50px" }}
                    open={Boolean(anchorAlarm)}
                    onClose={handleAlarmClose}
                  >
                    {alarmList.map((v, i) => (
                      <div
                        key={i}
                        className={headerStyles.alarmList}
                        onClick={() => alarmClick(v.al_seq)}
                      >
                        {v.al_text}
                        <div>{v.al_date}</div>
                      </div>
                    ))}
                  </Menu>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <SideSearch ani={ani} setAni={setAni} setAb={setAb} />
    </>
  );
};
