import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "../components/Toast";
import TimerStyles from "../css/timer.module.css";

export const Timer = ({ title, bun, sendFetchData }) => {
  const [min, setMin] = useState(bun);
  const [sec, setSec] = useState(0);
  const [mil, setMil] = useState(0);
  const time = useRef(min * 60);
  const time2 = useRef(min * 60000);
  const timerId = useRef(null);
  const milId = useRef(null);
  const [isSec, setisSec] = useState(false);
  const [isStart, setStart] = useState(false);
  const [isToast, setToast] = useState(false);
  const [toastText, setToastText] = useState("10분 남았습니다.");

  const [hurry, setHurry] = useState(false);

  //   useEffect(() => {
  //     notify();
  //   }, []);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (!isStart) {
        setisSec(true);
      }
      time.current -= 1;
    }, 1000);
    return () => {
      if (!isStart) {
        setStart(true);
      }

      clearTimeout(timerId.current);
    };
  }, []);

  useEffect(() => {
    if (isSec) {
      milId.current = setInterval(() => {
        setMil(time2.current % 60);
        time2.current -= 1;
      }, 16);
      return () => clearTimeout(milId.current);
    }
  }, [isSec]);

  useEffect(() => {
    if (time.current <= 0) {
      console.log("타임 아웃");
      setToastText("시험시간이 종료되었습니다.");
      clearInterval(time.current);
      clearInterval(time2.current);
      clearInterval(milId.current);
      sendFetchData();
    } else {
      if (min <= 10) {
        if (time.current === time.current * 0.1) {
          setHurry((prev) => !prev);
        }
      } else {
        if (time.current === 600) {
          setHurry(true);
        } else if (time.current <= 300) {
          setHurry((prev) => !prev);
        }
      }
    }
  }, [sec]);
  //console.log("timerId.current : ", time.current);
  return (
    <>
      <div className={TimerStyles.timer}>
        <div className={hurry ? TimerStyles.active : null}>
          {min >= 10 ? min : "0" + min} : {sec >= 10 ? sec : "0" + sec} :{" "}
          {mil >= 10 ? mil : "0" + mil}
        </div>
      </div>
      {isToast && (
        <Toast
          text={toastText}
          type="info"
          time={2000}
          isToast={isToast}
          setToast={setToast}
        />
      )}
    </>
  );
};
