import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

export const KeywordInput = ({ value, onChange }) => {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 2px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="검색 키워드를 입력해주세요."
        inputProps={{ "aria-label": "검색 키워드를 입력해주세요." }}
        onChange={onChange}
        value={value}
        style={{ fontSize: "14px" }}
      />
      <IconButton
        type="submit"
        sx={{ p: "10px" }}
        aria-label="search"
        className="p-1"
      >
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>
  );
};
