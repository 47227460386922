/* eslint-disable react/jsx-no-bind */
import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useContext,
} from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Container, Button } from 'react-bootstrap';
import {
    Link,
    UNSAFE_NavigationContext as NavigationContext,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { Header } from '../components/Header';
import { Timer } from '../components/Timer';
import { Timer_v2 } from '../components/Timer_v2';
import { getData, postData, putData } from '../common/common';

import parser from 'html-react-parser';

import testStyles from '../css/test.module.css';
import { CheckForm } from '../components/CheckForm';
import correct from '../img/correct.png';
import wrong from '../img/wrong.png';
import { Confirm } from '../components/Confirm';
import { Toast } from '../components/Toast';

//zustand
import { userinfoStore } from '../zustand/userinfoStore';
import { ResultComponent } from '../components/ResultComponent';
import { EmptyList } from '../components/EmptyList';

import {
    ArrowDownCircleFill,
    ArrowDownUp,
    ArrowUpCircleFill,
    Printer,
    JournalX,
    ListUl,
    UiRadiosGrid,
} from 'react-bootstrap-icons';
import { PDFExport } from '@progress/kendo-react-pdf';
import { defineFont } from '@progress/kendo-drawing/pdf';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { CheckForm2 } from '../components/CheckForm2';

export function Test2(props) {
    const user_info = userinfoStore(state => state.user_info);

    useEffect(() => {
        if (!user_info.u_seq) {
            window.sessionStorage.setItem('quizUrl', window.location.href);
            window.location.replace('/login');
        }
    }, []);
    /* 시험 쳤는지 유무 */
    const [isEnd, setEnd] = useState(false);
    /* -------------------------*/

    const [isLoading, setLoading] = useState(false);
    const [omrState, SetOmrState] = useState(false);
    const [title, setTitle] = useState('');
    const [cate, setCate] = useState('');
    const [testInfo, setTestInfo] = useState({});
    const [data, setData] = useState([]);

    const [resData, setResData] = useState([]);

    const [timeAttack, SetTimeAttack] = useState('N');

    const [cor, setCor] = useState([]);

    const [detail, setDetail] = useState([]);

    let params = useParams();

    const navigate = useNavigate();

    const [min, setMin] = useState(0);
    const [min2, setMin2] = useState(0);
    const [isConfirm, setConfirm] = useState(false);
    const [isToast, setToast] = useState(false);
    const [isToastM, setToastM] = useState(false);

    const [isStep, setStep] = useState('');

    const [tempTime, setTempTime] = useState({
        isTime: 'N',
        value: '',
    });

    const [isWhen, setWhen] = useState(false);
    const [page, setPage] = useState(1);
    const [pageTemp, setPageTemp] = useState([]);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(0);

    const [qcount, setQcount] = useState(100);
    async function loadBookData() {
        const res = await getData(`/book/${params.id}`);
        if (res.data) {
            console.log('뭐고 : ', res.data);
            if (res.data.b_type === 4 && res.data.b_motype === '하프') {
                setQcount(10);
            } else if (res.data.b_type === 4 && res.data.b_motype === '주간') {
                setQcount(20);
            }

            if (res.data.b_cate === '경찰학' || res.data.b_cate === '형사법') {
                setQcount(40);
            }
            if (res.data.b_cate === '헌법') {
                setQcount(20);
            }
            if (res.data.b_cate === '정보보호론') {
                setQcount(100);
            }
        }
    }

    useEffect(() => {
        loadBookData();
    }, []);

    function useBlocker(blocker) {
        const { navigator } = useContext(NavigationContext);

        useEffect(() => {
            if (!isWhen) return;

            const unblock = navigator.block(tx => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };
                blocker(autoUnblockingTx);
            });
            return unblock;
        }, [navigator, blocker, isWhen]);
    }

    function usePrompt() {
        const blocker = useCallback(
            tx => {
                cancelTest();
                // if (testInfo.t_type === "전국모의고사") {

                // } else {
                //   navigate(-1);
                // }
            },
            [testInfo.t_type],
        );

        useBlocker(blocker, isWhen);
    }

    usePrompt();

    const sendFetchData = async () => {
        if (
            confirmText.title === '제출하시겠습니까?' ||
            confirmText.title === '응시를 취소하시겠습니까?'
        ) {
            const tempData = [...data];
            const tempDetail = [...detail];
            const scrapArray = [];
            tempData.forEach(function (v) {
                tempDetail.forEach(function (vv) {
                    if (Number(vv.td_num) === Number(v.q_seq)) {
                        if (Number(v.q_answer) === Number(vv.td_user_num)) {
                            vv.td_check = 1;
                        } else {
                            vv.td_check = 0;
                            var obj = {
                                type: '오답노트',
                                id: v.q_seq,
                            };
                            scrapArray.push(obj);
                        }
                    }
                });
            });

            const res = await postData('/test_detail', {
                obj: tempDetail,
                scrapArray: JSON.stringify(scrapArray),
                u_seq: user_info.u_seq,
            });

            if (res.data.type === 'success') {
                endTest();
                setConfirm(false);
                setWhen(false);
            }
        } else if (confirmText.title === '시험을 중단하시겠습니까?') {
            navigate(-1);
        } else {
            openTest();
        }
    };

    const openTest = () => {
        setStep('start');
        setMin(tempTime.value);
        SetTimeAttack(tempTime.isTime);

        setConfirmText({
            title: '제출하시겠습니까?',
            message: '결과조회 페이지에서 확인이 가능합니다.',
        });

        setConfirm(false);
    };

    const [confirmText, setConfirmText] = useState({
        title: '제출하시겠습니까?',
        message: '결과조회 페이지에서 확인이 가능합니다.',
    });

    const cancelHandler = () => {
        if (
            confirmText.title === '제출하시겠습니까?' ||
            confirmText.title === '응시를 취소하시겠습니까?'
        ) {
            setConfirm(prev => !prev);
        } else {
            navigate(-1);
        }
    };
    const insertTestUser = async () => {
        if (user_info.u_seq && params.target) {
            let testObj = {
                t_seq: params.id,
                u_seq: user_info.u_seq,
                tu_u_seq: params.target,
            };
            const res = await postData(`/test_user`, { obj: testObj });
        }
    };
    useEffect(() => {
        if (params.type === 'link') {
            insertTestUser();
        }
    }, []);

    useEffect(() => {
        if (isStep === 'normal') {
            setConfirmText({
                title: '',
                message: '시험을 시작하시겠습니까?',
            });
        } else if (isStep === 'start') {
            if (!isEnd) {
                setMin(tempTime.value);
                SetTimeAttack(tempTime.isTime);
            }
        }
    }, [isStep]);

    useEffect(() => {
        if (confirmText.title !== '제출하시겠습니까?') {
            setConfirm(true);
        }
    }, [confirmText.title]);

    useEffect(() => {
        drawContent();
    }, [page, qcount]);

    // useEffect(() => {
    //   if (isLoading) {
    //     endCheck();
    //   }
    // }, [isLoading]);

    // useEffect(() => {
    //   if (cor.length > 0) {
    //     setEnd(true);
    //   }
    // }, [cor]);

    useEffect(() => {
        if (isEnd) {
            setData(drawCorrectAndWrong());
        }
    }, [isEnd]);

    const endCheck = async () => {
        const res = await getData(
            `/test_user/${params.id}/my?u_seq=${user_info.u_seq}`,
        );
        setLoading(false);
        if (res.data.isEnd) {
            if (res.data.cor) setCor(res.data.cor);
        }
    };

    const drawCorrectAndWrong = () => {
        setWhen(false);
        let tempData = [...data];
        tempData.forEach(function (v) {
            cor.forEach(function (vv) {
                if (vv.td_num === v.q_seq) {
                    v.correct = vv.td_check;
                    v.user_num = vv.td_user_num;
                }
            });
        });

        return tempData;
    };

    const startTest = async () => {
        const sRes = await getData(
            `/test_user/${params.id}/start_check?u_seq=${user_info.u_seq}`,
        );

        if (!sRes.data.isStart) {
            await putData(
                `/test_user/${params.id}/start?u_seq=${user_info.u_seq}`,
            );
        }
    };

    const handleChange = e => {
        setPage(e);
    };

    const drawContent = async () => {
        const res = await getData(
            `/questions/${params.id}/all2?ppp=${qcount}&page=${page}`,
        );
        //const temp = [res.data.content.map(v => ...v)]
        setResData(res.data);
        setTitle(res.data.book);
        setCate(res.data.b_cate);
        setTotal(res.data.totalPage);
        setCount(res.data.totalCount);
        let pt = [...pageTemp];
        let pageCheck = true;
        pt.forEach(function (v) {
            if (v === page) {
                pageCheck = false;
            }
        });

        if (pageCheck) {
            pt = [...pageTemp, page];
            setPageTemp(pt);
        }

        const temp = res.data.title.map(v => {
            if (v.q_title.indexOf('①') !== -1) {
                var devide = v.q_title.split('①');

                var num1 = devide[1].split('②');
                var num2 = num1[1].split('③');
                var num3 = num2[1].split('④');
                var num4 = num3[1].split('⑤');

                //["①", "②", "③", "④", "⑤"];
                //devide[1].split('④')
                return {
                    ...v,
                    q_title: devide[0],
                    content: [num1[0], num2[0], num3[0], num4[0]],
                };
            } else {
                return {
                    ...v,
                    content: JSON.parse(v.q_contents),
                };
            }
        });

        let dArray = [];
        temp.forEach(function (t) {
            const obj = {
                t_seq: params.id,
                u_seq: user_info.u_seq,
                td_num: t.q_seq,
                td_user_num: -1,
            };
            dArray.push(obj);
        });

        setDetail(dArray);
        setData(temp);
        setLoading(true);
    };

    const radioChange = e => {
        const obj = {
            t_seq: params.id,
            u_seq: user_info.u_seq,
            td_num: e.target.dataset.target,
            td_user_num: e.target.value,
        };

        let temp = [...detail];
        let isChk = false;

        temp.forEach(function (v) {
            if (Number(v.td_num) === Number(e.target.dataset.target)) {
                isChk = true;
                return false;
            }
        });

        if (isChk) {
            const t = temp.map(v =>
                Number(v.td_num) === Number(e.target.dataset.target)
                    ? { ...v, td_user_num: e.target.value }
                    : v,
            );
            setDetail(t);
        } else {
            setDetail([...detail, obj]);
        }
    };

    const toggleOMR = () => {
        SetOmrState(prev => !prev);
    };

    useEffect(() => {
        if (!omrState) {
            if (detail.length > 0) {
                document
                    .querySelectorAll('.form-check-input')
                    .forEach(function (v) {
                        detail.forEach(function (vv) {
                            if (
                                Number(vv.td_num) === Number(v.dataset.target)
                            ) {
                                if (
                                    Number(vv.td_user_num) === Number(v.value)
                                ) {
                                    v.checked = true;
                                }
                            }
                        });
                    });
            }
        }
    }, [omrState]);

    function button() {
        if (!omrState) {
            return (
                <Button
                    variant="success"
                    type="submit"
                    className="ms-auto"
                    onClick={toggleOMR}>
                    <UiRadiosGrid className={testStyles.margin} />
                </Button>
            );
        } else {
            return (
                <Button
                    variant="success"
                    type="submit"
                    className="ms-auto"
                    onClick={toggleOMR}>
                    <ListUl className={testStyles.margin} />
                </Button>
            );
        }
    }

    const [isScroll, setScroll] = useState(document.body.scrollHeight);
    const scrollCatch = () => {
        let scrollValue = '';
        if (window.pageYOffset > 0) {
            scrollValue = 0;
        } else {
            scrollValue = document.body.scrollHeight;
        }
        setScroll(scrollValue); // window 스크롤 값을 ScrollY에 저장
    };

    const scrollHandler = scrollValue => {
        // 클릭하면 스크롤이 위로 올라가는 함수
        window.scrollTo({
            top: scrollValue,
            behavior: 'smooth',
        });
        setScroll(0); // ScrollY 의 값을 초기화
    };

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', scrollCatch);
        };
        watch(); // addEventListener 함수를 실행
        return () => {
            window.removeEventListener('scroll', scrollCatch); // addEventListener 함수를 삭제
        };
    });

    function UpDown() {
        if (isScroll !== 0) {
            return (
                <ArrowDownCircleFill
                    className={testStyles.upDown}
                    onClick={() => scrollHandler(isScroll)}
                />
            );
        } else {
            return (
                <ArrowUpCircleFill
                    className={testStyles.upDown}
                    onClick={() => scrollHandler(isScroll)}
                />
            );
        }
    }

    const sendTest = () => {
        handleOpen();
        //   setConfirm(true);
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const ModalCell = () => {
        const modalTemp = detail.filter(v => v.td_user_num !== -1);
        const upIndex = 100 * (page - 1);
        let mapTemp = [];

        const an = () => {};

        data.forEach(function (v) {
            modalTemp.forEach(function (vv, i) {
                if (Number(v.q_seq) === Number(vv.td_num)) {
                    if (Number(vv.td_user_num) !== Number(v.q_answer)) {
                        if (page > 1) {
                            let obj = {
                                no: upIndex + i + 1,
                                title:
                                    cate === '영어'
                                        ? parser(v.q_title)
                                        : parser(
                                              v.content[Number(v.q_answer) - 1],
                                          ),
                                db:
                                    cate === '영어'
                                        ? parser(v.q_answer)
                                        : parser(
                                              v.content[Number(v.q_answer) - 1],
                                          ),
                                user_value:
                                    cate === '영어'
                                        ? parser(vv.td_user_num)
                                        : parser(
                                              v.content[
                                                  Number(vv.td_user_num) - 1
                                              ],
                                          ),
                            };
                            mapTemp.push(obj);
                        } else {
                            let obj = {
                                no: i + 1,
                                title:
                                    cate === '영어'
                                        ? parser(v.q_title)
                                        : parser(
                                              v.content[Number(v.q_answer) - 1],
                                          ),
                                db:
                                    cate === '영어'
                                        ? parser(v.q_answer)
                                        : parser(
                                              v.content[Number(v.q_answer) - 1],
                                          ),
                                user_value:
                                    cate === '영어'
                                        ? parser(vv.td_user_num)
                                        : parser(
                                              v.content[
                                                  Number(vv.td_user_num) - 1
                                              ],
                                          ),
                            };
                            mapTemp.push(obj);
                        }
                    }
                }
            });
        });

        return mapTemp.map((row, i) => (
            <TableRow
                key={i}
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                }}>
                <TableCell component="th" scope="row">
                    {row.no}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.title}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.db}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.user_value}
                </TableCell>
            </TableRow>
        ));
    };

    const ModalOverlay = () => {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        오답확인
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <TableContainer
                            component={Paper}
                            style={{ height: '300px' }}>
                            <Table
                                sx={{ maxWidth: 400 }}
                                aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>번호</TableCell>
                                        {cate === '국어' ? (
                                            <TableCell>표준어</TableCell>
                                        ) : (
                                            <TableCell>단어</TableCell>
                                        )}

                                        <TableCell>DB 정답</TableCell>
                                        <TableCell>선택한 정답</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <ModalCell />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Typography>
                </Box>
            </Modal>
        );
    };

    const endTest = async () => {
        const res = await putData(
            `/test_user/${params.id}/end?u_seq=${user_info.u_seq}`,
        );

        if (res.data === 'success') {
            //alert("제출 완료되었습니다.");
            setToast(true);
            setLoading(true);
            //navigate(-1);
        }
    };

    const Grading = ({ gr }) => {
        let isCheck = -1;
        detail.forEach(function (v) {
            if (Number(v.td_num) === Number(gr.q_seq)) {
                if (Number(v.td_user_num) === Number(gr.q_answer)) {
                    isCheck = 1;
                } else {
                    if (Number(v.td_user_num) === -1) {
                    } else {
                        isCheck = 0;
                    }
                }
            }
        });

        if (isCheck === 1) {
            return (
                <img
                    src={correct}
                    className={`${testStyles.grading}`}
                    alt="correct"
                />
            );
        } else if (isCheck === 0) {
            return (
                <img
                    src={wrong}
                    className={`${testStyles.grading}`}
                    alt="wrong"
                />
            );
        } else {
            return <></>;
        }
    };

    const tdActive = (v, i, no) => {
        let className = ``;

        if (isEnd) {
            if (Number(v.user_num) === i) className = `${testStyles.active}`;
            if (Number(v.q_answer) === i) className += ' text-danger';
        } else {
            detail.forEach(function (vv) {
                if (
                    Number(vv.td_user_num) === i &&
                    Number(vv.td_num) === Number(no)
                ) {
                    className = `${testStyles.active}`;
                }
                return false;
            });
        }

        return className;
    };

    const omrActive = (no, target) => {
        const obj = {
            t_seq: params.id,
            u_seq: user_info.u_seq,
            td_num: target,
            td_user_num: no,
        };

        let temp = [...detail];
        let isChk = false;

        temp.forEach(function (v) {
            if (Number(v.td_num) === Number(target)) {
                isChk = true;
                return false;
            }
        });

        if (isChk) {
            const t = temp.map(v =>
                Number(v.td_num) === Number(target)
                    ? { ...v, td_user_num: no }
                    : v,
            );
            setDetail(t);
        } else {
            setDetail([...detail, obj]);
        }
    };

    const [msg, setMsg] = useState('');

    const toastMsg = () => {
        if (!msg) {
            return (
                <div className="toast-div">
                    <div>수고하셨습니다!</div>
                    <div>결과조회 페이지에서 확인이 가능합니다.</div>
                </div>
            );
        } else {
            return (
                <div className="toast-div">
                    <div>{msg}</div>
                </div>
            );
        }
    };

    const labelArray = ['①', '②', '③', '④', '⑤'];

    const [scrap, setScrap] = useState([]);

    useEffect(() => {
        getScrap();
    }, []);

    const getScrap = async () => {
        const res = await getData(
            `/scrap/${params.id}/?u_seq=${user_info.u_seq}`,
        );

        setScrap(res.data);
    };

    const onScrap = e => {
        const tempScrap = [...scrap];
        let isSCrapCheck = false;
        tempScrap.forEach(function (x) {
            if (Number(x.id) === Number(e)) {
                isSCrapCheck = true;
            }
        });

        if (isSCrapCheck) {
            const filter = tempScrap.filter(v => v.id !== e);

            setScrap([...filter]);
            setMsg('해당 문제가 스크랩 취소 되었습니다.');
        } else {
            setScrap([...tempScrap, { id: e, type: '스크랩' }]);
            setMsg('해당 문제가 스크랩 되었습니다.');
        }
    };

    useEffect(() => {
        if (msg) {
            if (msg.indexOf('스크랩') !== -1) {
                scrapHandler();
            } else {
                setToastM(true);
            }
        }
    }, [msg]);

    const scrapHandler = async () => {
        let obj = {
            u_seq: user_info.u_seq,
            s_group: JSON.stringify(scrap),
        };

        const res = await postData(`/scrap`, { obj: obj });

        if (res.data === 'success') {
            if (params.type === 'ox') {
                drawContent();
            }
            setToastM(true);
        }
    };

    useEffect(() => {
        if (isToastM) {
            setTimeout(() => {
                setMsg('');
                setToastM(false);
            }, 2000);
        }
    }, [isToastM]);

    const cancelTest = () => {
        setConfirmText({
            title: '응시를 취소하시겠습니까?',
            message: '취소시 재응시가 불가능 합니다.',
        });
    };

    const backTest = () => {
        if (isStep === 'wait') {
            navigate(-1);
        } else {
            setConfirmText({
                title: '시험을 중단하시겠습니까?',
                message: '',
            });
        }
    };

    const pdfExportComponent = useRef(null);
    const handleExportWithComponent = () => {
        pdfExportComponent.current.save();
    };

    const testWord = ex => {
        let str = '';
        ex.split('').forEach(function (v) {
            if (v === '(') {
                str += `<span class="${testStyles.font} ${testStyles.under} text-primary">`;
            } else if (v === ')') {
                str += '</span>';
            } else if (v === '#') {
                str += '"';
            } else {
                str += v;
            }
        });

        return str;
    };

    async function limitUpdate(limit) {
        await putData(
            `/test_user/${params.id}/limit?u_seq=${user_info.u_seq}&tu_limit=${limit}`,
        );
    }

    function ScopeTest() {
        let arr = [];

        let scope = data.sort((a, b) => a.q_num - b.q_num);

        /////////////////임시방편 범위
        let times = Number(testInfo.t_times.split('회차')[0]);
        let strat_range;
        let end_range;
        if (testInfo.t_curri === '초급') {
            strat_range = times * 100 - 99;
            end_range = times * 100;
        } else if (testInfo.t_curri === '중급') {
            strat_range = times * 200 - 99;
            end_range = times * 200;
        } else if (testInfo.t_curri === '고급') {
            strat_range = times * 300 - 99;
            end_range = times * 300;
        }
        //////////////////////////

        return testInfo.b_copyright === 0 ? (
            <p>
                <span className="fw-bold">{scope[0]?.q_cate}</span> ~{' '}
                <span className="fw-bold">
                    {scope[scope.length - 1]?.q_cate}
                </span>{' '}
                입니다.
            </p>
        ) : (
            // <p>
            //   <span className="fw-bold">
            //     {scope[0]?.q_num}. {scope[0]?.q_title}
            //   </span>{" "}
            //   ~{" "}
            //   <span className="fw-bold">
            //     {scope[scope.length - 1]?.q_num}. {scope[scope.length - 1]?.q_title}
            //   </span>{" "}
            //   입니다.
            // </p>
            <p>
                <span className="fw-bold">{strat_range}</span>번 ~{' '}
                <span className="fw-bold">{end_range}</span>번 입니다.
            </p>
        );
    }
    console.log('resData : ', resData);
    const Top = () => {
        let topArray = [];

        for (let i = 0; i < total; i++) {
            if (i === total - 1) {
                let obj = {
                    start: i * qcount + 1,
                    end: count,
                };

                topArray.push(obj);
            } else {
                let obj = {
                    start: i * qcount + 1,
                    end: (i + 1) * qcount,
                };

                topArray.push(obj);
            }
        }
        console.log('topArray : ', topArray);
        if (resData.b_type === 4) {
            return topArray.map((v, i) => (
                <span
                    key={i + 1}
                    className={`${
                        testStyles.topSpan
                    } pointer d-inline-block p-2 ${
                        page === i + 1 ? testStyles.topOn : null
                    }`}
                    onClick={() => handleChange(i + 1)}>
                    {i + 1}회차
                </span>
            ));
        } else {
            return topArray.map((v, i) => (
                <span
                    key={i + 1}
                    className={`${
                        testStyles.topSpan
                    } pointer d-inline-block p-2 ${
                        page === i + 1 ? testStyles.topOn : null
                    }`}
                    onClick={() => handleChange(i + 1)}>
                    {v.start} ~ {v.end}
                </span>
            ));
        }
    };

    const Numbering = ({ index }) => {
        if (page > 1) {
            const upIndex = 100 * (page - 1);
            return (
                <span className={testStyles.font}>{upIndex + index + 1}. </span>
            );
        } else {
            return <span className={testStyles.font}>{index + 1}. </span>;
        }
    };

    const heasulDiv = v => {
        const arr = detail.filter(vv => vv.td_num === v.q_seq);
        if (arr[0].td_user_num !== -1) {
            return (
                <>
                    {v.q_comment && (
                        <div className={`${testStyles.preTitle} text-primary`}>
                            해설
                        </div>
                    )}

                    {v.q_comment && (
                        <pre className={testStyles.pre}>
                            {parser(v.q_comment)}
                        </pre>
                    )}

                    {v.q_heasuck && (
                        <div className={`${testStyles.preTitle} text-primary`}>
                            해석
                        </div>
                    )}

                    {v.q_heasuck && (
                        <pre className={testStyles.pre}>
                            {parser(v.q_heasuck)}
                        </pre>
                    )}
                </>
            );
        } else {
            return <></>;
        }
    };

    return (
        <>
            {/* {params.type === "end" && <Print />} */}
            <UpDown />
            {isEnd ? (
                <Header title={title} button={button} />
            ) : timeAttack === 'Y' ? (
                <Header
                    title={min && <Timer title={title} bun={min} />}
                    button={button}
                />
            ) : (
                <Header title={title} />
            )}
            {isEnd ? (
                <div className={`${testStyles.bottombtn} p-3`}>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => navigate(-1)}>
                        목록으로
                    </Button>
                </div>
            ) : params.type === 'ox' ? (
                <div className={`${testStyles.bottombtn} p-3`}>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => navigate(-1)}>
                        시험종료
                    </Button>
                </div>
            ) : (
                <div className={`${testStyles.bottombtn} p-3`}>
                    <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        onClick={() => sendTest()}>
                        오답확인
                    </Button>
                    <span className="mx-2"> </span>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() =>
                            testInfo.t_type === '성과관리'
                                ? cancelTest()
                                : backTest()
                        }>
                        {testInfo.t_type === '성과관리'
                            ? '응시취소'
                            : '목록으로'}
                    </Button>
                </div>
            )}

            <PDFExport ref={pdfExportComponent} paperSize="A4">
                <Container style={{ background: '#fff' }}>
                    <div
                        style={{
                            position: 'fixed',
                            top: '60px',
                            background: '#fff',
                            zIndex: 1000,
                            borderTop: '1px solid #ddd',
                            borderBottom: '1px solid #ddd',
                            width: '100%',
                            left: 0,
                            overflowX: 'scroll',
                        }}>
                        <div style={{ width: total * 100 + 'px' }}>
                            <Top />
                        </div>
                    </div>
                    <div
                        className={testStyles.nameWrap}
                        style={{ marginTop: '130px' }}>
                        <span> {user_info.u_name} 님</span>
                    </div>

                    <main className={testStyles.main}>
                        <div className={testStyles.testTop}>
                            {testInfo.t_type} <span>{testInfo.b_cate}</span>
                        </div>
                        <div className={testStyles.testName}>{title}</div>
                        {resData.b_type === 4 ? (
                            <>
                                {data.map((v, i) => (
                                    <div
                                        className="mb-5 position-relative"
                                        key={v.q_seq}>
                                        <Grading gr={v} />
                                        <pre
                                            className={testStyles.font}
                                            WIDTH="200px">
                                            {v.q_num}.{' '}
                                            {parser(v.q_title.replace('•', ''))}
                                        </pre>

                                        {v.q_img && (
                                            <div className="text-center no-print">
                                                <img
                                                    src={v.q_img}
                                                    className="mb-4"
                                                    alt="보기 이미지"
                                                />
                                            </div>
                                        )}

                                        {v.q_explain && (
                                            <div
                                                className={`${testStyles.jimoon} mb-4 p-3`}>
                                                {parser(
                                                    `<pre class="jimoonWrap mb-0" style="font-family: 'ChosunSm';font-size:14px;font-weight:300 !important;">${v.q_explain.replace(
                                                        /#/g,
                                                        '"',
                                                    )}</pre>`,
                                                )}
                                            </div>
                                        )}

                                        <CheckForm2
                                            index={i}
                                            radioChange={radioChange}
                                            v={v}
                                        />

                                        {heasulDiv(v)}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {data.map((v, i) => (
                                    <div
                                        className="mb-5 position-relative"
                                        key={v.q_seq}>
                                        <Grading gr={v} />
                                        {cate === '국어' ? (
                                            <pre
                                                className={testStyles.font}
                                                WIDTH="200px">
                                                <Numbering index={i} />{' '}
                                                {v.q_title.replace('•', '')}
                                            </pre>
                                        ) : (
                                            <p className={testStyles.font}>
                                                <Numbering index={i} />
                                                {
                                                    <span
                                                        className={
                                                            testStyles.font
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                            __html: testWord(
                                                                v.q_explain,
                                                            ),
                                                        }}></span>
                                                }
                                            </p>
                                        )}
                                        {cate === '영어' && (
                                            <p>뜻 : {v.q_mean}</p>
                                        )}
                                        {cate === '국어' && (
                                            <p>표준어 : {v.q_pyo}</p>
                                        )}
                                        {/* 
                <p>정답 : {v.q_answer}</p> */}

                                        <CheckForm2
                                            index={i}
                                            radioChange={radioChange}
                                            v={v}
                                        />
                                    </div>
                                ))}
                            </>
                        )}
                    </main>
                </Container>
            </PDFExport>

            {isToast && (
                <Toast
                    text={() => toastMsg()}
                    type="info"
                    time={2000}
                    isToast={isToast}
                    setToast={setToast}
                />
            )}
            {isToastM && (
                <Toast
                    text={() => toastMsg('')}
                    type="info"
                    time={2000}
                    isToast={isToastM}
                    setToast={setToastM}
                />
            )}
            <ModalOverlay />
        </>
    );
}
