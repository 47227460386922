import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { getData } from "../common/common";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

export default function BookInfoModal(props) {
  const [bookdata, SetBookdata] = useState([]);

  async function fetchBookData() {
    const res = await getData(`book/${props.b_seq}/bottomcontent`);
    if (res.data) SetBookdata(res.data);
  }

  useEffect(() => {
    fetchBookData();
  }, [props.b_seq]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" style={{ fontSize: "14px" }}>
            <div className="mb-2">책이름 : {bookdata.b_name || "-"}</div>
            <div className="mb-2">저자 : {bookdata.b_author || "-"}</div>
            <div className="mb-2">출판사 : {bookdata.b_publisher || "-"}</div>
            <div className="mb-2">과목 : {bookdata.b_cate || "-"}</div>
            <div className="mb-2">책종류 : {bookdata.b_jong || "-"}</div>
            <div className="mb-2">한 줄 서평 : {bookdata.b_review || "-"}</div>
            <div className="mb-2">ISBN-13 : {bookdata.b_isbn || "-"}</div>
            <div className="mb-2">출간일 : {bookdata.b_pub_date || "-"}</div>
            <div className="mb-2">가격 : {bookdata.b_price || "-"}</div>
            <div className="mb-2">페이지수 : {bookdata.b_page || "-"}</div>
            <div className="mb-2">특징 : {bookdata.b_char || "-"}</div>
            <div className="mb-2">수험분야 : {bookdata.b_exam || "-"}</div>
            <div className="mb-2">이용대상 : {bookdata.b_target || "-"}</div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
