import React, { useCallback, useEffect, useState } from "react";
import { currentDate, getData, getDate, returnDate } from "../common/common";
import sliderBoxStyles from "../css/sliderBox.module.css";
import { EmptyList } from "./EmptyList";
import { KeywordInput } from "../components/KeywordInput";
import { userinfoStore } from "../zustand/userinfoStore";
import cal from "../img/Todaycal.png";
import search from "../img/search.png";
import gImage from "../img/gDefault.jpg";
import { CalendarModal } from "../components/CalendarModal";
import { Toast } from "./Toast";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Paper } from "@mui/material";
import { CollapseTable } from "./CollapseTable";

export const SliderBox = ({ sid, main, quiz, b_seq, hd, now_curri, total }) => {
  const user_info = userinfoStore((state) => state.user_info);

  const categorys = ["오늘의 시험", "미응시 시험", "결과조회"];

  const Contents = ({ index }) => {
    let init = quiz
      ? [
          {
            id: 1,
            title: "쪽지시험",
            category: categorys,
            data: [],
            active: 0,
            on: "전체",
            value: currentDate(),
            page: 1,
            cate: [],
            keyword: "",
          },
        ]
      : [
          {
            id: 1,
            title: "기출관리",
            category: categorys,
            data: [],
            active: 0,
            on: "전체",
            value: currentDate(),
            page: 1,
            cate: [],
            keyword: "",
          },
          {
            id: 2,
            title: "암기관리",
            category: categorys,
            data: [],
            active: 0,
            on: "전체",
            value: currentDate(),
            page: 1,
            cate: [],
            keyword: "",
          },
          {
            id: 3,
            title: "성과관리",
            category: categorys,
            data: [],
            active: 0,
            on: "전체",
            value: currentDate(),
            page: 1,
            cate: [],
            keyword: "",
          },
        ];
    const [contentArray, setContentArray] = useState(init);

    const getMainContent = async () => {
      // console.log("category[active] : ", category[active]);

      const reqArray = [
        `u_seq=${user_info.u_seq}`,
        `ppp=${10}`,
        `page=${contentArray[index].page}`,
        `value=${contentArray[index].value}`,
        `title=${contentArray[index].title}`,
        `on=${contentArray[index].on}`,
        `active=${categorys[contentArray[index].active]}`,
        `keyword=${contentArray[index].keyword}`,
        `b_seq=${b_seq}`,
      ];

      const res = await getData(`/test/0/my?${reqArray.join("&")}`);

      let tempCate = [];
      res.data.rows.forEach(function (v) {
        let isDupli = true;
        tempCate.forEach(function (vv) {
          if (vv === v.b_cate) {
            isDupli = false;
          }
        });
        if (isDupli) {
          tempCate.push(v.b_cate);
        }
      });

      let temp = contentArray.map((v) =>
        v.title === contentArray[index].title
          ? { ...v, data: res.data.rows, cate: tempCate }
          : v
      );

      setContentArray(temp);
    };

    const categoryHandler = (idx) => {
      let temp = contentArray.map((v, i) =>
        i === index ? { ...v, active: idx } : v
      );
      setContentArray(temp);
    };

    const cateChagne = (cate) => {
      let temp = contentArray.map((v, i) =>
        i === index ? { ...v, on: cate } : v
      );
      setContentArray(temp);
    };
    const [isNavi, setNavi] = useState(false);
    const dataChange = (value) => {
      let temp = contentArray.map((v, i) =>
        i === index ? { ...v, value: getDate(value) } : v
      );
      setContentArray(temp);
      setNavi((prev) => !prev);
    };

    const onKeyword = (value) => {
      console.log("value : ", value);
      let temp = contentArray.map((v, i) =>
        i === index ? { ...v, keyword: value } : v
      );
      setContentArray(temp);
    };

    const naviHandler = () => {
      setNavi((prev) => !prev);
    };

    useEffect(() => {
      getMainContent();
    }, [
      contentArray[index].active,
      contentArray[index].on,
      contentArray[index].value,
      contentArray[index].keyword,
    ]);

    const getModalData = async () => {
      const res = await getData(
        `/test/${modalData.info.t_uuid}/modalList?u_seq=${user_info.u_seq}`
      );
      console.log("getModalData : ", res.data);
      setmodalData({
        ...modalData,
        data: res.data,
      });
    };

    function SimpleDialog() {
      const handleListItemClick = (value) => {
        //onClose(value);
      };

      const onClose = () => {
        setmodalData({
          info: 0,
          data: [],
        });
        setOpen(false);
      };

      const numWrap = () => {
        const newItem = [];
        const temp = [...modalData.data];
        temp.forEach(function (v) {
          newItem.push(v.t_hoedock);
        });
        const set = new Set(newItem);
        const uniqueArr = [...set];
        console.log("set : ", uniqueArr);
        return uniqueArr;
      };

      console.log("d : ", contentArray);

      return (
        <Dialog
          onClose={onClose}
          open={open}
          sx={{ maxHeight: "400px", top: "30%" }}
        >
          <DialogTitle
            sx={{
              fontWeight: "bold",
              borderBottom: "1px solid #ddd",
              marginBottom: "16px",
            }}
          >
            {modalData.info.t_name}
          </DialogTitle>

          {numWrap().map((vv) => (
            <List
              sx={{
                pt: 0,
                minWidth: "300px",
                fontSize: "14px",
              }}
            >
              <h6 className="px-3 h-border d-inline-block"> {vv}회독</h6>
              {modalData.data.map(
                (v) =>
                  vv === v.t_hoedock && (
                    <ListItem
                      button
                      onClick={() => goModalAction(v.t_seq, v.endCheck)}
                      key={v.t_seq}
                    >
                      {v.endCheck === 1 ? (
                        <ListItemText
                          primary={
                            v.t_name +
                            " " +
                            v.t_times +
                            " " +
                            v?.t_start_date +
                            " (완료)"
                          }
                        />
                      ) : v.t_retest === 1 ? (
                        <ListItemText
                          primary={
                            v.t_name +
                            " " +
                            v.t_times +
                            " " +
                            v?.t_start_date +
                            " (재시험)"
                          }
                        />
                      ) : (
                        <ListItemText
                          primary={
                            v.t_name + " " + v.t_times + " " + v?.t_start_date
                          }
                        />
                      )}

                      <KeyboardArrowRightIcon />
                    </ListItem>
                  )
              )}
            </List>
          ))}
        </Dialog>
      );
    }

    const [open, setOpen] = useState(false);

    const [modalData, setmodalData] = useState({
      info: 0,
      data: [],
    });

    const handleClickOpen = () => {
      setOpen(true);
    };

    useEffect(() => {
      if (modalData.info) {
        getModalData();

        setTimeout(() => {
          handleClickOpen();
        }, 100);
      }
    }, [modalData.info]);

    const navigate = useNavigate();
    const goTest = async (v, isEnd) => {
      console.log("isEnd : ", isEnd);
      if (contentArray[index].title === "암기관리") {
        setmodalData({
          ...modalData,
          info: v,
        });
      } else {
        const res = await getData(`/test/${v.t_seq}/time`);

        if (contentArray[index].active === 2) {
          navigate(`/test/${v.t_seq}/end`);
        } else {
          if (res.data.type === "yet" || res.data.type === "close") {
            setMsg(res.data.message);
          } else {
            if (isEnd === 1) {
              navigate(`/test/${v.t_seq}/end`);
            } else {
              navigate(`/test/${v.t_seq}`);
            }
          }
        }
      }
    };

    const goModalAction = async (target_id, isEnd) => {
      console.log("isEnd : ", isEnd);

      const res = await getData(`/test/${target_id}/time`);

      if (contentArray[index].active === 2) {
        navigate(`/test/${target_id}/end`);
      } else {
        if (res.data.type === "yet" || res.data.type === "close") {
          setMsg(res.data.message);
        } else {
          if (isEnd === 1) {
            navigate(`/test/${target_id}/end`);
          } else {
            navigate(`/test/${target_id}`);
          }
        }
      }
    };

    const [msg, setMsg] = useState("");
    const [isToast, setToast] = useState("");

    const toastMsg = () => {
      return (
        <div className="toast-div">
          <div>{msg}</div>
        </div>
      );
    };

    useEffect(() => {
      if (msg) {
        setToast(true);
      }
    }, [msg]);

    useEffect(() => {
      if (isToast) {
        setTimeout(() => {
          setToast(false);
          setMsg("");
        }, [2000]);
      }
    }, [isToast]);

    const imageBg = (thm) => {
      if (!thm) {
        return gImage;
      } else {
        return thm;
      }
    };

    return (
      <div>
        {open && <SimpleDialog />}
        <div
          className={`${sliderBoxStyles.title} ${
            !main && sliderBoxStyles.borderNone
          }`}
        >
          <div className={sliderBoxStyles.titleWrap}>
            {main && (
              <span className="fw-bold">{contentArray[index].title}</span>
            )}

            <div className={`${sliderBoxStyles.categoryWrap} text-white`}>
              {contentArray[index].category.map((v, i) =>
                main ? (
                  i < 2 && (
                    <span
                      className={`${sliderBoxStyles.category} ${
                        i === contentArray[index].active
                          ? sliderBoxStyles.active
                          : null
                      }`}
                      key={i}
                      onClick={() => categoryHandler(i)}
                    >
                      {v}
                    </span>
                  )
                ) : (
                  <span
                    className={`${sliderBoxStyles.category} ${
                      i === contentArray[index].active
                        ? sliderBoxStyles.active
                        : null
                    }`}
                    key={i}
                    onClick={() => categoryHandler(i)}
                  >
                    {v}
                  </span>
                )
              )}
            </div>
          </div>
          {/* {!main && (
            <div className="mt-2 position-relative">
              <input
                type="text"
                className={sliderBoxStyles.keyword}
                value={contentArray[index].keyword}
                onChange={(e) => onKeyword(e.target.value)}
              />
              <img src={search} alt="search" className={sliderBoxStyles.ab} />
            </div>
          )} */}
        </div>
        {!main && contentArray[index].active !== 2 && (
          <KeywordInput
            value={contentArray[index].keyword}
            onChange={(e) => onKeyword(e.target.value)}
          />
        )}
        {contentArray[index].data.length === 0 && (
          <div
            className="text-center text-white"
            style={{ marginTop: !main ? "70px" : "0" }}
          >
            <EmptyList text={`시험 정보가 존재하지 않습니다.`} />
          </div>
        )}
        {/* {contentArray[index].active === 2 && (
          <div className="mt-4 mb-5">
            {contentArray[index].data.map((row, i) => (
              <CollapseTable
                row={row}
                u_seq={user_info.u_seq}
                hd={hd}
                now_curri={now_curri}
              />
            ))}
          </div>
        )} */}

        <div className="mt-3">
          {contentArray[index].data.length > 0 &&
            contentArray[index].data.map((v, i) => (
              <div className={sliderBoxStyles.contentDiv}>
                <Paper key={i} onClick={() => goTest(v, v.endCheck)}>
                  <div
                    className={sliderBoxStyles.thumb}
                    style={{ backgroundImage: `url(${imageBg(v.b_thumb)})` }}
                  ></div>
                  {index === 1 ? (
                    <div className={sliderBoxStyles.name}>{v.t_name}</div>
                  ) : (
                    <div className={sliderBoxStyles.name}>{v.t_name}</div>
                  )}
                  {index === 2 ? (
                    <div className={sliderBoxStyles.date}>
                      {v.t_start_date} {v.t_time}
                    </div>
                  ) : (
                    <div className={sliderBoxStyles.date}>{v.t_start_date}</div>
                  )}
                  {/* {v.t_timer && v.t_yn_time === "Y" && (
                  <div className={sliderBoxStyles.timer}>
                    타임어택 : <span className="text-danger">{v.t_timer}</span>{" "}
                    분
                  </div>
                )} */}
                </Paper>
              </div>
            ))}
        </div>
        {isNavi && (
          <CalendarModal
            value={returnDate(contentArray[index].value)}
            onChange={dataChange}
            setNavi={setNavi}
            type={contentArray[index].title}
          />
        )}
        {isToast && (
          <Toast
            text={() => toastMsg()}
            type="info"
            time={2000}
            isToast={isToast}
            setToast={setToast}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Contents index={sid} />
    </div>
  );
};
