import React, { useEffect, useRef, useState } from "react";

import { getData } from "../common/common";
import netStyles from "../css/net.module.css";
import BookIcon from "@mui/icons-material/Book";
import CustomizedDialogs from "./Dialog";
import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { userinfoStore } from "../zustand/userinfoStore";
import gImage from "../img/gDefault.jpg";
import { Paper } from "@mui/material";
import {
  useMotionTemplate,
  useMotionValue,
  useSpring,
  motion,
} from "framer-motion";

export const Net = ({
  icon,
  title,
  url,
  type,
  titleShow,
  itemHanlder,
  forceRender,
  setForce,
  itemData = [],
}) => {
  const [data, setData] = useState([]);

  //const [forceRender, setForce] = useState(false);
  const user_info = userinfoStore((state) => state.user_info);

  useEffect(() => {
    getBook();
  }, []);

  const imageBg = (thm) => {
    if (!thm) {
      return gImage;
    } else {
      return thm;
    }
  };

  useEffect(() => {
    if (forceRender) {
      reGet();
    }
  }, [forceRender]);

  const reGet = async () => {
    const bookInfo = await getData(`/user/${user_info.u_seq}`);

    if (bookInfo.data.u_book) {
      if (JSON.parse(bookInfo.data.u_book).length > 0) {
        const res = await getData(`/book/my/${bookInfo.data.u_book}`);

        setData(res.data);
        setForce(false);
      } else {
        setData([]);
        setForce(false);
      }
    }
  };

  const getBook = async () => {
    if (url === "/book/my/0") {
      const bookInfo = await getData(`/user/${user_info.u_seq}`);

      if (bookInfo.data.u_book) {
        if (JSON.parse(bookInfo.data.u_book).length > 0) {
          const res = await getData(`/book/my/${bookInfo.data.u_book}`);

          let tempBook = res.data.filter((v) => v.b_type === 1);
          setData(tempBook);
        }
      }
    } else if (url === "/book/my/0/기출") {
      const bookInfo = await getData(`/user/${user_info.u_seq}`);

      if (bookInfo.data.u_book) {
        if (JSON.parse(bookInfo.data.u_book).length > 0) {
          const res = await getData(`/book/my/${bookInfo.data.u_book}`);

          let tempBook = res.data.filter((v) => v.b_type === 3);
          setData(tempBook);
        }
      }
    } else {
      const res = await getData(url);
      setData(res.data);
    }
  };

  const openBook = () => {};

  const Item = ({ v }) => {
    const styles = {
      paperContainer: {
        backgroundImage: `url(${imageBg(v.b_thumb)})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100%",
      },
    };

    return (
      <div
        style={{
          textAlign: "center",
          height: "200px",
          width: "150px",
          padding: "10px",
        }}
        onClick={() => itemHanlder(v.b_seq, v.b_type)}
      >
        <Paper elevation={3} style={styles.paperContainer} />
        <p className="m-0 py-2 text-white">{v.b_name}</p>
      </div>
    );
  };

  const EmptyBox = () => {
    return (
      <div className={`${netStyles.emptyWrap}`}>
        <CustomizedDialogs setForce={setForce} />
      </div>
    );
  };

  return (
    <div style={{ margin: "5px 0 15px" }}>
      {titleShow && (
        <h6 className={netStyles.h6}>
          {title}
          {/* {title === "이용중인 교재" && data.length > 0 && (
            <CustomizedDialogs isData setForce={setForce} />
          )} */}
        </h6>
      )}
      {type && (
        <div className="mb-3">
          <span className={netStyles.type}>
            <BookIcon /> {type}
          </span>
        </div>
      )}
      {data.length === 0 && (
        <div>
          <Paper
            className="position-relative text-center"
            elevation={3}
            style={{ padding: "50px 0" }}
            onClick={openBook}
          >
            <InfoIcon
              style={{
                marginBottom: "10px",
                fontSize: "80px",
                color: "darkgray",
              }}
            />
            {user_info.u_seq ? (
              <p className="fw-bold m-0">
                {type || "이용중인"} 교재가 존재하지 않습니다.
              </p>
            ) : (
              <p className="fw-bold m-0">먼저 로그인해주세요.</p>
            )}
          </Paper>
        </div>
      )}
      {data.length > 0 && (
        <div className={netStyles.itemWrap}>
          <div style={{ display: "flex", width: data.length * 150 + "px" }}>
            {data.map((v, i) => (
              <Item v={v} key={i} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
