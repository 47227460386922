import React, { useEffect, useState, useCallback } from "react";
import { getData, postData } from "../common/common";
import { userinfoStore } from "../zustand/userinfoStore";

import Box from "@mui/material/Box";

import { useNavigate } from "react-router-dom";

import Chart from "react-apexcharts";

import { TopTen } from "./TopTen";

import btcStyles from "../css/bookTabCommunity.module.css";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { WrongList } from "./WrongList";
import ReactApexChart from "react-apexcharts";

export const BookTabCommunity = (props) => {
  console.log("props : ", props);
  /* 커리 선택 비율 */
  const [series4, setS4] = useState([0]);

  const options4 = {
    series: series4,
    labels: ["초급", "중급", "고급"],
    plotOptions: {
      pie: {
        expnadOnClick: false,
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
            },
          },
        },
      },
    },
  };

  const dList = [
    {
      name: "커리 선택 비율",
      options: options4,
      series: series4,
    },
  ];

  const user_info = userinfoStore((state) => state.user_info);
  const { b_seq } = props;
  const navigate = useNavigate();

  const [data, Setdata] = useState();
  const [myData, SetMydata] = useState({});
  const [tableData, setTableData] = useState([]);
  const [myAllData, SetMyAlldata] = useState({});
  const [currData, setCurrData] = useState([[], [], []]);
  const [bookdata, SetBookdata] = useState([]);

  const getAvg = async () => {
    const res = await getData(`/test_detail/${props.b_seq}/avg`);

    setCurrData(res.data);
  };

  const fetchBookData = useCallback(async () => {
    const res = await getData(`book/${b_seq}/bottomcontent`);
    if (res.data) SetBookdata(res.data);
  }, []);

  useEffect(() => {
    getAvg();
    fetchBookData();
  }, []);

  async function myFetchData() {
    const res = await getData(
      `/book/${props.b_seq}/myAvg?u_seq=${user_info.u_seq}`
    );
    SetMydata(res.data);
  }

  async function myFetchDataAll() {
    const res = await getData(
      `/book/${props.b_seq}/myAvg/total?u_seq=${user_info.u_seq}`
    );

    setTableData(res.data.tableData);
    SetMyAlldata(res.data);
  }

  useEffect(() => {
    myFetchData();
    myFetchDataAll();
  }, []);

  const currilist = ["암기율", "회독수", "틀린단어"];

  const nowScore = (index) => {
    let scoreArray = [];
    if (currData[index].length > 0) {
      currData[index].forEach(function (v, i) {
        if (i === 0) {
          let obj = {
            id: v.u_seq,
            avg: Number(v.avg),
            count: 1,
          };
          scoreArray.push(obj);
        } else {
          let isCheck = true;
          scoreArray.forEach(function (vv, ii) {
            if (vv.id === v.u_seq) {
              vv.avg += Number(v.avg);
              vv.count += 1;
              isCheck = false;
            }
          });

          if (isCheck) {
            let obj = {
              id: v.u_seq,
              avg: Number(v.avg),
              count: 1,
            };
            scoreArray.push(obj);
          }
        }
      });

      let sum = 0;
      scoreArray.forEach(function (v) {
        sum += v.avg / v.count;
      });

      sum = sum / scoreArray.length;
      return Math.round(sum);
    } else {
      return 0;
    }
  };

  /* 새로 작업 하는 코드들 여기부터 시작 */
  const chartInfo = {
    series: [
      {
        data: [myAllData?.times, myAllData?.hd, myAllData?.avg],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: "auto",
        toolbar: { show: false },
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: "30%",
          labels: { show: false },
        },
      },
      dataLabels: {
        enabled: true,
        color: "#fff",
      },
      xaxis: {
        categories: ["학습시간", "회독수", "누적 암기율"],
      },
    },
  };

  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [finalData, setFinalData] = useState([]);
  const drawAllList = async () => {
    const res = await getData(
      `/test/${props.b_seq}/finalList?u_seq=${user_info.u_seq}`
    );
    setFinalData(res.data);
  };

  useEffect(() => {
    drawAllList();
  }, []);

  const tableItem = (cn) => {
    let obj = {
      avg: "-",
      hd: "-",
      wrong: "-",
    };
    if (tableData.length > 0) {
      if (tableData.filter((v) => v.curri === cn).length > 0) {
        obj.avg = tableData.filter((v) => v.curri === cn)[0].avg + "%";
        obj.hd = tableData.filter((v) => v.curri === cn)[0].hd + "회독";
        obj.wrong = tableData.filter((v) => v.curri === cn)[0].wrong;
      }
    }

    return obj;
  };
  return (
    <>
      <div className="text-center">
        <h5 className={`text-white fw-bold ${btcStyles.title}`}>
          {myData?.b_name} 학습 통계 분석
        </h5>
      </div>
      <div className="d-flex">
        <div className={`${btcStyles.box} ${btcStyles.left}`}>
          {user_info.u_profile ? (
            <img
              src={user_info.u_profile}
              alt="프로필 이미지"
              style={{ width: "95%", height: "95%" }}
            />
          ) : (
            <img
              src={bookdata.b_thumb}
              alt="프로필 이미지"
              style={{ width: "95%", height: "95%" }}
            />
          )}
        </div>
        <div className={`${btcStyles.box} ${btcStyles.right}`}>
          <div className="d-flex mb-xl-5 mb-3">
            <div className="w-50">
              <span className={btcStyles.btn}>{myData?.curri}커리</span>
            </div>
            <div className={`w-50 text-center ${btcStyles.lineHeight}`}>
              <span className="text-danger fw-bold">{myData?.endData}</span>{" "}
              <span className="text-white">/</span>{" "}
              <span className="text-primary fw-bold">{myData?.totalData}</span>{" "}
              <span className="text-white">회차</span>
            </div>
          </div>
          <div className="d-flex mb-1">
            <div className={btcStyles.box2}>
              <div className="fw-bold text-center">현재</div>
              <div>
                <p className="mb-0 text-primary">
                  {myData?.hd}
                  <span className="text-white">회독</span>
                </p>
              </div>
            </div>
            <div className={btcStyles.box2}>
              <div className="fw-bold  text-center">암기율</div>
              <div>
                <p className="mb-0  text-primary">
                  {myData?.avg}
                  <span className="text-white">%</span>
                </p>
              </div>
            </div>
            <div className={btcStyles.box2}>
              <div className="fw-bold  text-center">미응시</div>
              <div>
                <p className="mb-0  text-primary">
                  {myData?.totalData - myData?.endData}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="w-50 fw-bold text-center text-white">
              <div className={btcStyles.bottom}>시작일</div>
              <div className={btcStyles.top}>{myData?.start}</div>
            </div>
            <div className="w-50 fw-bold text-center text-white">
              <div className={btcStyles.bottom}>종료일</div>
              <div className={btcStyles.top}> {myData?.end}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4" style={{ marginTop: "20px" }}>
        <span
          className="btn btn-success  w-100"
          onClick={() => setOpen((prev) => !prev)}
        >
          오늘의 랭킹 TOP 10
        </span>
      </div>
      <TopTen b_seq={b_seq} open={open} setOpen={setOpen} />

      <div className="">
        <h5 className={`fw-bold ${btcStyles.subtitle}`}>학습이력</h5>
      </div>
      <div>
        <div id="chart" className="graph_chart">
          <ReactApexChart
            options={chartInfo.options}
            series={chartInfo.series}
            type="bar"
            height={350}
          />
        </div>
      </div>

      <TableContainer sx={{ marginBottom: "1.5rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell className="fw-bold text-white">초급</TableCell>
              <TableCell className="fw-bold text-white">중급</TableCell>
              <TableCell className="fw-bold text-white">고급</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                className="fw-bold text-white"
                style={{ padding: "7px 16px" }}
              >
                암기율
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {tableItem("초급").avg}
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {tableItem("중급").avg}
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {tableItem("고급").avg}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="fw-bold text-white"
                style={{ padding: "7px 16px" }}
              >
                회독수
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {" "}
                {tableItem("초급").hd}
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {" "}
                {tableItem("중급").hd}
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {" "}
                {tableItem("고급").hd}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                className="fw-bold text-white"
                style={{ padding: "7px 16px" }}
              >
                틀린단어
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {tableItem("초급").wrong}
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {tableItem("중급").wrong}
              </TableCell>
              <TableCell className="text-white" style={{ padding: "5px 16px" }}>
                {tableItem("고급").wrong}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="">
        <h5 className={`fw-bold ${btcStyles.subtitle}`}>전체테스트 이력</h5>
      </div>
      <TableContainer sx={{ marginBottom: "1.5rem" }}>
        <Table>
          <TableBody>
            {finalData.map((v, index) => (
              <TableRow key={index}>
                <TableCell
                  className="fw-bold text-white"
                  style={{ padding: "7px 16px" }}
                >
                  {v.ta_date}
                </TableCell>
                <TableCell
                  className="text-white"
                  style={{ padding: "5px 16px" }}
                >
                  {v.ta_score}점
                </TableCell>
                {v.ta_score >= 99 ? (
                  <TableCell
                    className="text-success text-white"
                    style={{ padding: "5px 16px" }}
                  >
                    Success
                  </TableCell>
                ) : (
                  <TableCell
                    className="text-danger"
                    style={{ padding: "5px 16px" }}
                  >
                    Fail
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {finalData.length === 0 && (
        <div className="text-center mb-3 text-white">
          데이터가 존재 하지않습니다.
        </div>
      )}
    </>
  );
};
