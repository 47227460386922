import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import bdStyle from '../css/bookdetail.module.css';

//components
import { Header } from '../components/Header';
import BookTab from '../components/BookTab';
import gImage from '../img/gDefault.jpg';
//zustand
import { userinfoStore } from '../zustand/userinfoStore';

//axios
import {
    getData,
    postData,
    putData,
    currentDateTime,
    isUseDay,
} from '../common/common';

import GroupIcon from '@mui/icons-material/Group';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { BookModal } from '../components/BookModal';
import useCountUp from '../hook/useCountUp';
import { motion } from 'framer-motion';
import { Toast } from '../components/Toast';

import testStyles from '../css/test.module.css';

export const MoDetailView = () => {
    const user_info = userinfoStore(state => state.user_info);
    const params = useParams();

    const [gisuData, SetGisuData] = useState('');
    const [nowMonth, SetNowMonth] = useState(
        currentDateTime().split('-')[0] + '-' + currentDateTime().split('-')[1],
    );
    const [testData, SetTestData] = useState('');
    const [tab, SetTab] = useState('');
    const navigate = useNavigate();

    const getGisu = async () => {
        const res = await getData(
            `/gisu/${params.id}?u_seq=${user_info.u_seq}`,
        );
        if (res.data) {
            SetGisuData(res.data);
        }
    };

    useEffect(() => {
        getGisu();
    }, []);

    const getTest = async () => {
        const res = await getData(
            `/gisu/test/${params.id}?u_seq=${user_info.u_seq}&month=${nowMonth}&tab=${tab}`,
        );
        if (res.data) {
            SetTestData(res.data);
        }
    };

    useEffect(() => {
        getTest();
    }, [nowMonth, tab]);

    // console.log("testData >>", testData);

    const [msg, setMsg] = useState('');
    const [isToast, setToast] = useState('');

    const toastMsg = () => {
        return (
            <div className="toast-div">
                <div>{msg}</div>
            </div>
        );
    };

    useEffect(() => {
        if (msg) {
            setToast(true);
        }
    }, [msg]);

    useEffect(() => {
        if (isToast) {
            setTimeout(() => {
                setToast(false);
                setMsg('');
            }, [2000]);
        }
    }, [isToast]);

    const goPage = async (v, isEnd, moType, tu_re) => {
        console.log('v : ', v);
        if (isEnd === 1) {
            navigate(`/test/${v.t_seq}/end`);
        } else {
            if (tu_re) {
                if (moType === '주간') {
                    navigate(`/test_ju/${v.t_seq}`);
                } else {
                    navigate(`/test/${v.t_seq}`);
                }
            } else {
                console.log('체크체크');
                const res = await getData(`/test/${v.t_seq}/time`);
                if (res.data.type === 'yet' || res.data.type === 'close') {
                    setMsg(res.data.message);
                } else {
                    if (moType === '주간') {
                        navigate(`/test_ju/${v.t_seq}`);
                    } else {
                        navigate(`/test/${v.t_seq}`);
                    }
                }
            }
        }
    };

    const goPage_end = async v => {
        alert(
            '미응시 처리된 시험입니다. 정답해설 조회만 가능하며 마킹 및 제출하실 수 없습니다.',
        );
        navigate(`/result/${v.t_seq}/end`);
    };

    // async function reStart(t_seq) {
    //   if (
    //     window.confirm("재응시처리 하시겠습니까? ( 시험결과값이 초기화됩니다. )")
    //   ) {
    //     const res = await getData(`/gisu/${t_seq}/re?u_seq=${user_info.u_seq}`);
    //     if (res.data === "success") {
    //       getGisu();
    //       getTest();
    //     }
    //   }
    // }

    console.log('testData >>', testData);

    const TabContents = () => {
        console.log('컨텐츠 호출 직전 :', gisuData.g_sort);
        if (
            gisuData.g_sort === '경찰' ||
            gisuData.g_sort == '헌법' ||
            gisuData.g_sort == '경찰학' ||
            gisuData.g_sort == '형사법' ||
            gisuData.g_sort == '국어' ||
            gisuData.g_sort == '영어' ||
            gisuData.g_sort == '한국사'
        ) {
            return (
                <div
                    className={`${bdStyle.tab} py-2 ${
                        tab === '' && bdStyle.active
                    }`}
                    onClick={() => SetTab('')}>
                    전체
                </div>
            );
        } else {
            return (
                <>
                    <div
                        className={`${bdStyle.tab} py-2 ${
                            tab === '' && bdStyle.active
                        }`}
                        onClick={() => SetTab('')}>
                        전체
                    </div>
                    <div
                        className={`${bdStyle.tab} py-2 ${
                            tab === '주간' && bdStyle.active
                        }`}
                        onClick={() => SetTab('주간')}>
                        주간
                    </div>
                    <div
                        className={`${bdStyle.tab} py-2 ${
                            tab === '하프' && bdStyle.active
                        }`}
                        onClick={() => SetTab('하프')}>
                        하프
                    </div>
                </>
            );
        }
    };

    return (
        <motion.div
            className="oxPage"
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{ overflowY: 'hidden', position: 'relative' }}>
            <Header />
            <div
                className="container"
                style={{
                    paddingTop: '75px',
                    paddingBottom: '75px',
                    maxWidth: '768px',
                }}>
                <table
                    className="table border text-center text-white"
                    style={{ fontSize: '13px' }}>
                    <tbody>
                        <tr>
                            <th style={{ verticalAlign: 'middle' }}>등록일</th>
                            <td style={{ verticalAlign: 'middle' }}>
                                {gisuData.g_gisu || '-'}
                            </td>
                        </tr>
                        <tr>
                            <th style={{ verticalAlign: 'middle' }}>
                                수험분야
                            </th>
                            <td style={{ verticalAlign: 'middle' }}>
                                {gisuData.g_sort || '-'}
                            </td>
                        </tr>
                        <tr>
                            <th style={{ verticalAlign: 'middle' }}>
                                세부직렬
                            </th>
                            <td style={{ verticalAlign: 'middle' }}>
                                {gisuData.g_sebu || '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>

                {gisuData && gisuData.test_data.length > 0 ? (
                    <>
                        <input
                            type="month"
                            className="form-control form-control-sm"
                            value={nowMonth}
                            onChange={e => SetNowMonth(e.target.value)}
                        />
                        {nowMonth && testData.length > 0 ? (
                            <>
                                <table
                                    className="table border text-center text-white mt-3"
                                    style={{ fontSize: '13px' }}>
                                    <tbody>
                                        <tr>
                                            <th
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}>
                                                응시일
                                            </th>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}>
                                                {
                                                    gisuData.test_data[0]
                                                        .t_start_date
                                                }{' '}
                                                ~{' '}
                                                {
                                                    gisuData.test_data[
                                                        gisuData.test_data
                                                            .length - 1
                                                    ].t_start_date
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}>
                                                진행률
                                            </th>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}>
                                                (
                                                {
                                                    gisuData.test_data.filter(
                                                        v => v.tu_end_date,
                                                    ).length
                                                }{' '}
                                                / {gisuData.test_data.length}){' '}
                                                {(
                                                    (gisuData.test_data.filter(
                                                        v => v.tu_end_date,
                                                    ).length /
                                                        gisuData.test_data
                                                            .length) *
                                                    100
                                                ).toFixed(1)}{' '}
                                                %
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="d-flex align-items-center mt-4 mb-3">
                                    <TabContents />
                                </div>
                                <table
                                    className={`table border text-center bg-white mt-3 ${testStyles.moTable}`}
                                    style={{ fontSize: '12px' }}>
                                    <thead>
                                        <tr>
                                            <th>회차</th>
                                            <th>시험명</th>
                                            <th>응시일</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testData.map((v, i) => (
                                            <tr
                                                key={i}
                                                className={`${
                                                    v.t_motype === '주간' &&
                                                    tab === '' &&
                                                    bdStyle.bgactive
                                                }`}>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}>
                                                    {v.t_times || '-'}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}>
                                                    {v.t_name || '-'}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}>
                                                    {v.t_start_date || '-'}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}>
                                                    {v.t_hoedock_stop ? (
                                                        <span
                                                            className={`btn btn-sm ${
                                                                v.tu_end_date
                                                                    ? 'btn-success'
                                                                    : 'btn-danger'
                                                            }`}
                                                            style={{
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    v.tu_end_date
                                                                ) {
                                                                    goPage(
                                                                        v,
                                                                        v.endCheck,
                                                                        v.t_motype,
                                                                        v.tu_re,
                                                                    );
                                                                } else {
                                                                    alert(
                                                                        '중단 되었습니다.',
                                                                    );
                                                                    return;
                                                                }
                                                            }}>
                                                            {v.tu_end_date
                                                                ? '결과'
                                                                : '중단'}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {isUseDay(
                                                                v.t_start_date,
                                                            ) ? (
                                                                <span
                                                                    className={`btn btn-sm ${
                                                                        v.tu_end_date
                                                                            ? 'btn-success'
                                                                            : 'btn-dark'
                                                                    }`}
                                                                    style={{
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                    onClick={() =>
                                                                        goPage(
                                                                            v,
                                                                            v.endCheck,
                                                                            v.t_motype,
                                                                            v.tu_re,
                                                                        )
                                                                    }>
                                                                    {v.tu_end_date
                                                                        ? '결과'
                                                                        : '응시'}
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    {v.tu_nodate ? (
                                                                        <span
                                                                            className={`btn btn-sm ${
                                                                                v.tu_end_date
                                                                                    ? 'btn-success'
                                                                                    : 'btn-dark'
                                                                            }`}
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                            }}
                                                                            onClick={() =>
                                                                                goPage(
                                                                                    v,
                                                                                    v.endCheck,
                                                                                    v.t_motype,
                                                                                    v.tu_re,
                                                                                )
                                                                            }>
                                                                            {v.tu_end_date
                                                                                ? '결과'
                                                                                : '응시'}
                                                                        </span>
                                                                    ) : v.tu_end_date ? (
                                                                        <span
                                                                            className={`btn btn-sm ${
                                                                                v.tu_end_date
                                                                                    ? 'btn-success'
                                                                                    : 'btn-dark'
                                                                            }`}
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                            }}
                                                                            onClick={() =>
                                                                                goPage(
                                                                                    v,
                                                                                    v.endCheck,
                                                                                    v.t_motype,
                                                                                    v.tu_re,
                                                                                )
                                                                            }>
                                                                            {v.tu_end_date
                                                                                ? '결과'
                                                                                : '응시'}
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="btn btn-sm btn-primary"
                                                                            style={{
                                                                                fontSize:
                                                                                    '12px',
                                                                            }}
                                                                            onClick={() =>
                                                                                goPage_end(
                                                                                    v,
                                                                                )
                                                                            }>
                                                                            정답확인
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {/* {v.tu_end_date && (
                            <span
                              className="btn btn-sm btn-primary ms-2"
                              style={{ fontSize: "12px" }}
                              onClick={() => reStart(v.t_seq)}
                            >
                              재응시
                            </span>
                          )} */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <div className="text-center py-5 text-white">
                                배정된 시험이 없습니다.
                            </div>
                        )}
                    </>
                ) : (
                    <div className="text-center py-5 text-white">
                        배정된 시험이 없습니다.
                    </div>
                )}
            </div>
            {isToast && (
                <Toast
                    text={() => toastMsg()}
                    type="info"
                    time={2000}
                    isToast={isToast}
                    setToast={setToast}
                />
            )}
        </motion.div>
    );
};
