import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { putData } from "../common/common";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "400px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const DirectModal = ({ u_direct, directTest }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    console.log("asd");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const btnStyle = {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "-90px",
    maxWidth: "260px",
    width: "80%",
  };

  const Btn = React.useCallback(() => {
    return (
      <span className="btn btn-success" onClick={handleOpen} style={btnStyle}>
        암기를 완료하셨나요?
      </span>
    );
  }, [u_direct]);

  const typoStyle = {
    mt: 2,
    fontSize: "14px",
  };

  return (
    <div>
      <Btn />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={typoStyle}>
            - 학습시간관리 전문 영스파의 암기관리는 단순 암기 성과가 아닌,
            암기시간 자체를 관리하는 것이 중요한 포인트입니다. 같은 분량을 더
            오래 암기하면 경쟁에서 뒤쳐지기 때문입니다. <br />
            <br />
            실제 합격생의 암기 패턴을 분석하여 제공하는 영스파 커리큘럼 시간을
            준수하여 암기를 진행한다면, 시간대비 가장 효율적인 암기를 진행할 수
            있습니다. <br />
            <br />
            이미 학습이 완료되었거나 바로 시험을 응시해야 한다면 아래 버튼을
            눌러 시험을 시작해주세요.
          </Typography>

          <div className="text-center mt-3">
            <span className="btn btn-success w-100" onClick={directTest}>
              바로 시작하기
            </span>
            <span
              className="btn btn-secondary w-100 mt-2"
              onClick={handleClose}
            >
              닫기
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
