import React from "react";
import Pagination from "react-js-pagination";
// eslint-disable-next-line react/prop-types
export const Paging = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
}) => {
  return (
    <Pagination
      activePage={activePage}
      itemsCountPerPage={itemsCountPerPage}
      totalItemsCount={totalItemsCount}
      pageRangeDisplayed={5}
      prevPageText={"‹"}
      nextPageText={"›"}
      onChange={onChange}
    />
  );
};
