import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Toast } from "../components/Toast";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import QnaWriteStyles from "../css/qnawrite.module.css";
import { postData, getData } from "../common/common";
import { returnObj } from "../data/qna";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

export const QnaWrite = () => {
  const user_info = userinfoStore((state) => state.user_info);
  const params = useParams();
  const navigate = useNavigate();

  const quillRef = useRef(null);

  const [isToast, setToast] = useState(false);
  const [Toastmsg, setToastmsg] = useState("");

  const [obj, setObj] = useState({
    type: params.type,
    title: "",
    content: "",
    u_seq: user_info.u_seq,
    t_seq: 0,
    q_seq: 0,
  });

  const { title, content } = obj;

  const testId = useRef(null);
  const qId = useRef(null);

  const DrawTest = () => {
    const [options, setOptions] = useState([]);
    const [content, setContent] = useState([]);

    useEffect(() => {
      getSelect();
    }, []);

    // useEffect(() => {

    // },[])
    // useEffect(() => {
    //   if (options.length > 0 && content.length > 0) {
    //     setObj({
    //       ...obj,
    //       t_seq: options[0].t_seq,
    //       q_seq: content[0].q_seq,
    //     });
    //   }
    // }, [options, content]);

    const getSelect = async () => {
      const res = await getData(`/study_qna/${params.tid}/myTest`);
      setOptions(res.data);
      testId.current = res.data[0].t_seq;
      const res2 = await getData(`/study_qna/${params.id}/testContent`);
      //console.log("res : ", res.data);
      setContent(res2.data);
      qId.current = res2.data[0].q_seq;
    };

    const onChange = async (e) => {
      // const { value } = e.target;
      // const res = await getData(`/study_qna/${params.id}/testContent`);
      // console.log("res : ", res.data);
      // setContent(res.data);
    };

    const attach = (e) => {
      const { value } = e.target;
      console.log("e : ", value);
    };
    return (
      <>
        <select className="form-select mb-2" onChange={onChange}>
          {options.map((op) => (
            <option key={op.t_seq} value={op.t_seq}>
              {op.t_name}
            </option>
          ))}
        </select>
        <select className="form-select mb-2" onChange={attach}>
          {/* <option value="">문제 선택</option> */}
          {content.map((op, i) => (
            <option key={i} value={`[${op.q_cate}] ${op.q_title}`}>
              {`[${op.q_cate}] ${op.q_title}`}
            </option>
          ))}
        </select>
      </>
    );
  };

  const onChange = (e) => {
    setObj({
      ...obj,
      [e.target.name]: e.target.value,
    });
  };

  const editorHandler = (e) => {
    quillRef.cerrent = e;
  };

  const insertHanlder = async () => {
    //returnObj(obj)
    if (!title) {
      setToastmsg("제목을 입력해주세요.");
      setToast(true);
      return;
    }
    if (!quillRef.cerrent) {
      setToastmsg("내용을 입력해주세요.");
      setToast(true);
      return;
    }
    const temp = { ...obj };
    temp.content = quillRef.cerrent;
    const sendObj = {
      ...returnObj(temp),
    };
    delete sendObj.type;
    console.log("qId ??? : ", qId);
    if (qId.current) sendObj.q_seq = qId.current;
    if (testId.current) sendObj.t_seq = testId.current;
    //console.log("sendObj : ", sendObj);
    const res = await postData(`${params.type}_qna`, { obj: sendObj });
    if (res.data === "success") {
      setToastmsg("정상적으로 등록되었습니다.");
      setToast(true);
    }
  };

  useEffect(() => {
    if (isToast && title && quillRef.cerrent) {
      setTimeout(() => {
        setToast(false);
        navigate(-1);
      }, [2000]);
    } else {
      setTimeout(() => {
        setToast(false);
      }, [2000]);
    }
  }, [isToast]);

  const toastMsg = () => {
    return (
      <div className="toast-div">
        <div>{Toastmsg}</div>
      </div>
    );
  };
  console.log("params.id  : ", params.id);
  return (
    <>
      <Header centerText="글 작성하기" />
      <Wrapper>
        {Number(params.tid) !== 0 && (
          <div className="">
            <DrawTest />
          </div>
        )}

        <div className="position-relative mb-4" style={{ marginTop: "70px" }}>
          <span className={QnaWriteStyles.titleName}>제목 : </span>
          <input
            className={QnaWriteStyles.title}
            type="text"
            name="title"
            onChange={onChange}
            value={title}
          />
        </div>
        <ReactQuill
          ref={quillRef}
          placeholder="내용을 입력해주세요."
          theme="snow"
          className="bg-white"
          onChange={(content, delta, source, editor) =>
            editorHandler(editor.getHTML())
          }
        />
        <div
          className="btn btn-success w-100 mt-5 py-2"
          onClick={insertHanlder}
        >
          등록하기
        </div>
        <div
          className="btn btn-secondary w-100 mt-2 py-2"
          onClick={() => navigate(-1)}
        >
          뒤로가기
        </div>
      </Wrapper>
      {isToast && (
        <Toast
          text={() => toastMsg()}
          type="info"
          time={2000}
          isToast={isToast}
          setToast={setToast}
        />
      )}
    </>
  );
};
