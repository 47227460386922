import React, { useEffect, useState } from "react";
import { AnimatePresence, motion, LayoutGroup } from "framer-motion";
import sideStyles from "../css/side.module.css";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SearchIcon from "@mui/icons-material/Search";
import { getData } from "../common/common";
import { SimplePaper } from "./SimplePaper";
import { Paper } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import InfiniteScroll from "react-infinite-scroll-component";

export const SideSearch = ({ setAni, ani, setAb }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    getBook();
  }, [page, keyword]);

  const [isEnd, setEnd] = useState(true);

  const getBook = async () => {
    setEnd(true);
    const res = await getData(
      `/book/side?ppp=10&page=${page}&keyword=${keyword}`
    );

    if (res.data.rows.length === 0) {
      setEnd(false);
    }

    if (page === 1 && keyword) {
      setData([...res.data.rows]);
    } else {
      setData([...data, ...res.data.rows]);
    }
  };

  const fetchMore = () => {
    setPage(page + 1);
  };

  const onKeyword = (e) => {
    setPage(1);
    setKeyword(e.target.value);
  };

  const Item = ({ v }) => {
    const styles = {
      paperContainer: {
        backgroundImage: `url(${v.b_thumb})`,
      },
    };
    return (
      <div className={`${sideStyles.grid} position-relative`}>
        <Paper
          className={`${sideStyles.thum}`}
          elevation={3}
          style={styles.paperContainer}
        />
        <div className={`${sideStyles.info}`}>
          <ul>
            <li>{v.b_name}</li>
          </ul>
          <ArrowCircleRightIcon
            className={`${sideStyles.ab} ${sideStyles.right}`}
          />
        </div>
      </div>
    );
  };

  const closeHandler = () => {
    setAni((prev) => !prev);
    setAb((prev) => !prev);
  };

  return (
    <AnimatePresence>
      {ani && (
        <motion.div
          key="sideBox"
          className={sideStyles.wrap}
          initial={{
            position: "absolute",
            x: 500,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          exit={{ x: 500, opacity: 0 }}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              zIndex: "111",
              width: "100%",
              background: "#f4f8f9",
            }}
          >
            <div className={`p-2 ${sideStyles.top}`}>
              <KeyboardArrowLeftIcon onClick={() => closeHandler()} />
            </div>
            <div className="position-relative">
              <SearchIcon className={`${sideStyles.ab} ${sideStyles.left}`} />
              <input
                className={`${sideStyles.topInput} w-100 px-5 p-2`}
                type="text"
                placeholder="교재, 과목 검색"
                onChange={onKeyword}
              />
            </div>
            <div className={`${sideStyles.title} p-2 py-3`}>
              많이 조회하는 교재
            </div>
          </div>
          {/* <div className={sideStyles.itemWrap}>
            {data.map((v, i) => (
              <Item v={v} key={i} />
            ))}
          </div> */}

          <InfiniteScroll
            dataLength={data.length}
            next={fetchMore}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "150px",
              backgroundColor: "#f4f8f9",
              overflow: "hidden",
            }} //To put endMessage and loader to the top.
            inverse={false} //
            hasMore={isEnd}
            loader={<h4 style={{ background: "#f4f8f9" }}>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {data.map((v, i) => (
              <Item v={v} key={i} />
            ))}
          </InfiniteScroll>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
