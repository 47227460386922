import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getData } from "../common/common";
import { Toast } from "../components/Toast";
import TimerStyles from "../css/timer.module.css";
import { DirectModal } from "./DirectModal";

export const Timer_v2 = ({
  bun,
  setStep,
  direct = 0,
  limitUpdate = () => {},
  motype
}) => {
  console.log('motype : ', motype)
  const [min, setMin] = useState(Math.floor(bun / 60));

  const [sec, setSec] = useState(bun % 60);
  const [mil, setMil] = useState(0);
  const time = useRef(min * 60 + sec);
  const time2 = useRef(min === 0 ? 60000 : min * 60000);

  //   console.log("sec : ", sec);
  const timerId = useRef(null);
  const milId = useRef(null);
  const [isSec, setisSec] = useState(false);
  const [isStart, setStart] = useState(false);
  const [isToast, setToast] = useState(false);
  const [toastText, setToastText] = useState("10분 남았습니다.");

  const [isEnd, setEnd] = useState(false);

  //   useEffect(() => {
  //     notify();
  //   }, []);
  function cleartLimit(event) {
    event.preventDefault();

    // 대표적으로 Chrome에서는 returnValue 설정이 필요합니다.
    event.returnValue = "";
    limitUpdate(time.current);
    clearInterval(time.current);
    clearInterval(time2.current);
    clearInterval(milId.current);
  }
  useEffect(() => {
    window.addEventListener("beforeunload", async (event) =>
      cleartLimit(event)
    );

    return () => {
      limitUpdate(time.current);
      clearInterval(time.current);
      clearInterval(time2.current);
      clearInterval(milId.current);

      window.removeEventListener("beforeunload", async (event) =>
        cleartLimit(event)
      );
    };
  }, []);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      if (!isStart) {
        setisSec(true);
      }
      time.current -= 1;
    }, 1000);
    return () => {
      if (!isStart) {
        setStart(true);
      }

      clearTimeout(timerId.current);
    };
  }, []);

  useEffect(() => {
    if (isSec) {
      milId.current = setInterval(() => {
        setMil(time2.current % 60);
        time2.current -= 1;
      }, 16);
      return () => clearTimeout(milId.current);
    }
  }, [isSec]);

  useEffect(() => {
    if (time.current <= 0) {
      if (!isEnd) {
        console.log("시험 시작", time.current);
        clearInterval(time.current);
        clearInterval(time2.current);
        clearInterval(milId.current);
        setEnd(true);
        if(motype == '주간'){
          console.log('주간')
          setStep("start");
        }else{
          console.log('주간아님')
          setStep("normal");
        }
        
      }
    }
  }, [sec]);
  //console.log("timerId.current : ", time.current);

  const directTest = () => {
    clearInterval(time.current);
    clearInterval(time2.current);
    clearInterval(milId.current);
    setEnd(true);
    setStep("start");
  };

  const [u_direct, setDirect] = useState(direct);

  return (
    <>
      <div className={TimerStyles.timer2} style={{ position: "relative" }}>
        <div style={{ fontSize: "24px" }}>
          {min >= 10 ? min : "0" + min} : {sec >= 10 ? sec : "0" + sec} :{" "}
          {mil >= 10 ? mil : "0" + mil}
        </div>
        {u_direct ? (
          <DirectModal u_direct={u_direct} directTest={directTest} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
