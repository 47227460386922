import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";

import { useLocation, useParams } from "react-router-dom";
import { getData, postData } from "../common/common";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";

import { userinfoStore } from "../zustand/userinfoStore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import memoStyles from "../css/momerize.module.css";

import GradeIcon from "@mui/icons-material/Grade";
import { Box } from "@mui/material";
import BottomSlider from "../components/BottomSlider";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const Memorize = () => {
  const { state } = useLocation();
  const { oxType, impValue, shuffle } = state;
  const user_info = userinfoStore((state) => state.user_info);
  const [data, setData] = useState([]);
  const [scrap, setScrap] = useState([]);
  const [page, setPage] = useState(1);
  const [totalcount, setTotalcount] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const [seletedValue, setSeletedValue] = useState(1);

  const seletedValueHandler = (e) => {
    setSeletedValue(e.target.value);
  };

  useEffect(() => {
    if (seletedValue !== 1) {
      triggerRef.current.moveTo(seletedValue - 1);
    }
  }, [seletedValue]);

  useEffect(() => {
    getScrap();
  }, [page, oxType, impValue, shuffle]);

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  const getScrap = async () => {
    const res = await getData(
      `/scrap/0/all?u_seq=${
        user_info.u_seq
      }&page=${page}&ppp=1000&oxType=${oxType}&impValue=${impValue}&shuffle=${shuffle}&isHide=${false}`
    );

    setData([...data, ...res.data.row]);
    setScrap(res.data.scrap);
    setTotalcount(res.data.totalCount);
  };

  const activeIdx = useRef(1);

  const targetRef = useRef([]);
  const active = (idx) => {
    targetRef.current[idx].classList.toggle(memoStyles.on);

    var children = targetRef.current[idx].childNodes;
    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains(memoStyles.content)) {
        children[i].classList.toggle("d-none");
      }

      if (children[i].classList.contains(memoStyles.content2)) {
        children[i].classList.toggle("d-none");
      }
    }
  };

  const [slideIndex, setSlideIndex] = useState(1);
  const triggerRef = useRef([]);

  // const onChange = (e) => {
  //   console.log("슬라이더 넘버링 값 : ", e.target.value);
  //   triggerRef.current.moveTo(e.target.value - 1);
  // };

  useEffect(() => {}, [slideIndex]);

  const imp = (id) => {
    let isCheck = false;
    scrap.forEach(function (v) {
      if (v.id === id) {
        if (v.imp) isCheck = true;
      }
    });

    return isCheck;
  };

  const cookieHandler = async (id) => {
    const temp = scrap.map((v) =>
      v.id === id ? { ...v, imp: v.imp ? false : true } : v
    );

    let obj = {
      u_seq: user_info.u_seq,
      s_group: JSON.stringify(temp),
    };

    const res = await postData(`/scrap`, { obj: obj });

    if (res.data === "success") {
      getScrap();
    }
    //setData(temp.map((v) => (v.q_title === word ? { ...v, imp: true } : v)));
  };

  const Item = () => {
    return (
      <Carousel
        ref={(el) => (triggerRef.current = el)}
        centerMode
        centerSlidePercentage={90}
        showIndicators={false}
        showStatus={false}
        onChange={(idx) => {
          activeIdx.current = idx + 1;
          console.log("카드  : ", activeIdx.current);
          // if (idx + 1 === data.length) {
          //   console.log("마지막 값");
          //   setPage(page + 1);
          // }
        }}
        // onSwipeEnd={() => setSlideIndex(activeIdx.current + 1)}
      >
        {data.length > 0 &&
          data.map((v, index) => (
            <div
              ref={(el) => (targetRef.current[index] = el)}
              key={index}
              className={`${memoStyles.item}`}
              onClick={() => active(index)}
            >
              <div className={`${memoStyles.grade}`}>
                <GradeIcon
                  sx={{
                    fontSize: "24px",
                    marginTop: "-2px",
                    color: imp(v.q_seq) ? "orange" : "darkgray",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    cookieHandler(v.q_seq);
                  }}
                />
              </div>
              <div className={memoStyles.content}>
                <p className={memoStyles.text}>{v.q_title}</p>
              </div>
              <div className={`${memoStyles.content2} d-none`}>
                <p className={memoStyles.text}>[뜻]</p>
                <p className={`${memoStyles.text} mb-2`}>{v.q_mean}</p>
                <p className={memoStyles.text}>[예문]</p>
                <p className={`${memoStyles.text} mb-2`}>{v.q_explain}</p>
                <p className={memoStyles.text}>[해설]</p>
                <p className={memoStyles.text}>{v.q_comment}</p>
              </div>
            </div>
          ))}
      </Carousel>
    );
  };

  return (
    <>
      <Header centerText="암기하기" />
      <Wrapper margin={70} addClass>
        <div style={{ marginBottom: "7px" }}>
          <input
            type="number"
            value={seletedValue}
            className={memoStyles.input}
            onChange={seletedValueHandler}
          />
          <span>번 부터</span>
        </div>

        {isLoading ? <div>loading...</div> : <Item />}

        {/* <BottomSlider
          // onChange={onChange}
          idx={activeIdx.current}
          max={scrap.length}
          totalcount={totalcount}
        /> */}
      </Wrapper>
    </>
  );
};
