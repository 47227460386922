/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { TestMakeDayModal } from "./TestMakeDayModal";

//axios
import { putData, postData, isUseDay } from "../common/common";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

//img
import Loading from "../img/loading.gif";

export const HoedockNext = (props) => {
  const user_info = userinfoStore((state) => state.user_info);

  const { oneData, getList, SetNextModalOpen, SetNextChoiceModalOpen } = props;

  const [ModalDayIsOpen, SetModalDayIsOpen] = useState(false);
  const handleOpen = () => SetModalDayIsOpen(true);
  const [newDate, SetnewDate] = useState("");
  const [type, Settype] = useState("");
  const [day, Setday] = useState(1);
  const [yoil, Setyoil] = useState([]);
  const yoilarr = ["일", "월", "화", "수", "목", "금", "토"];
  const [newdaynum, Setnewdaynum] = useState(1);
  const [newcurri, Setnewcurri] = useState("");

  const [loading, SetLoading] = useState(false);

  useEffect(() => {
    Settype(oneData.t_period ? "일" : "주");
    Setday(oneData.t_period ? oneData.t_period : 1);
    Setyoil(oneData.t_period ? [] : JSON.parse(oneData.t_yoil));
    Setnewcurri(oneData.t_curri);
  }, [oneData]);

  async function qrChange() {
    if (!newDate) {
      alert("시험시작일을 선택해주세요.");
      return false;
    }
    if (!newcurri) {
      alert("커리를 선택해주세요.");
      return false;
    }
    if (window.confirm("커리를변경하여 다음회독을 생성하시겠습니까?")) {
      SetLoading(true);
      let obj = {
        testData: oneData,
        newDate: newDate,
        hoedock: oneData.t_hoedock,
        newdaynum: newdaynum,
        newcurri: newcurri,
        type: type,
        day: day,
        yoil: JSON.stringify(yoil),
      };
      const res = await postData(`hoedock/qrchange/0`, { obj: obj });
      if (res.data === "success") {
        alert("생성 되었습니다!");
        getList();
        SetNextModalOpen(false);
        SetNextChoiceModalOpen(false);
        SetLoading(false);
      }
    }
  }

  async function qrNow() {
    if (!newDate) {
      alert("시험시작일을 선택해주세요.");
      return false;
    }
    if (window.confirm("현재커리로 다음회독을 생성하시겠습니까?")) {
      SetLoading(true);
      let obj = {
        testData: oneData,
        newDate: newDate,
        hoedock: oneData.t_hoedock,
        newdaynum: newdaynum,
        newcurri: newcurri,
        type: type,
        day: day,
        yoil: JSON.stringify(yoil),
      };
      const res = await postData(`hoedock/qrnow/0/v2`, { obj: obj });
      if (res.data === "success") {
        alert("생성 되었습니다!");
        getList();
        SetNextModalOpen(false);
        SetNextChoiceModalOpen(false);
        SetLoading(false);
      }
    }
  }

  async function qrNext() {
    if (newcurri === oneData.t_curri) {
      qrNow();
    } else {
      qrChange();
    }
  }

  return (
    <>
      <table
        className="table table-sm text-center border"
        style={{ fontSize: "12px" }}
      >
        <tbody>
          <tr>
            <th className="border-end">시험시작일</th>
            <td>
              <input
                type="date"
                className="form-control form-control-sm"
                value={newDate}
                onChange={(e) => SetnewDate(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th className="border-end">커리</th>
            <td>
              <select
                name=""
                id=""
                className="form-select form-select-sm"
                value={newcurri}
                onChange={(e) => Setnewcurri(e.target.value)}
              >
                <option value="">선택</option>
                <option value="초급">초급</option>
                <option value="중급">중급</option>
                <option value="고급">고급</option>
              </select>
            </td>
          </tr>
          <tr>
            <th className="border-end">일일응시횟수</th>
            <td>
              <select
                name=""
                id=""
                className="form-select form-select-sm"
                value={newdaynum}
                onChange={(e) => Setnewdaynum(e.target.value)}
              >
                <option value="1">일 1회</option>
                <option value="2">일 2회</option>
                <option value="3">일 3회</option>
              </select>
            </td>
          </tr>
          <tr>
            <th className="border-end">응시주기</th>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                name="t_period"
                value={
                  type === "일"
                    ? `${day}일마다 응시`
                    : `${yoil.map((v) => yoilarr[v])}`
                }
                placeholder="클릭하여 주기를 설정해주세요."
                onClick={handleOpen}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        className="btn btn-sm btn-primary w-100"
        onClick={() => qrNext()}
        disabled={loading}
      >
        다음회독생성
      </button>
      <TestMakeDayModal
        ModalDayIsOpen={ModalDayIsOpen}
        SetModalDayIsOpen={SetModalDayIsOpen}
        type={type}
        day={day}
        yoil={yoil}
        Settype={Settype}
        Setday={Setday}
        Setyoil={Setyoil}
      />
      {loading && <img src={Loading} className="loading"></img>}
    </>
  );
};
