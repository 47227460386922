/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { TestMakeDayModal } from "./TestMakeDayModal";

import { useNavigate, useParams } from "react-router-dom";

//axios
import { putData, postData, isUseDay, getData } from "../common/common";
import axios from "axios";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

//img
import Loading from "../img/loading.gif";

export const HoedockChangeModal = (props) => {
  const user_info = userinfoStore((state) => state.user_info);

  const params = useParams();

  const [type, Settype] = useState("");
  const [day, Setday] = useState(1);
  const [yoil, Setyoil] = useState([]);
  const yoilarr = ["일", "월", "화", "수", "목", "금", "토"];
  const [ModalDayIsOpen, SetModalDayIsOpen] = useState(false);

  const [loading, SetLoading] = useState(false);

  const [from, SetFrom] = useState(1);

  const [bookData, SetBookData] = useState("");

  async function fetchBookData() {
    const res = await getData(`book/${params.id}/bottomcontent`);
    if (res.data) {
      SetBookData(res.data);
    }
  }

  useEffect(() => {
    fetchBookData();
  }, []);

  useEffect(() => {
    SetFrom(1);
    if (props.changeTestList.length !== 0) {
      Settype(props.changeTestList[0].t_period ? "일" : "주");
      Setday(
        props.changeTestList[0].t_period ? props.changeTestList[0].t_period : 1
      );
      Setyoil(
        props.changeTestList[0].t_period
          ? []
          : JSON.parse(props.changeTestList[0].t_yoil)
      );
    }
  }, [props.changeModalVisible]);

  useEffect(() => {
    props.SetChangeTestList(props.changeBeforeTestList);
    if (props.changeTestList.length !== 0) {
      Settype(props.changeBeforeTestList[0].t_period ? "일" : "주");
      Setday(
        props.changeBeforeTestList[0].t_period
          ? props.changeBeforeTestList[0].t_period
          : 1
      );
      Setyoil(
        props.changeBeforeTestList[0].t_period
          ? []
          : JSON.parse(props.changeBeforeTestList[0].t_yoil)
      );
    }
  }, [from]);

  async function changeDayNum(val) {
    SetLoading(true);
    let arr = [...props.changeTestList];
    let obj = {
      test_data: arr,
      t_daynum: val,
      from: from,
    };
    const res = await postData(`hoedock/custom/change`, { obj: obj });
    if (res.data) {
      props.SetChangeTestList(res.data);
      SetLoading(false);
    }
  }

  // function changeHoedockAll(val) {
  //   let arr = [...props.changeTestList]
  //   for (let i = 0; i < arr.length; i++) {
  //     arr[i].t_hoedock_all = val
  //   }
  //   props.SetChangeTestList(arr)
  // }

  async function changeStartDayNum(val) {
    SetLoading(true);
    let arr = [...props.changeTestList];
    let obj = {
      test_data: arr,
      t_start_date: val,
      from: from,
    };
    const res = await postData(`hoedock/custom/change/startdate`, {
      obj: obj,
    });
    if (res.data) {
      props.SetChangeTestList(res.data);
      SetLoading(false);
    }
  }

  async function updatePeriod() {
    SetLoading(true);
    let arr = [...props.changeTestList];
    let obj = {
      test_data: arr,
      type: type,
      day: day,
      yoil: yoil,
      from: from,
    };
    const res = await postData(`hoedock/custom/change/period`, { obj: obj });
    if (res.data) {
      props.SetChangeTestList(res.data);
      SetLoading(false);
    }
  }

  useEffect(() => {
    updatePeriod();
  }, [ModalDayIsOpen]);

  async function testCurriChange() {
    if (window.confirm("커리변경을 신청하시겠습니까?")) {
      SetLoading(true);
      let arr = [...props.changeTestList];
      for (let i = 0; i < arr.length; i++) {
        delete arr[i].t_chapter;
      }
      let obj = {
        a_type: "커리변경",
        u_seq: user_info.u_seq,
        b_seq: props.b_seq,
        a_test: JSON.stringify(arr),
      };
      const res = await postData(`/apply/student`, { obj: obj });
      if (res.data === "success") {
        alert("커리변경이 신청되었습니다");
        props.SetChangeModalVisible(false);
        props.fetchData();
        SetLoading(false);

        const res2 = await getData(
          `/apply/applyCenter/0?c_seq=${user_info.c_seq}`
        );
        if (res2.data) {
          const res3 = await axios.post(
            `https://young-ga.com:3001/kakaowork/kwSend`,
            {
              u_seq: res2.data.u_seq,
              text: `[ 학생 암기관리 신청 ]\n(커리변경요청) ${user_info.u_name} / ${bookData.b_name}`,
            }
          );

          const res5 = await getData(
            `/apply/applyCenterTeacher/0?c_seq=${user_info.c_seq}`
          );
          for (let i = 0; i < res5.data.length; i++) {
            const res6 = await axios.post(
              `https://young-ga.com:3001/kakaowork/kwSend`,
              {
                u_seq: res5.data[i].u_seq,
                text: `[ 학생 암기관리 신청 ]\n(커리변경요청) ${user_info.u_name} / ${bookData.b_name}`,
              }
            );
          }
        }
      }
    }
  }

  const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 1,
    overflowY: "scroll",
    maxHeight: "90%",
    maxWidth: "600px",
  };

  const handleClose = () => props.SetChangeModalVisible(false);

  return (
    <>
      <TestMakeDayModal
        ModalDayIsOpen={ModalDayIsOpen}
        SetModalDayIsOpen={SetModalDayIsOpen}
        type={type}
        day={day}
        yoil={yoil}
        Settype={Settype}
        Setday={Setday}
        Setyoil={Setyoil}
      />
      <Modal
        open={props.changeModalVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box sx={style}>
          <table
            className="table text-center border"
            style={{ fontSize: "12px" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>시험명</th>
                <th>커리</th>
                <th>회독</th>
                <th>응시여부</th>
                <th>응시일자</th>
              </tr>
            </thead>
            <tbody>
              {props.changeTestList &&
                props.changeTestList.length !== 0 &&
                props.changeTestList.map((v, i) => (
                  <tr key={i}>
                    <td style={{ fontSize: "12px" }}>{i + 1}</td>
                    <td style={{ fontSize: "12px" }}>
                      {v.t_name} {v.t_times || ""}
                      {v.t_daynum ? `(일${v.t_daynum}회)` : "-"}
                      {v.t_retest === 1 && "(재)"}
                    </td>
                    <td style={{ fontSize: "12px" }}>{v.t_curri}</td>
                    <td style={{ fontSize: "12px" }}>{v.t_hoedock}</td>
                    <td style={{ fontSize: "12px" }}>
                      {v.tu_end_date ? (
                        <span className="fw-bold text-success">응시</span>
                      ) : (
                        <>
                          {isUseDay(v.t_start_date) ? (
                            <span>응시예정</span>
                          ) : (
                            <span className="fw-bold text-danger">미응시</span>
                          )}
                        </>
                      )}
                    </td>
                    <td
                      style={{ fontSize: "12px" }}
                      className={`${
                        props.changeBeforeTestList[i].t_start_date !==
                          props.changeTestList[i].t_start_date && "text-danger"
                      }`}
                    >
                      {v.t_start_date}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {props.changeTestList && props.changeTestList.length !== 0 && (
            <table
              className="table text-center border"
              style={{ fontSize: "12px" }}
            >
              <tbody>
                <tr>
                  <th>적용 :</th>
                  <td>
                    <select
                      name=""
                      id=""
                      className="form-select form-select-sm"
                      style={{ width: "120px", fontSize: "12px" }}
                      value={from}
                      onChange={(e) => SetFrom(e.target.value)}
                    >
                      {props.changeTestList.map((v, i) => (
                        <option value={i + 1} key={i}>
                          {i + 1}회차부터
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>응시주기 :</th>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="t_period"
                      value={
                        type === "일"
                          ? `${day}일마다 응시`
                          : `${yoil.map((v) => yoilarr[v])}`
                      }
                      placeholder="클릭하여 주기를 설정해주세요."
                      style={{ width: "150px", fontSize: "12px" }}
                      // disabled={Number(from) !== 1 && true}
                      onClick={() => SetModalDayIsOpen(true)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>시험시작일 :</th>
                  <td>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      style={{ width: "200px", fontSize: "12px" }}
                      value={props.changeTestList[from - 1].t_start_date}
                      onChange={(e) => changeStartDayNum(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th>일일응시횟수 :</th>
                  <td>
                    <select
                      name=""
                      id=""
                      className="form-select form-select-sm"
                      value={props.changeTestList[from - 1].t_daynum}
                      style={{ width: "80px", fontSize: "12px" }}
                      onChange={(e) => changeDayNum(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <div
            className="d-flex align-items-center justify-content-end mt-4"
            style={{ fontSize: "12px" }}
          >
            <button
              className="btn btn-success text-white"
              style={{ fontSize: "13px" }}
              onClick={() => testCurriChange()}
              disabled={loading}
            >
              변경신청
            </button>
            <div
              className="btn btn-secondary text-white ms-2"
              style={{ fontSize: "13px" }}
              onClick={handleClose}
            >
              닫기
            </div>
          </div>
        </Box>
      </Modal>
      {loading && <img src={Loading} className="loading"></img>}
    </>
  );
};
