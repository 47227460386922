import create from "zustand";
import { persist } from "zustand/middleware";

export const deviceInfoStore = create(
  persist(
    (set) => ({
      deviceInfo: [],
      setDeviceinfo: (info) => set(() => ({ deviceInfo: info })),
    }),
    {
      name: "deviceInfo", // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
);
