import React, { useEffect, useState } from "react";

import { getData } from "../common/common";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

import QnaStyles from "../css/qna.module.css";
import { QnaBox } from "../components/QnaBox";
import { makeObj } from "../data/qna";
import { useNavigate, useParams } from "react-router-dom";

export const Qna = () => {
  const params = useParams();
  const navigate = useNavigate();

  const user_info = userinfoStore((state) => state.user_info);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState(
    params.type === "study" ? "학습 Q&A" : "시스템 Q&A"
  );
  useEffect(() => {
    drawList();
  }, []);
  const drawList = async () => {
    const res = await getData(
      `/${params.type}_qna/my?u_seq=${user_info.u_seq}`
    );

    if (res.data.length > 0) {
      const resData = res.data.map((v) => makeObj(v));
      setData(resData);
    }
  };

  const goWrite = () => {
    navigate(`/qnaWrite/${params.type}/0/0`);
  };
  return (
    <>
      <Header centerText={title} />
      <Wrapper margin={75}>
        <div
          className="text-center btn btn-success main-color w-100 py-2 mb-4"
          onClick={() => goWrite()}
        >
          글 작성하기
        </div>
        {/* <div className={QnaStyles.infoText}>
          다른 수강생들이 자주 물어보는 질문이 궁금하신가요?{" "}
          <span className="text-success fw-bold pointer">
            자주묻는 질문 보기 >
          </span>
        </div> */}
        <QnaBox qna={data} />
      </Wrapper>
    </>
  );
};
