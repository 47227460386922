import React, { useCallback, useEffect, useState } from "react";
import { currentDate, getData, returnDate, postData } from "../common/common";
import sliderBoxStyles from "../css/sliderBox.module.css";
import { EmptyList } from "./EmptyList";
import { KeywordInput } from "../components/KeywordInput";
import { userinfoStore } from "../zustand/userinfoStore";
import cal from "../img/Todaycal.png";
import search from "../img/search.png";
import { CalendarModal } from "../components/CalendarModal";
import { Toast } from "./Toast";
import { useNavigate, useParams } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Paper } from "@mui/material";
import { CollapseTable } from "./CollapseTable";

import { TestMakeDayModal } from "./TestMakeDayModal";
import { HoedockChangeModal } from "./HoedockChangeModal";
import { HoedockNext } from "./HoedockNext";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import testStyles from "../css/test.module.css";

import axios from "axios";

//img
import Loading from "../img/loading.gif";

export const TotalTestList = ({
  b_seq,
  now_curri,
  index,
  applyState,
  fetchApplyState,
}) => {
  const user_info = userinfoStore((state) => state.user_info);
  const [data, setData] = useState([]);

  const params = useParams();

  const navigate = useNavigate();

  const [text, SetText] = useState("잠시만 기다려주세요.");

  const [loading, SetLoading] = useState(false);

  const [isEnd, SetisEnd] = useState(1);
  const [oneData, SetOneData] = useState([]);
  const [lastTestData, SetLastTestData] = useState([]);

  const [memoModalOpen, SetMemoModalOpen] = useState(false);
  const [memo, SetMemo] = useState("");

  const [changeModalVisible, SetChangeModalVisible] = useState(false);
  const [changeBeforeTestList, SetChangeBeforeTestList] = useState([]);
  const [changeTestList, SetChangeTestList] = useState([]);

  const handleClose = () => SetMemoModalOpen(false);
  const handleMemoOpen = () => SetMemoModalOpen(true);

  const [nextModalOpen, SetNextModalOpen] = useState(false);
  const handleNextClose = () => SetNextModalOpen(false);
  const handleNextOpen = () => SetNextModalOpen(true);

  const [nextChoiceModalOpen, SetNextChoiceModalOpen] = useState(false);
  const handleNextChoiceClose = () => SetNextChoiceModalOpen(false);
  const handleNextChoiceOpen = () => SetNextChoiceModalOpen(true);

  const [nowHoedockModalOpen, SetNowHoedockModalOpen] = useState(false);
  const [nowHoedock, SetNowHoedock] = useState(2);
  const handleNowHoedockClose = () => SetNowHoedockModalOpen(false);
  const handleNowHoedockOpen = (i) => {
    SetNowHoedockModalOpen(true);
    SetNowHoedock(i);
  };

  useEffect(() => {
    getList();
  }, []);

  const [bookData, SetBookData] = useState("");

  async function fetchBookData() {
    const res = await getData(`book/${params.id}/bottomcontent`);
    if (res.data) {
      SetBookData(res.data);
    }
  }

  useEffect(() => {
    fetchBookData();
  }, []);

  const getList = async () => {
    let arr = [];

    const res = await getData(
      `/test_user/${b_seq}/totalList?u_seq=${user_info.u_seq}&curri=${now_curri}`
    );

    const a = groupHandler(res.data.rows, "t_uuid");

    const group = Object.values(a);

    group.forEach(function (v) {
      const b = groupHandler(v, "t_hoedock", "회독");
      arr.push(b);
    });

    let result = [];
    for (let k in arr[0]) {
      let arr3 = arr[0][k].map((v) => v.t_curri);

      let obj = {
        hd: k,
        curri: arr3[0],
        list: arr[0][k],
      };

      result.push(obj);
    }

    let newResult = result.map((v) => v.list);
    SetLastTestData(newResult[newResult.length - 1]);
    let newArr = newResult[newResult.length - 1][0];
    SetOneData(newArr);

    let num = 0;
    // for (let i = 0; i < newResult.length; i++) {
    //   let temp = newResult[i].filter((v) => !v.tu_end_date).length;
    //   num += temp;
    // }
    num = newResult[newResult.length - 1].filter((v) => !v.tu_end_date).length;
    SetisEnd(num);

    setData(result);
    SetText("시험 정보가 존재하지 않습니다.");
  };

  // console.log("data : ", data);

  const groupHandler = (resp, key, str = "") => {
    const groupByName = resp.reduce((acc, obj) => {
      acc[obj[key] + str] = acc[obj[key] + str] ?? [];
      acc[obj[key] + str].push(obj);
      return acc;
    }, {});

    return groupByName;
  };

  async function qrfinal() {
    const response = await getData(
      `/test/finalinfo/0?u_seq=${user_info.u_seq}&b_seq=${oneData.b_seq}`
    );
    if (response.data.t_seq) {
      navigate(`/test/${response.data.t_seq}/link/${user_info.u_seq}`);
      return;
    }
    if (window.confirm("파이널테스트를 진행하시겠습니까?")) {
      let obj = {
        CkStudentList: [oneData.u_seq],
        b_seq: oneData.b_seq,
        my_seq: user_info.u_seq,
      };
      const res = await postData(`testall`, { obj: obj });
      if (res.data.type === "success") {
        navigate(`/test/${res.data.id}/link/${user_info.u_seq}`);
      }
    }
  }

  async function stopApply() {
    if (!memo) {
      alert("사유를 입력해주세요.");
      return;
    }
    if (window.confirm("회독중단을 신청하시겠습니까?")) {
      SetLoading(true);
      let obj = {
        a_type: "회독중단",
        u_seq: user_info.u_seq,
        b_seq: b_seq,
        a_memo: memo,
        t_hoedock_all: oneData.t_hoedock,
        a_test: JSON.stringify(lastTestData),
      };
      const res = await postData(`/apply/student`, { obj: obj });
      if (res.data === "success") {
        alert("회독 중단이 신청되었습니다");
        SetMemoModalOpen(false);
        handleNowHoedockClose(false);
        fetchApplyState();
        SetLoading(false);

        const res2 = await getData(
          `/apply/applyCenter/0?c_seq=${user_info.c_seq}`
        );
        if (res2.data) {
          const res3 = await axios.post(
            `https://young-ga.com:3001/kakaowork/kwSend`,
            {
              u_seq: res2.data.u_seq,
              text: `[ 학생 암기관리 신청 ]\n(회독중단요청) ${user_info.u_name} / ${bookData.b_name}`,
            }
          );

          const res5 = await getData(
            `/apply/applyCenterTeacher/0?c_seq=${user_info.c_seq}`
          );
          for (let i = 0; i < res5.data.length; i++) {
            const res6 = await axios.post(
              `https://young-ga.com:3001/kakaowork/kwSend`,
              {
                u_seq: res5.data[i].u_seq,
                text: `[ 학생 암기관리 신청 ]\n(회독중단요청) ${user_info.u_name} / ${bookData.b_name}`,
              }
            );
          }
        }
      }
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    maxWidth: "600px",
    overflowY: "scroll",
    maxHeight: "90%",
  };

  function hoedockChange() {
    SetChangeBeforeTestList(lastTestData);
    SetChangeTestList([]);
    SetChangeModalVisible(true);
    SetChangeTestList(lastTestData);
  }

  const empltyHoedock = () => {
    const rendering = () => {
      const result = [];
      let num = !isEnd ? 8 : 9;
      for (let i = 0; i < num - data.length; i++) {
        result.push(
          <div class={`${testStyles.CardContainer}`}>
            <div class={`${testStyles.CardBox}`}></div>
          </div>
        );
      }
      return result;
    };

    return rendering();
  };

  return (
    <div className="mt-4 mb-5">
      {data.length === 0 ? (
        <div className="text-center text-white" style={{ marginTop: "0" }}>
          {index === 2 ? (
            <EmptyList text={text} />
          ) : (
            <EmptyList text={`시험 정보가 존재하지 않습니다.`} />
          )}
        </div>
      ) : (
        <div className={`my-4 ${testStyles.Main}`}>
          <div class={`${testStyles.Grid}`}>
            {data.length !== 0 &&
              data.map((row, i) => (
                <div
                  class={`${testStyles.CardContainer}`}
                  onClick={() => handleNowHoedockOpen(i)}
                >
                  <div
                    class={`${testStyles.CardBox} ${
                      data.length === i + 1
                        ? !isEnd
                          ? "text-white bg-secondary"
                          : `${testStyles.cardborder}`
                        : ""
                    }`}
                  >
                    {row.hd}
                  </div>
                </div>
              ))}
            {!isEnd && (
              <div
                class={`${testStyles.CardContainer}`}
                onClick={handleNextChoiceOpen}
              >
                <div class={`${testStyles.CardBox}`}>
                  <AddIcon />
                </div>
              </div>
            )}
            {data.length < !isEnd ? 8 : 9 && empltyHoedock()}
          </div>
        </div>
      )}

      <HoedockChangeModal
        changeModalVisible={changeModalVisible}
        SetChangeModalVisible={SetChangeModalVisible}
        changeBeforeTestList={changeBeforeTestList}
        changeTestList={changeTestList}
        SetChangeTestList={SetChangeTestList}
        fetchData={fetchApplyState}
        b_seq={b_seq}
      />

      <Modal
        open={memoModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div class="text-danger" style={{ fontSize: "12px" }}>
            * 회독중단시 진행하는 마지막 회독 데이터가 전부 삭제됩니다.
          </div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            className="form-control mt-2 p-2"
            placeholder="사유를 입력해주세요."
            style={{ resize: "none", height: "150px" }}
            value={memo}
            onChange={(e) => SetMemo(e.target.value)}
          ></textarea>
          <button
            className="btn btn-sm btn-danger w-100 mt-3"
            onClick={() => stopApply()}
            disabled={loading}
          >
            신청
          </button>
        </Box>
      </Modal>

      <Modal
        open={nextModalOpen}
        onClose={handleNextClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <HoedockNext
            oneData={oneData}
            getList={getList}
            SetNextModalOpen={SetNextModalOpen}
            SetNextChoiceModalOpen={SetNextChoiceModalOpen}
          />
        </Box>
      </Modal>

      <Modal
        open={nextChoiceModalOpen}
        onClose={handleNextChoiceClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {data.length !== 0 && (
            <>
              {!isEnd && (
                <div>
                  <div
                    className="btn btn-sm btn-primary mb-2 w-100"
                    onClick={handleNextOpen}
                  >
                    다음회독생성
                  </div>
                  {Number(applyState.final_cnt) === 0 && (
                    <div
                      className="btn btn-sm btn-success mb-2 w-100"
                      onClick={() => qrfinal()}
                    >
                      파이널테스트
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={nowHoedockModalOpen}
        onClose={handleNowHoedockClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {data.length !== 0 &&
            data.map((row, i) => (
              <CollapseTable
                row={row}
                u_seq={user_info.u_seq}
                isOpen={i === nowHoedock ? true : false}
              />
            ))}
          {data.length !== 0 && (
            <>
              {isEnd && nowHoedock === data.length - 1 ? (
                <>
                  <button
                    className="btn btn-sm btn-warning text-white mb-2 w-100"
                    onClick={hoedockChange}
                    disabled={Number(applyState.change_cnt) > 0 && true}
                  >
                    커리변경 신청
                    {Number(applyState.change_cnt) > 0 && "완료"}
                  </button>
                  <button
                    className="btn btn-sm btn-danger mb-2 w-100"
                    onClick={handleMemoOpen}
                    disabled={Number(applyState.stop_cnt) > 0 && true}
                  >
                    회독중단 신청
                    {Number(applyState.stop_cnt) > 0 && "완료"}
                  </button>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </Box>
      </Modal>
      {loading && <img src={Loading} className="loading"></img>}
    </div>
  );
};
