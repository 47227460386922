/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Form, Container, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import loginBg from "../img/login/login_bg.png";
import { instance, getData } from "../common/common";
import loginStyles from "../css/login.module.css";

export function Signup(props) {
  const type = useParams();
  const [data, setData] = useState({
    u_id: "",
    u_pass: "",
    u_name: "",
    u_phone: "",
    u_email: "",
    c_seq: 0,
  });
  const [idCheck, SetIdCheck] = useState(false);

  const { u_id, u_pass, u_pass2, u_name, u_phone, u_email, c_seq } = data;

  const [centerList, SetCenterList] = useState([]);

  async function fetchCenterData() {
    const res = await getData(`center`);
    SetCenterList(res.data);
  }

  useEffect(() => {
    fetchCenterData();
  }, []);

  const SignupOnChange = async (e) => {
    const { name, value } = e.target;

    if (e.target.name === "u_id") {
      SetIdCheck(false);
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  function DupliClick() {
    if (!u_id) {
      alert("아이디를 입력해주세요.");
      return false;
    } else {
      instance
        .post(`/user/dupli`, {
          u_id: u_id,
        })
        .then((res) => {
          if (res.data.user_cnt > 0) {
            alert("이미 사용중인 아이디입니다.");
            SetIdCheck(false);
          } else {
            alert("사용 가능한 아이디입니다.");
            SetIdCheck(true);
          }
        });
    }
  }

  function SignupClick() {
    if (!u_id) {
      alert("아이디를 입력해주세요.");
      return false;
    } else if (!idCheck) {
      alert("아이디 중복을 확인해주세요.");
      return false;
    } else if (!u_pass) {
      alert("비밀번호를 입력해주세요.");
      return false;
    } else if (u_pass !== u_pass2) {
      alert("비밀번호확인을 정확히 입력해주세요.");
      return false;
    } else if (!u_name) {
      alert("성명을 입력해주세요.");
      return false;
    } else if (!u_phone) {
      alert("휴대폰번호를 입력해주세요.");
      return false;
    } else if (!u_email) {
      alert("이메일을 입력해주세요.");
      return false;
    } else if (!c_seq) {
      alert("지점센터를 선택해주세요.");
      return false;
    } else {
      if (window.confirm("회원가입 하시겠습니까?")) {
        let obj = {
          u_id: u_id,
          u_pass: u_pass,
          u_name: u_name,
          u_phone: u_phone,
          u_email: u_email,
          c_seq: c_seq,
          u_type: type.id === "학생" ? 4 : 3,
        };
        instance
          .post(`/user/signup`, {
            obj: obj,
          })
          .then((res) => {
            alert(`회원가입에 성공하였습니다!`);
            window.location.replace("/login");
          });
      }
    }
  }

  return (
    <div className={loginStyles.wrap}>
      <div className={loginStyles.white_box}>
        <h1>0823 OLS</h1>
        <Form.Group className="mb-3">
          <Form.Label className="mb-3">{type.id} 회원가입</Form.Label>
          <div className={`${loginStyles.idwrap} mb-3`}>
            <Form.Control
              className="me-2 form-control-sm"
              placeholder="아이디"
              name="u_id"
              type="text"
              onChange={(e) => SignupOnChange(e)}
            />
            <Button
              className={`${loginStyles.duplibtn}`}
              variant="secondary"
              onClick={() => DupliClick()}
            >
              중복확인
            </Button>
          </div>
          <Form.Control
            className="mb-2 form-control-sm"
            placeholder="비밀번호"
            type="password"
            name="u_pass"
            onChange={(e) => SignupOnChange(e)}
          />
          <Form.Control
            className="mb-2 form-control-sm"
            placeholder="비밀번호 확인"
            type="password"
            name="u_pass2"
            onChange={(e) => SignupOnChange(e)}
          />
          <Form.Control
            className="mb-2 form-control-sm"
            placeholder="성명"
            name="u_name"
            type="text"
            onChange={(e) => SignupOnChange(e)}
          />
          <Form.Control
            className="mb-2 form-control-sm"
            placeholder="휴대폰 ( - 없이 작성 )"
            name="u_phone"
            type="number"
            onChange={(e) => SignupOnChange(e)}
          />
          <Form.Control
            placeholder="이메일"
            className="mb-2 form-control-sm"
            name="u_email"
            type="email"
            onChange={(e) => SignupOnChange(e)}
          />
          <select
            name="c_seq"
            onChange={(e) => SignupOnChange(e)}
            className="mb-2 form-select form-select-sm"
          >
            <option value="">지점센터 선택</option>
            {centerList.map((centerlist, i) => (
              <option key={i} value={centerlist.c_seq}>
                {centerlist.c_name}
              </option>
            ))}
          </select>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="w-100"
          onClick={() => SignupClick()}
        >
          회원가입
        </Button>
        <hr />
        <div className="my-2 mt-4">
          <Link to="/login">
            <span className={`${loginStyles.fs_14} text-secondary`}>
              로그인 하기
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
