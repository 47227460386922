import React, { createRef, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Container, Button } from "react-bootstrap";
import headerStyles from "../css/header.module.css";
//components
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { BookDetail } from "../components/BookDetail";

//css
import mainStyles from "../css/main.module.css";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";
import { Wrapper } from "../components/Wrapper";
import { currentDate, getData } from "../common/common";
import { SliderBox } from "../components/SliderBox";
import { Net } from "../components/Net";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
  motion,
  m,
} from "framer-motion";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Backdrop from "@mui/material/Backdrop";

import Ready from "../img/ready2.png";

export function Main(props) {
  const user_info = userinfoStore((state) => state.user_info);
  const navigate = useNavigate();

  const [isShow, SetisShow] = useState(false);
  const [b_seq, Setb_seq] = useState();

  const [forceRender, setForce] = useState(false);

  // if (!user_info.u_id) {
  //   window.location.replace("/login");
  // }

  const widthRef = createRef();

  const [ani, setAni] = useState(false);

  const [category, setCategory] = useState("");
  const [isActive, setActive] = useState(false);
  const [isDelay, setDelay] = useState(true);

  const onCategory = (c) => {
    setCategory(c);
    if (c !== "") {
      setActive(true);
      setDelay(false);
    } else {
      setActive(false);
    }
  };

  const ActiveCategory = useCallback(() => {
    return (
      <motion.div
        key="cate"
        animate={{ x: -60 }}
        transition={{
          ease: "easeOut",
          duration: 0.3,
        }}
        exit={{ x: 0 }}
        className={`${headerStyles.cate} ${headerStyles.on}`}
        style={{ paddingLeft: "60px" }}
      >
        {category}
      </motion.div>
    );
  }, [category]);

  const opHandler = () => {
    let str = `${headerStyles.opOn}`;
    if (!isActive && isDelay) {
    } else {
      str = `${headerStyles.op}`;
    }

    return str;
  };

  const [item, setItem] = useState([]);
  const [book, setBook] = useState([]);

  useEffect(() => {
    getBook();
  }, []);

  const getBook = async () => {
    const res = await getData(`/book/0/distinct?field=b_cate`);
    if (res.data.length > 0) {
      setBook(makeItem(res.data));
    }
  };

  const makeItem = (res) => {
    let array = [];
    res.forEach(function (v) {
      if (v.b_cate) {
        array.push(v.b_cate);
      }
    });

    return array;
  };

  useEffect(() => {
    console.log("category : ", category);
    if (category) {
      innerData();
    }
  }, [category]);

  const innerData = async () => {
    console.log("innerData category : ", category);
    let res;

    if (category === "과목") {
      res = await getData(`/book/0/distinct?field=b_cate`);
    } else if (category === "수험분야") {
    }

    if (res) {
      if (res.data.length > 0) {
        setItem(makeItem(res.data));
      }
    } else {
      setItem([]);
    }
  };

  const itemHanlder = (b_seq, b_type) => {
    navigate(`/BookDetailView/${b_seq}/${b_type}`);
    // SetisShow(true);
    // Setb_seq(b_seq);
  };

  const [isAb, setAb] = useState(false);

  return (
    <motion.div
      className="loginPage"
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div ref={widthRef}>
        <Header
          ani={ani}
          setAni={setAni}
          isActive={isActive}
          isDelay={isDelay}
          setCategory={setCategory}
          setActive={setActive}
          setDelay={setDelay}
          isAb={isAb}
          setAb={setAb}
        />
        {/* <div
          className={`${headerStyles.topBottom} p-3 py-4`}
          style={{ top: isAb ? "60px" : "0px" }}
        >
          <AnimatePresence>{isActive && <ActiveCategory />}</AnimatePresence>

          <div className={`${opHandler()} d-flex`}>
            <div
              className={`${headerStyles.cate} pointer ${headerStyles.on}`}
              onClick={() => onCategory("")}
            >
              전체
            </div>
            {user_info.u_seq && (
              <div
                className={`${headerStyles.cate} pointer`}
                onClick={() => onCategory("수험분야")}
              >
                수험분야
              </div>
            )}

            <div
              className={`${headerStyles.cate} pointer`}
              onClick={() => onCategory("과목")}
            >
              과목 <ArrowDropDownIcon />{" "}
            </div>
          </div>
        </div> */}

        {/* <Wrapper margin={isAb ? 75 : 15}> */}
        <Wrapper margin={75}>
          {isActive && !isDelay && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isActive}
              onClick={() => {
                setCategory("");
                setActive(false);
                setTimeout(() => {
                  setDelay(true);
                }, [250]);
              }}
            ></Backdrop>
          )}
          <AnimatePresence>
            {isActive && (
              <motion.div
                layout
                style={{
                  borderRadius: 20,
                  height: "300px",
                  background: "#fff",
                  position: "fixed",
                  zIndex: 111111111,
                  width: "100%",
                  maxWidth: "300px",
                  overflow: "auto",
                }}
                animate={{ left: "50%", y: -40, x: "-50%" }}
                transition={{ ease: "easeOut", duration: 0.3 }}
                exit={{ y: 0 }}
              >
                <ul className="p-0 text-center">
                  {item.length === 0 && (
                    <li className="border-bottom border-secandary py-3">
                      데이터가 존재하지 않습니다.
                    </li>
                  )}
                  {item.map((it, i) => (
                    <li className="border-bottom border-secandary py-3" key={i}>
                      {it}
                    </li>
                  ))}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
          <Net
            icon={<BookmarkAddedIcon />}
            title="이용중인 교재"
            url={`/book/my/0`}
            forceRender={forceRender}
            setForce={setForce}
            itemHanlder={itemHanlder}
            titleShow
          />
          {user_info.u_seq &&
            book.map((v, i) => (
              <Net
                icon={<BookmarkAddedIcon />}
                title="과목별 교재 신규신청"
                url={`/book/type/${v}`}
                type={v}
                forceRender={forceRender}
                setForce={setForce}
                itemHanlder={itemHanlder}
                titleShow={i === 0 ? true : false}
              />
            ))}

          {/* <img src={Ready} className="w-100 mt-2" alt="준비중" /> */}
        </Wrapper>
      </motion.div>
      <BookDetail
        isShow={isShow}
        SetisShow={SetisShow}
        b_seq={b_seq}
        setForce={setForce}
      />
    </motion.div>
  );
}
