import "./App.css";
// eslint-disable-next-line import/imports-first

import "bootstrap/dist/css/bootstrap.min.css";

import { Header } from "./components/Header";

// Routes pages
import { RoutesList } from "./RoutesList";

// components
import { SideMenu } from "./components/SideMenu";

function App() {
  // if (
  //   window.location.href === "https://young-ga.com:81/" ||
  //   window.location.href === "http://localhost:3000/"
  // ) {
  //   window.location.replace("/main");
  // }
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <RoutesList />
  );
}

export default App;
