/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Form, Container, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import loginBg from "../img/login/login_bg.png";
import { instance } from "../common/common";
import loginStyles from "../css/login.module.css";
import axios from "axios";
import { recode } from "../common/common";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";
import { deviceInfoStore } from "../zustand/deviceInfoStore";
import { motion } from "framer-motion";
export function Login(props) {
  const setUserinfo = userinfoStore((state) => state.setUserinfo);
  const setDeviceinfo = deviceInfoStore((state) => state.setDeviceinfo);
  const deviceInfo = deviceInfoStore((state) => state.deviceInfo);

  const [autoLogin, setAutoLogin] = useState(false);

  const navigate = useNavigate();
  const [data, setData] = useState({
    u_id: "",
    u_pass: "",
  });

  const { u_id, u_pass } = data;

  const LoginOnChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  async function getDeviceInfo() {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      if (response.data) {
        let device;
        let mos = "";

        const agent = window.navigator.userAgent.toLowerCase();
        let browserName;
        switch (true) {
          case agent.indexOf("edge") > -1:
            browserName = "MS Edge"; // MS 엣지
            break;
          case agent.indexOf("edg/") > -1:
            browserName = "Edge (chromium based)"; // 크롬 기반 엣지
            break;
          case agent.indexOf("opr") > -1 && !!window.opr:
            browserName = "Opera"; // 오페라
            break;
          case agent.indexOf("chrome") > -1 && !!window.chrome:
            browserName = "Chrome"; // 크롬
            break;
          case agent.indexOf("trident") > -1:
            browserName = "MS IE"; // 익스플로러
            break;
          case agent.indexOf("firefox") > -1:
            browserName = "Mozilla Firefox"; // 파이어 폭스
            break;
          case agent.indexOf("safari") > -1:
            browserName = "Safari"; // 사파리
            break;
          default: // 기타
            browserName = "other";
        }

        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          device = "M";
          let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
          if (varUA.indexOf("android") > -1) {
            //안드로이드
            mos = "android";
          } else if (
            varUA.indexOf("iphone") > -1 ||
            varUA.indexOf("ipad") > -1 ||
            varUA.indexOf("ipod") > -1
          ) {
            //IOS
            mos = "ios";
          } else {
            //아이폰, 안드로이드 외
            mos = "other";
          }
        } else {
          device = "PC";
        }

        let deviceInfo = {
          browserName: browserName,
          device: device,
          mos: mos,
          ip: response.data.ip,
        };

        setDeviceinfo(deviceInfo);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getDeviceInfo();
  }, []);

  // eslint-disable-next-line consistent-return

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      loginClick();
    }
  };
  function loginClick() {
    if (!u_id) {
      alert("아이디를 입력해주세요.");
      return false;
      // eslint-disable-next-line no-else-return
    } else if (!u_pass) {
      alert("비밀번호를 입력해주세요.");
      return false;
    } else {
      // eslint-disable-next-line object-shorthand
      instance
        .post(`/user/login`, { u_id: u_id, u_pass: u_pass })
        .then((res) => {
          if (res.data.result === "not_found") {
            alert(`계정 정보가 존재하지 않습니다.`);
          } else if (res.data.result === "wrong_pw") {
            alert(`비밀번호가 틀렸습니다.`);
          } else if (res.data.result === "status_empty") {
            alert(`미승인 상태입니다. 관리자에게 문의하세요.`);
          } else {
            if (res.data.result.u_id) {
              setUserinfo(res.data.result);
              if (autoLogin) {
                localStorage.setItem(
                  "user_info",
                  JSON.stringify(res.data.result)
                );
              }
              alert(`${res.data.result.u_name}님 안녕하세요!`);
              recode(
                res.data.result.u_seq,
                "로그인",
                deviceInfo.browserName,
                deviceInfo.device,
                deviceInfo.ip,
                deviceInfo.mos
              );

              if (window.sessionStorage.getItem("quizUrl")) {
                window.location.replace(
                  window.sessionStorage.getItem("quizUrl")
                );
              } else {
                window.location.replace("/");
              }
            }
          }
        });
    }
  }

  return (
    <motion.div
      className={loginStyles.wrap}
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ overflowY: "hidden", position: "relative" }}
    >
      <div className={loginStyles.white_box}>
        <h1>0823 OLS</h1>
        <Form.Group>
          <Form.Label className="mb-3">영가에듀</Form.Label>
          <Form.Control
            className="mb-3"
            placeholder="아이디"
            name="u_id"
            type="text"
            onChange={(e) => LoginOnChange(e)}
          />
          <Form.Control
            placeholder="비밀번호"
            type="password"
            name="u_pass"
            onChange={(e) => LoginOnChange(e)}
            onKeyPress={onKeyPress}
          />
        </Form.Group>
        <div className="my-3 d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            id="autologin"
            onClick={() => {
              setAutoLogin((prev) => !prev);
            }}
          />
          <label
            htmlFor="autologin"
            className="mb-0 ms-1"
            style={{ fontSize: "14px" }}
          >
            자동 로그인
          </label>
        </div>
        <Button
          variant="primary"
          type="submit"
          className="w-100"
          onClick={() => loginClick()}
        >
          로그인
        </Button>
        <hr />
        {/* <div>
          <Link to="/signup/강사">
            <span className={`${loginStyles.fs_14} text-primary`}>
              강사 회원가입
            </span>
          </Link>
          <span className="mx-2"> </span>
          <Link to="/signup/학생">
            <span className={`${loginStyles.fs_14} text-primary`}>
              학생 회원가입
            </span>
          </Link>
        </div> */}
        <div className="my-2 mt-4">
          <Link to="/findid/id">
            <span className={`${loginStyles.fs_14} text-secondary`}>
              아이디를 잊으셨나요?
            </span>
          </Link>
        </div>
        <div className="my-2">
          <Link to="/findid/pass">
            <span className={`${loginStyles.fs_14} text-secondary`}>
              비밀번호를 잊으셨나요?
            </span>
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
