import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Container, Button } from 'react-bootstrap';
import headerStyles from '../css/header.module.css';
//components
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { BookDetail } from '../components/BookDetail';

//css
import mainStyles from '../css/main.module.css';
import netStyles from '../css/net.module.css';

//zustand
import { userinfoStore } from '../zustand/userinfoStore';
import { Wrapper } from '../components/Wrapper';
import { currentDate, getData } from '../common/common';
import { SliderBox } from '../components/SliderBox';
import { Net } from '../components/Net';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {
    AnimatePresence,
    domAnimation,
    LazyMotion,
    motion,
    m,
} from 'framer-motion';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Backdrop from '@mui/material/Backdrop';

import Ready from '../img/mo_image.png';

export function Gichul(props) {
    const user_info = userinfoStore(state => state.user_info);
    const navigate = useNavigate();

    const [isShow, SetisShow] = useState(false);
    const [b_seq, Setb_seq] = useState();

    const [forceRender, setForce] = useState(false);

    const [gisuList, SetGisuList] = useState([]);

    const [dailyList, SetDailyList] = useState([]);

    const getGisu = async () => {
        const res = await getData(`/gisu/list/${user_info.u_seq}`);
        if (res.data.length > 0) {
            let gi1 = res.data.filter(
                v =>
                    v.g_sort !== '헌법' &&
                    v.g_sort !== '경찰학' &&
                    v.g_sort !== '형사법' &&
                    v.g_sort !== '국어' &&
                    v.g_sort !== '영어' &&
                    v.g_sort !== '한국사' &&
                    v.g_sort !== '정보보호론',
            );
            let gi2 = res.data.filter(
                v =>
                    v.g_sort == '헌법' ||
                    v.g_sort == '경찰학' ||
                    v.g_sort == '형사법' ||
                    v.g_sort == '국어' ||
                    v.g_sort == '영어' ||
                    v.g_sort == '한국사' ||
                    v.g_sort == '정보보호론',
            );
            SetGisuList(gi1);
            SetDailyList(gi2);
        }
    };

    useEffect(() => {
        getGisu();
    }, []);

    // if (!user_info.u_id) {
    //   window.location.replace("/login");
    // }

    const widthRef = createRef();

    const [ani, setAni] = useState(false);

    const [category, setCategory] = useState('');
    const [isActive, setActive] = useState(false);
    const [isDelay, setDelay] = useState(true);

    const onCategory = c => {
        setCategory(c);
        if (c !== '') {
            setActive(true);
            setDelay(false);
        } else {
            setActive(false);
        }
    };

    const ActiveCategory = useCallback(() => {
        return (
            <motion.div
                key="cate"
                animate={{ x: -60 }}
                transition={{
                    ease: 'easeOut',
                    duration: 0.3,
                }}
                exit={{ x: 0 }}
                className={`${headerStyles.cate} ${headerStyles.on}`}
                style={{ paddingLeft: '60px' }}>
                {category}
            </motion.div>
        );
    }, [category]);

    const opHandler = () => {
        let str = `${headerStyles.opOn}`;
        if (!isActive && isDelay) {
        } else {
            str = `${headerStyles.op}`;
        }

        return str;
    };

    const [item, setItem] = useState([]);
    const [book, setBook] = useState([]);

    useEffect(() => {
        getBook();
    }, []);

    const getBook = async () => {
        const res = await getData(`/book/0/distinct?field=b_cate`);
        if (res.data.length > 0) {
            setBook(makeItem(res.data));
        }
    };

    const makeItem = res => {
        let array = [];
        res.forEach(function (v) {
            if (v.b_cate) {
                array.push(v.b_cate);
            }
        });

        return array;
    };

    useEffect(() => {
        console.log('category : ', category);
        if (category) {
            innerData();
        }
    }, [category]);

    const innerData = async () => {
        console.log('innerData category : ', category);
        let res;

        if (category === '과목') {
            res = await getData(`/book/0/distinct?field=b_cate`);
        } else if (category === '수험분야') {
        }

        if (res) {
            if (res.data.length > 0) {
                setItem(makeItem(res.data));
            }
        } else {
            setItem([]);
        }
    };

    const itemHanlder = (b_seq, b_type) => {
        navigate(`/BookDetailView/${b_seq}/${b_type}`);
        // SetisShow(true);
        // Setb_seq(b_seq);
    };

    const [isAb, setAb] = useState(false);

    console.log('gisuList >>', gisuList);

    return (
        <motion.div
            className="loginPage"
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}>
            <motion.div ref={widthRef}>
                <Header
                    ani={ani}
                    setAni={setAni}
                    isActive={isActive}
                    isDelay={isDelay}
                    setCategory={setCategory}
                    setActive={setActive}
                    setDelay={setDelay}
                    isAb={isAb}
                    setAb={setAb}
                />
                {/* <div
          className={`${headerStyles.topBottom} p-3 py-4`}
          style={{ top: isAb ? "60px" : "0px" }}
        >
          <AnimatePresence>{isActive && <ActiveCategory />}</AnimatePresence>

          <div className={`${opHandler()} d-flex`}>
            <div
              className={`${headerStyles.cate} pointer ${headerStyles.on}`}
              onClick={() => onCategory("")}
            >
              전체
            </div>
            {user_info.u_seq && (
              <div
                className={`${headerStyles.cate} pointer`}
                onClick={() => onCategory("수험분야")}
              >
                수험분야
              </div>
            )}

            <div
              className={`${headerStyles.cate} pointer`}
              onClick={() => onCategory("과목")}
            >
              과목 <ArrowDropDownIcon />{" "}
            </div>
          </div>
        </div> */}

                {/* <Wrapper margin={isAb ? 75 : 15}> */}
                <Wrapper margin={75}>
                    {isActive && !isDelay && (
                        <Backdrop
                            sx={{
                                color: '#fff',
                                zIndex: theme => theme.zIndex.drawer + 1,
                            }}
                            open={isActive}
                            onClick={() => {
                                setCategory('');
                                setActive(false);
                                setTimeout(() => {
                                    setDelay(true);
                                }, [250]);
                            }}></Backdrop>
                    )}
                    <AnimatePresence>
                        {isActive && (
                            <motion.div
                                layout
                                style={{
                                    borderRadius: 20,
                                    height: '300px',
                                    background: '#fff',
                                    position: 'fixed',
                                    zIndex: 111111111,
                                    width: '100%',
                                    maxWidth: '300px',
                                    overflow: 'auto',
                                }}
                                animate={{ left: '50%', y: -40, x: '-50%' }}
                                transition={{ ease: 'easeOut', duration: 0.3 }}
                                exit={{ y: 0 }}>
                                <ul className="p-0 text-center">
                                    {item.length === 0 && (
                                        <li className="border-bottom border-secandary py-3">
                                            데이터가 존재하지 않습니다.
                                        </li>
                                    )}
                                    {item.map((it, i) => (
                                        <li
                                            className="border-bottom border-secandary py-3"
                                            key={i}>
                                            {it}
                                        </li>
                                    ))}
                                </ul>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {/* <Net
            icon={<BookmarkAddedIcon />}
            title="이용중인 기출문제집"
            url={`/book/my/0/기출`}
            forceRender={forceRender}
            setForce={setForce}
            itemHanlder={itemHanlder}
            titleShow
          /> */}
                    {/* {book.map((v, i) => (
            <Net
              icon={<BookmarkAddedIcon />}
              title="과목별 교재"
              url={`/book/type/${v}`}
              type={v}
              forceRender={forceRender}
              setForce={setForce}
              itemHanlder={itemHanlder}
              titleShow={i === 0 ? true : false}
            />
          ))} */}
                    <h6 className={netStyles.h6}>스파르타 모의고사</h6>
                    {gisuList.length > 0 ? (
                        <table
                            className="table border text-center bg-white"
                            style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th>등록일</th>
                                    <th>분야</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {gisuList.map((v, i) => (
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            {v.g_seq === 46
                                                ? v.g_gisu_origin
                                                : v.g_gisu}
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            {v.g_sort}
                                        </td>
                                        <td>
                                            <Link
                                                to={`/MoDetailView/${
                                                    v.g_seq === 46
                                                        ? v.g_seq_origin
                                                        : v.g_seq
                                                }`}>
                                                <span
                                                    className="btn btn-sm btn-dark"
                                                    style={{
                                                        fontSize: '12px',
                                                    }}>
                                                    입장
                                                </span>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center fw-bold py-3 text-white">
                            배정된 시험이 없습니다.
                        </div>
                    )}

                    <h6 className={netStyles.h6}>데일리테스트</h6>
                    {dailyList.length > 0 ? (
                        <table
                            className="table border text-center bg-white"
                            style={{ fontSize: '13px' }}>
                            <thead>
                                <tr>
                                    <th>등록일</th>
                                    <th>과목명</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dailyList.map((v, i) => (
                                    <tr>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            {v.g_seq === 46
                                                ? v.g_gisu_origin
                                                : v.g_gisu}
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            {v.g_sort}
                                        </td>
                                        <td>
                                            <Link
                                                to={`/MoDetailView/${
                                                    v.g_seq === 46
                                                        ? v.g_seq_origin
                                                        : v.g_seq
                                                }`}>
                                                <span
                                                    className="btn btn-sm btn-dark"
                                                    style={{
                                                        fontSize: '12px',
                                                    }}>
                                                    입장
                                                </span>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center fw-bold py-3 text-white">
                            배정된 시험이 없습니다.
                        </div>
                    )}
                    <img src={Ready} className="w-100 mt-2" alt="준비중" />
                </Wrapper>
            </motion.div>
            <BookDetail
                isShow={isShow}
                SetisShow={SetisShow}
                b_seq={b_seq}
                setForce={setForce}
            />
        </motion.div>
    );
}
