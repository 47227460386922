import React from "react";
import { Container } from "react-bootstrap";
import WrapperStyles from "../css/wrapper.module.css";

export function Wrapper({ children, margin = 15, addClass = null }) {
  return (
    <Container
      className={`${WrapperStyles.resize} ${
        addClass ? WrapperStyles.refit : addClass
      }`}
      style={{
        paddingTop: margin + "px",
        paddingBottom: margin + "px",
        overflow: "hidden",
        position: "relative",
        maxWwidth: "768px",
      }}
    >
      {children}
    </Container>
  );
}
