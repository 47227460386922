import React, { useEffect, useRef, useState } from "react";
import Map from "../components/Map";
import { Voice } from "../components/Voice";
import VoiceChat from "../components/VoiceChat";
import Zoom from "../components/Zoom";
import { jsPDF } from "jspdf";
export const Work = () => {
  const [isVisible, setVisible] = useState({
    voice: false,
    map: false,
    chat: true,
    zoom: false,
  });

  const { voice, map, chat, zoom } = isVisible;

  const visibleHandler = (type) => {
    setVisible({
      ...isVisible,
      [type]: !isVisible[type],
    });
  };

  const pdfRef = useRef(null);

  const handleDownload = () => {
    // const content = pdfRef.current;
    // const doc = new jsPDF();
    // doc.addFileToVFS("ChosunSm.TTF", ChosunSm);
    // doc.addFont("ChosunSm.TTF", "ChosunSm", "normal");
    // doc.setFont("ChosunSm");
    // doc.html(content, {
    //   callback: function (doc) {
    //     doc.save("sample.pdf");
    //   },
    // });
  };

  return (
    <div className="p-3">
      <span
        className="btn btn-primary me-2 mb-2"
        onClick={() => visibleHandler("voice")}
      >
        음성녹음 테스트
      </span>
      <span
        className="btn btn-primary me-2 mb-2"
        onClick={() => visibleHandler("map")}
      >
        카카오맵 테스트
      </span>
      <span className="btn btn-primary " onClick={() => visibleHandler("chat")}>
        음성채팅 테스트
      </span>
      <span className="btn btn-primary " onClick={() => visibleHandler("zoom")}>
        ZOOM 테스트
      </span>
      {voice && <Voice />}
      {/* {map && <Map />} */}
      {chat && <VoiceChat />}
      {/* {zoom && <Zoom />} */}
      <div ref={pdfRef}>한글 ㅠ</div>
      <button onClick={handleDownload}>Download</button>
    </div>
  );
};
