import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export const Toast = ({ text = () => {}, type = "info", time }) => {
  const notify = () =>
    toast[type](text, {
      position: "top-center",
    });

  useEffect(() => {
    notify();
  }, []);

  return (
    <ToastContainer position="top-center" autoClose={time} hideProgressBar />
  );
};
