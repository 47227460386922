/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import homeStyles from "../css/home.module.css";
// import mainImage from "../img/main-bg.jpg";
//import mainImage from "../img/main-bg-m.jpg";
import mainImage from "../img/main-bg2.jpg";
import mainMap from "../img/main-map.png";
import marker from "../img/marker.png";
import logo from "../img/symbol-white.png";
import { Header } from "../components/Header";

import { css, keyframes } from "@emotion/react";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import useWindowDimensions from "../hook/useWindowDimensions ";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
export const Home = () => {
  const { height, width } = useWindowDimensions();

  const homeRef = useRef(null);

  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const imageRef = useRef();
  const [imageObj, setImageObj] = useState({
    width: "100%",
  });

  const [centerObj, setCenterObj] = useState({
    height: "550px",
  });

  const [left, setLeft] = useState(0);

  useEffect(() => {
    if (width >= 540) {
      setImageObj({
        height: width + 200 + "px",
      });
    } else {
      setImageObj({
        height: height + 50 + "px",
      });
    }

    if (width < 1280) {
      setLeft(width);
    }
  }, [width]);

  useEffect(() => {
    if (left) {
      setTimeout(() => {
        onHomeClick();
      }, 100);
    }
  }, [left]);

  const navigate = useNavigate();

  function homeText() {
    return (
      <span className={`${homeStyles.font}`}>영가스파르타 제1캠퍼스...</span>
    );
  }

  return (
    <motion.div
      className="loginPage"
      intial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ overflowY: "hidden", position: "relative" }}
    >
      <Header leftText={homeText()} />
      {/* <div className={homeStyles.wrapper}>메인화면</div> */}
      <div
        className={homeStyles.center}
        style={{ left: width + 300 + "px" }}
        ref={homeRef}
      ></div>
      <img src={mainImage} alt="메인" style={imageObj} ref={imageRef} />
      {/* <div className={`${homeStyles.title} ${homeStyles.font}`}>
        영가스파르타 제1캠퍼스...
      </div> */}
      <div css={mapStyle} style={centerObj}>
        <img src={mainMap} alt="메인" css={mapStyle} />
        <Link to="/main">
          <div
            className={`${homeStyles.arrow_box} ${homeStyles.float}`}
            css={boxStyle}
            // onClick={() => navigate("/main")}
          >
            <div className={`${homeStyles.font} text-white`} css={textshadow}>
              암기 센터
            </div>
            <img src={marker} alt="marker" style={{ width: "25px" }} />
          </div>
        </Link>
        <div css={circle}></div>
        <div
          className={`${homeStyles.arrow_box2} ${homeStyles.float}`}
          css={boxStyle2}
          onClick={() => navigate("/gichul")}
        >
          <div className={`${homeStyles.font} text-white`} css={textshadow}>
            테스트 센터
          </div>
          <img src={marker} alt="marker" style={{ width: "25px" }} />
        </div>
        <div css={circle2}></div>
      </div>
    </motion.div>
  );
};

const floating = keyframes`
    0 {
        transform: translateY(0);    
    }
    50% {
        transform: translateY(-7px);
    }
    100% {
        transform: translateY(0);
    }
`;

const bigger = keyframes`
    0 {
        transform: scale(0.3);
        opacity:0.3;
    }
    100% {
        transform: scale(13);
        opacity:0; 
    }
`;

const mapStyle = css`
  width: 650px;
  left: 15%;
  top: 7%;
  position: absolute;
`;

const boxStyle = css`
  left: 39%;
  top: 11%;
  position: absolute;
  animation: ${floating} 2s ease infinite;
  z-index: 11;
`;

const boxStyle2 = css`
  animation: ${floating} 2s ease infinite;
  right: -4%;
  top: 27%;
  position: absolute;
  z-index: 11;
`;

const circle = css`
  width: 10px;
  height: 10px;
  animation: ${bigger} 1.3s ease infinite;
  left: 93%;
  top: 82%;
  position: absolute;
  border-radius: 50%;
  margin-right: -50%;
  margin-left: -50%;
  margin-top: -50%;
  background: white;
  opacity: 0.3;
  z-index: 10;
`;

const circle2 = css`
  width: 10px;
  height: 10px;
  animation: ${bigger} 1.3s ease infinite;
  right: 52%;
  top: 98%;
  position: absolute;
  border-radius: 50%;
  margin-right: -50%;
  margin-left: -50%;
  margin-top: -50%;
  background: white;
  opacity: 0.3;
  z-index: 10;
`;

const textshadow = css`
  text-shadow: 0px 0px 20px black;
`;
