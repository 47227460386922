export function makeObj(v) {
  let obj = new Object();
  for (let k in v) {
    if (k === "sq_title") {
      obj.type = "study";
    } else if (k === "sy_title") {
      obj.type = "system";
    }
    if (k === "sq_title" || k === "sy_title") {
      obj.title = v[k];
    } else if (k === "sq_content" || k === "sy_content") {
      obj.content = v[k];
    } else if (k === "sq_answer" || k === "sy_answer") {
      obj.answer = v[k];
    } else if (k === "sq_date" || k === "sy_date") {
      obj.date = v[k];
    } else if (k === "sq_seq" || k === "sy_seq") {
      obj.seq = v[k];
    } else {
      obj[k] = v[k];
    }
  }

  console.log("obj : ", obj);

  return obj;
}

export function returnObj(v) {
  let obj = new Object();
  if (v.type === "study") {
    for (let k in v) {
      if (k === "title") {
        obj.sq_title = v[k];
      } else if (k === "content") {
        obj.sq_content = v[k];
      } else if (k === "answer") {
        obj.sq_answer = v[k];
      } else if (k === "date") {
        obj.sq_date = v[k];
      } else {
        obj[k] = v[k];
      }
    }
  } else if (v.type === "system") {
    for (let k in v) {
      if (k === "title") {
        obj.sy_title = v[k];
      } else if (k === "content") {
        obj.sy_content = v[k];
      } else if (k === "answer") {
        obj.sy_answer = v[k];
      } else if (k === "date") {
        obj.sy_date = v[k];
      } else {
        obj[k] = v[k];
      }
    }
  }

  return obj;
}
