import React, { useEffect, useState } from "react";
import { getData } from "../common/common";
import { AccordionComponent } from "../components/Accordion";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";

export const Faq = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await getData("/faq/0");
    setData(res.data);
  };

  console.log("data : ", data);
  return (
    <>
      <Header centerText={"FAQ"} />
      <Wrapper>
        {data.length > 0 &&
          data.map((v, i) => <AccordionComponent v={v} key={i} />)}
      </Wrapper>
    </>
  );
};
