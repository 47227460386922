import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const TestMakeDayModal = (props) => {
  const yoilarr = ["일", "월", "화", "수", "목", "금", "토"];
  function yoilClick(target) {
    let arr = [...props.yoil];
    if (!arr.includes(Number(target))) {
      arr.push(Number(target));
    } else {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === Number(target)) {
          arr.splice(i, 1);
          i--;
        }
      }
    }
    arr.sort();
    props.Setyoil(arr);
  }

  const handleClose = () => props.SetModalDayIsOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={props.ModalDayIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <select
            name="per_type"
            id=""
            className="form-select form-select-sm"
            style={{ width: "100px" }}
            value={props.type}
            onChange={(e) => props.Settype(e.target.value)}
          >
            <option value="일">일</option>
            <option value="주">요일</option>
          </select>
        </div>
        {props.type === "일" ? (
          <div className="mt-3">
            <input
              type="number"
              min={1}
              className="form-control form-control-sm d-inline-block"
              placeholder="몇일 마다"
              style={{ width: "100px" }}
              value={props.day}
              onChange={(e) => props.Setday(e.target.value)}
            />{" "}
            일 마다
          </div>
        ) : (
          <div className="mt-3 d-flex">
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(0) && "active"
              }`}
              data-target="0"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              일
            </span>
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(1) && "active"
              }`}
              data-target="1"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              월
            </span>
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(2) && "active"
              }`}
              data-target="2"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              화
            </span>
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(3) && "active"
              }`}
              data-target="3"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              수
            </span>
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(4) && "active"
              }`}
              data-target="4"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              목
            </span>
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(5) && "active"
              }`}
              data-target="5"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              금
            </span>
            <span
              className={`btn btn-sm btn-outline-primary me-2 ${
                props.yoil.includes(6) && "active"
              }`}
              data-target="6"
              onClick={(e) => yoilClick(e.target.dataset.target)}
            >
              토
            </span>
          </div>
        )}

        <div className="d-flex pt-3 mt-3 border-top">
          {props.yoil.map((v, i) => yoilarr[v]).join(", ")}
        </div>

        <div className="d-flex pt-3 mt-3 border-top">
          <span className="btn btn-sm btn-dark ms-auto" onClick={handleClose}>
            확인
          </span>
        </div>
      </Box>
    </Modal>
  );
};
