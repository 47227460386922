import React from "react";
import { Accordion } from "react-bootstrap";

export const AccordionComponent = ({ v }) => {
  return (
    <Accordion defaultActiveKey={v.f_seq}>
      <Accordion.Item eventKey={v.f_seq}>
        <Accordion.Header>{v.f_title}</Accordion.Header>
        <Accordion.Body>{v.f_content}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
