import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import { getData } from "../common/common";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { QuestionLg, InfoLg, CheckLg } from "react-bootstrap-icons";
//zustand
import { userinfoStore } from "../zustand/userinfoStore";

import QnaDetailStyles from "../css/qnadetail.module.css";
import { useParams } from "react-router-dom";
import { makeObj } from "../data/qna";

export const QnaDetail = () => {
  const params = useParams();
  const [data, setData] = useState({
    seq: "",
    answer: "",
    date: "",
    title: "",
    type: "",
    u_name: "",
    sub_name: "",
    an_date: "",
    t_name: "",
    q_cate: "",
    u_seq: 0,
    sub_seq: 0,
  });
  //console.log("params : ", params);

  useEffect(() => {
    listDraw();
  }, []);

  const listDraw = async () => {
    const res = await getData(`/${params.type}_qna/${params.id}/v2`);
    const temp = res.data.map((v) => makeObj(v))[0];
    if (res.data.length > 0) {
      if (res.data[0].t_seq > 0) {
        const res2 = await getData(`/study_qna/${res.data[0].t_seq}/myTest`);
        const res3 = await getData(
          `/study_qna/${res.data[0].q_seq}/testContent`
        );

        setData({
          ...temp,
          t_name: res2.data[0].t_name,
          q_cate: res3.data[0].q_cate,
        });
      } else {
        setData(temp);
      }
    }
  };

  console.log("data : ", data);
  return (
    <>
      <Header />
      <Wrapper>
        <div style={{ marginTop: "70px" }} className="bg-white p-3">
          <div>
            {data.t_name && (
              <p>
                <span className="text-secondary me-2">[{data.t_name}]</span>
                <span className="text-success">{data.q_cate}</span>
              </p>
            )}
          </div>
          <QuestionLg className={QnaDetailStyles.icon} />
          <p className={QnaDetailStyles.titleTexxt}>{data.title}</p>
          <div style={{ clear: "both" }}></div>
          <div className={QnaDetailStyles.nameWrap}>
            <span>{data.u_name}&nbsp;&nbsp;</span>
            {data.date && (
              <span className={QnaDetailStyles.date}>
                ·&nbsp;{data.date.split(" ")[0]}
              </span>
            )}
          </div>
          <hr />
          <div className={QnaDetailStyles.contentText}>
            {data.content && parse(data.content)}
          </div>
          <hr />
          <div>
            {data.sub_seq === 0 ? (
              <div className={`text-center ${QnaDetailStyles.aWrap}`}>
                <InfoLg className={QnaDetailStyles.aIcon} />
                <span>답변이 아직 등록되지 않았습니다.</span>
              </div>
            ) : (
              <>
                <div className={`${QnaDetailStyles.acWrap}`}>
                  <CheckLg
                    className={`${QnaDetailStyles.aIconA} text-success`}
                  />
                  <span>&nbsp;&nbsp;{data.sub_name}&nbsp;&nbsp;</span>
                  {data.an_date && (
                    <span className={QnaDetailStyles.date}>
                      ·&nbsp;{data.an_date.split(" ")[0]}
                    </span>
                  )}
                </div>

                <div></div>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};
