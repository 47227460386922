import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { Container, Row } from "react-bootstrap";
import { Wrapper } from "../components/Wrapper";
import { TopFab } from "../components/TopFab";

import ListStyles from "../css/list.module.css";
import {
  currentDate,
  getYoil,
  getData,
  returnDate,
  getDate,
} from "../common/common";

import defaultImage from "../img/young_defalut_thumb.png";
import { EmptyList } from "../components/EmptyList";
import { CalendarModal } from "../components/CalendarModal";
import { useNavigate } from "react-router-dom";
import { Toast } from "../components/Toast";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";
import { Paging } from "../components/Paging";
import { SliderBox } from "../components/SliderBox";

export const All = () => {
  const user_info = userinfoStore((state) => state.user_info);

  const [sid, setId] = useState(0);
  const TopCategory = () => {
    let category;
    category = ["기출관리", "회독관리", "성과관리"];

    const onClick = (name) => {
      setId(name);
    };

    return (
      <div className={ListStyles.topCategory}>
        {category.map((cate, i) => (
          <span
            className={i === sid ? ListStyles.active : null}
            key={i}
            onClick={() => onClick(i)}
          >
            {cate}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Header centerText={"전범위시험"} />
      <div style={{ margin: "0 auto", maxWidth: "768px" }}>
        <TopFab quiz all />
      </div>
      {/* <TopCategory /> */}
      {/* <Wrapper margin={0}>
        <SliderBox sid={sid} main={false} />
      </Wrapper> */}
    </div>
  );
};
