import React, { useEffect, useState } from "react";
import { Form, Container } from "react-bootstrap";

//components
import { Header } from "../components/Header";
import { TestMakeDayModal } from "../components/TestMakeDayModal";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

//axios
import { instance, postData, getData, putData } from "../common/common";
import axios from "axios";

//img
import Loading from "../img/loading.gif";

import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";

export const BookApply = () => {
  const user_info = userinfoStore((state) => state.user_info);
  const setUserinfo = userinfoStore((state) => state.setUserinfo);
  const params = useParams();
  const navigate = useNavigate();

  const [oneData, SetOneData] = useState([]);

  const [curri, SetCurri] = useState("초급");
  const [startdate, Setstartdate] = useState("");
  const [hoedockall, Sethoedockall] = useState(1);
  const [daynum, Setdaynum] = useState(1);

  const [type, Settype] = useState("주");
  const [day, Setday] = useState(1);
  const [yoil, Setyoil] = useState([1, 2, 3, 4, 5, 6]);
  const yoilarr = ["일", "월", "화", "수", "목", "금", "토"];
  const [ModalDayIsOpen, SetModalDayIsOpen] = useState(false);

  const [memo, SetMemo] = useState("");

  const [bookData, SetBookData] = useState("");

  const [applyState, SetApplyState] = useState([]);

  const [loading, SetLoading] = useState(false);

  async function fetchApplyState() {
    const res = await getData(
      `/apply/applystate/0?u_seq=${user_info.u_seq}&b_seq=${params.id}`
    );
    SetApplyState(res.data);
  }

  async function fetchBookData() {
    const res = await getData(`book/${params.id}/bottomcontent`);
    if (res.data) {
      SetBookData(res.data);
    }
  }

  const getList = async () => {
    const res = await getData(
      `/test_user/${params.id}/totalList?u_seq=${user_info.u_seq}`
    );

    let newArr = res.data.rows[res.data.rows.length - 1];
    SetOneData(newArr);
  };

  // console.log("oneData >>", oneData);

  useEffect(() => {
    fetchBookData();
    fetchApplyState();
    getList();
  }, []);

  async function qrChange() {
    if (window.confirm("커리를변경하여 다음회독을 생성하시겠습니까?")) {
      SetLoading(true);
      let obj = {
        testData: oneData,
        newDate: startdate,
        hoedock: oneData.t_hoedock,
        newdaynum: daynum,
        newcurri: curri,
        type: type,
        day: day,
        yoil: JSON.stringify(yoil),
      };
      const res = await postData(`hoedock/qrchange/0`, { obj: obj });
      if (res.data === "success") {
        let obj2 = {
          t_uuid: oneData.t_uuid,
          u_seq: oneData.u_seq,
          b_seq: oneData.b_seq,
        };
        const res2 = await putData(`hoedock/qrrestore/0`, { obj: obj2 });
        if (res2.data === "success") {
          alert("생성 되었습니다!");
          SetLoading(false);
          navigate(-1);
        }
      }
    }
  }

  async function qrNow() {
    if (window.confirm("현재커리로 다음회독을 생성하시겠습니까?")) {
      SetLoading(true);
      let obj = {
        testData: oneData,
        newDate: startdate,
        hoedock: oneData.t_hoedock,
        newdaynum: daynum,
        newcurri: curri,
        type: type,
        day: day,
        yoil: JSON.stringify(yoil),
      };
      const res = await putData(`hoedock/qrnow/0/v2`, { obj: obj });
      if (res.data === "success") {
        let obj2 = {
          t_uuid: oneData.t_uuid,
          u_seq: oneData.u_seq,
          b_seq: oneData.b_seq,
        };
        const res2 = await putData(`hoedock/qrrestore/0`, { obj: obj2 });
        if (res2.data === "success") {
          alert("생성 되었습니다!");
          SetLoading(false);
          navigate(-1);
        }
      }
    }
  }

  async function applyClick() {
    if (!curri) {
      alert("커리를 선택해주세요.");
      return;
    }
    if (!daynum) {
      alert("일일응시횟수 선택해주세요.");
      return;
    }
    if (!hoedockall) {
      alert("총회독을 선택해주세요.");
      return;
    }
    if (!startdate) {
      alert("시험시작일을 선택해주세요.");
      return;
    }

    if (applyState.end_cnt > 0) {
      if (curri === oneData.t_curri) {
        qrNow();
      } else {
        qrChange();
      }
    } else {
      if (window.confirm("신청 하시겠습니까?")) {
        SetLoading(true);
        let obj = {
          a_type: "교재신규",
          u_seq: user_info.u_seq,
          b_seq: params.id,
          t_curri: curri,
          t_start_date: startdate,
          t_daynum: daynum,
          t_period: type === "주" ? JSON.stringify(yoil) : day,
          a_memo: memo,
        };
        const res = await postData(`apply/student`, { obj: obj });
        if (res.data === "success") {
          const res2 = await getData(
            `/apply/applyCenter/0?c_seq=${user_info.c_seq}`
          );
          if (res2.data) {
            const res3 = await axios.post(
              `https://young-ga.com:3001/kakaowork/kwSend`,
              {
                u_seq: res2.data.u_seq,
                text: `[ 학생 암기관리 신청 ]\n(신규신청) ${user_info.u_name} / ${bookData.b_name} / ${startdate}`,
              }
            );
          }

          const res5 = await getData(
            `/apply/applyCenterTeacher/0?c_seq=${user_info.c_seq}`
          );
          for (let i = 0; i < res5.data.length; i++) {
            const res6 = await axios.post(
              `https://young-ga.com:3001/kakaowork/kwSend`,
              {
                u_seq: res5.data[i].u_seq,
                text: `[ 학생 암기관리 신청 ]\n(신규신청) ${user_info.u_name} / ${bookData.b_name} / ${startdate}`,
              }
            );
          }

          alert("신청 되었습니다!");
          SetLoading(false);
          navigate(-1);
        }
      }
    }
  }

  return (
    <>
      <Header />
      <Container
        style={{
          paddingTop: "80px",
          paddingBottom: "100px",
          maxWidth: "468px",
        }}
        className="mx-auto"
      >
        <h5>{applyState.end_cnt > 0 ? "교재 다시 시작" : "교재 신규 신청"}</h5>
        <hr />
        <table
          className="table border text-center bg-white"
          style={{ fontSize: "12px" }}
        >
          <tbody>
            <tr>
              <th>커리</th>
              <td>
                <select
                  name=""
                  id=""
                  className="form-select"
                  value={curri}
                  onChange={(e) => SetCurri(e.target.value)}
                  style={{ fontSize: "12px" }}
                >
                  <option value="">선택</option>
                  <option value="초급">초급</option>
                  <option value="중급">중급</option>
                  <option value="고급">고급</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                암기횟수 <br />{" "}
                <span style={{ fontSize: "10px" }}>(1회 1시간 분량)</span>
              </th>
              <td>
                <select
                  name=""
                  id=""
                  className="form-select"
                  value={daynum}
                  onChange={(e) => Setdaynum(e.target.value)}
                  style={{ fontSize: "12px" }}
                >
                  <option value="1">일 1회</option>
                  <option value="2">일 2회</option>
                  <option value="3">일 3회</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>응시주기</th>
              <td>
                <input
                  type="text"
                  className="form-control"
                  name="t_period"
                  value={
                    type === "일"
                      ? `${day}일마다 응시`
                      : `${yoil.map((v) => yoilarr[v])}`
                  }
                  placeholder="클릭하여 주기를 설정해주세요."
                  onClick={() => SetModalDayIsOpen(true)}
                  style={{ fontSize: "12px" }}
                />
              </td>
            </tr>
            {/* <tr>
              <th>총회독수</th>
              <td>
                <select
                  name=""
                  id=""
                  className="form-select"
                  value={hoedockall}
                  onChange={(e) => Sethoedockall(e.target.value)}
                  style={{ fontSize: "12px" }}
                >
                  <option value="1">총 1회독</option>
                  <option value="2">총 2회독</option>
                  <option value="3">총 3회독</option>
                </select>
              </td>
            </tr> */}
            <tr>
              <th>시험시작일</th>
              <td>
                <input
                  type="date"
                  className="form-control"
                  value={startdate}
                  style={{ width: "150px", fontSize: "12px" }}
                  onChange={(e) => Setstartdate(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="form-control w-100"
                  style={{ resize: "none", height: "150px", fontSize: "12px" }}
                  value={memo}
                  onChange={(e) => SetMemo(e.target.value)}
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <Button
          fullWidth
          variant="contained"
          onClick={() => applyClick()}
          disabled={loading}
        >
          {applyState.end_cnt > 0 ? "다시 시작" : "신청"}
        </Button>
      </Container>
      <TestMakeDayModal
        ModalDayIsOpen={ModalDayIsOpen}
        SetModalDayIsOpen={SetModalDayIsOpen}
        type={type}
        day={day}
        yoil={yoil}
        Settype={Settype}
        Setday={Setday}
        Setyoil={Setyoil}
      />
      {loading && <img src={Loading} className="loading"></img>}
    </>
  );
};
