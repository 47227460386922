/* eslint-disable camelcase */
import React, { useState } from "react";
import { Form, Container, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import loginBg from "../img/login/login_bg.png";
import { instance } from "../common/common";
import loginStyles from "../css/login.module.css";

export function Newpass(props) {
  const u_seq = useParams();
  const [data, setData] = useState({
    u_pass: "",
    u_pass2: "",
  });

  const { u_pass, u_pass2 } = data;

  const SignupOnChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  function FindPass() {
    if (!u_pass) {
      alert("비밀번호를 입력해주세요.");
      return false;
    } else if (u_pass !== u_pass2) {
      alert("비밀번호확인을 정확히 입력해주세요.");
      return false;
    } else {
      if (window.confirm("비밀번호를 변경 하시겠습니까?")) {
        instance
          .post(`/user/update/pw`, {
            u_seq: u_seq.id,
            u_pass: u_pass,
          })
          .then((res) => {
            window.location.replace("/login");
          });
      }
    }
  }

  return (
    <div className={loginStyles.wrap}>
      <div className={loginStyles.white_box}>
        <h1>0823 OLS</h1>
        <Form.Group className="mb-3">
          <Form.Label className="mb-3"> 비밀번호 변경</Form.Label>

          <Form.Control
            className="mb-3"
            placeholder="새비밀번호"
            type="password"
            name="u_pass"
            onChange={(e) => SignupOnChange(e)}
          />
          <Form.Control
            className="mb-3"
            placeholder="새비밀번호 확인"
            type="password"
            name="u_pass2"
            onChange={(e) => SignupOnChange(e)}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="w-100"
          onClick={() => FindPass()}
        >
          비밀번호 변경
        </Button>
        <hr />
        <div className="my-2 mt-4">
          <Link to="/login">
            <span className={`${loginStyles.fs_14} text-secondary`}>
              로그인 하기
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
