import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import bstyles from "../css/bs.module.css";

function BottomSlider({ max, totalcount, idx }) {
  const [value, setValue] = React.useState(idx);

  return (
    <div className={`${bstyles.ab} text-center`}>
      <span style={{ float: "left", lineHeight: "42px" }}>1</span>
      <Slider
        style={{ width: "75%" }}
        size="small"
        min={1}
        max={max}
        value={value}
        aria-label="Small"
        valueLabelDisplay="on"
        onChangeCommitted={(e, v) => setValue(v)}
      />
      <span style={{ float: "right", lineHeight: "42px" }}>{max}</span>
    </div>
  );
}

export default BottomSlider;
