import React from "react";
import QnaStyles from "../css/qna.module.css";
import { EmptyList } from "./EmptyList";
import { timeForToday } from "../common/common";
import { useNavigate } from "react-router-dom";

import parse from "html-react-parser";

export const QnaBox = ({ qna }) => {
  const navigate = useNavigate();
  const goPage = (type, id) => {
    navigate(`/qnaDetail/${type}/${id}`);
  };

  if (qna.length === 0)
    return <EmptyList text={`등록된 글이 존재하지 않습니다.`} />;
  return (
    <div className="mt-3">
      {qna.map((v, i) => (
        <div
          className={`${QnaStyles.qnaDiv} bg-white p-3`}
          key={i}
          onClick={() => goPage(v.type, v.seq)}
        >
          <p className={QnaStyles.titleTexxt}>{v.title}</p>
          <div className={QnaStyles.contentText}>
            {v.content && parse(v.content)}
          </div>
          <div className={QnaStyles.bottomText}>
            <span>{v.u_name}</span>
            <span>&nbsp;·&nbsp;</span>
            <span>{timeForToday(v.date)}</span>
          </div>
          <div className={QnaStyles.answerWrap}>
            <div className={QnaStyles.answerDiv}>
              <span>{v.sub_seq === 0 ? 0 : 1}&nbsp;</span>
              <span>답변</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
