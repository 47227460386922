import React, { useEffect, useState } from 'react';
import bottomStyles from '../css/bottomNavigation.module.css';

import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CampaignIcon from '@mui/icons-material/Campaign';

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { GlobalStyles } from '@mui/styled-engine';
import { userinfoStore } from '../zustand/userinfoStore';

const inputGlobalStyles = <GlobalStyles styles={{ color: '#a82126' }} />;

export const BottomNavi = () => {
    const user_info = userinfoStore(state => state.user_info);

    const navigate = useNavigate();

    const [value, setValue] = useState('홈');
    const [isToggle, setToggle] = useState(false);

    const [isOp, setOp] = useState(0);

    useEffect(() => {
        if (window.location.href.indexOf('test') !== -1) {
        } else {
            setOp(1);
        }
    }, []);
    const sx = {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #ddd',
        opacity: isOp,
    };

    useEffect(() => {
        const nowurl = window.location.href;

        if (nowurl.indexOf('list') !== -1) {
            setValue('학습관리');
        } else if (nowurl.indexOf('oxnote') !== -1) {
            setValue('단어장');
        } else if (nowurl.indexOf('qna/study') !== -1) {
            setValue('Q&A');
        } else {
            setValue('홈');
        }
        // else if(nowurl.indexOf("공지사항") !== -1){
        //   setValue("");
        // }
    }, []);

    useEffect(() => {
        if (isToggle) {
            if (value === '홈') {
                navigate('/');
            } else if (value === '학습관리') {
                navigate('/list');
            } else if (value === '단어장') {
                navigate('/oxnote');
            } else if (value === 'Q&A') {
                navigate('/qna/study');
            } else if (value === '공지사항') {
                navigate('/notice');
            }

            setToggle(false);
        }
    }, [isToggle]);

    return (
        <div className={`${bottomStyles.wrap}`}>
            <div style={{ height: '1px' }}></div>
            <Box
                sx={sx}
                style={{ zIndex: '99', maxWidth: '768px' }}
                className={`${bottomStyles.center}`}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setToggle(true);
                        setValue(newValue);
                    }}>
                    <BottomNavigationAction
                        style={{
                            color: value === '홈' ? '#a82126' : null,
                            textShadow: '2px 2px 2px lightgray',
                        }}
                        label="홈"
                        value="홈"
                        icon={<HomeIcon />}
                    />
                    {user_info.u_seq && (
                        <BottomNavigationAction
                            label="단어장"
                            value="단어장"
                            style={{
                                color: value === '단어장' ? '#a82126' : null,
                                textShadow: '2px 2px 2px lightgray',
                            }}
                            icon={<BookmarkAddIcon />}
                        />
                    )}

                    {/* <BottomNavigationAction
            label="스크랩"
            value="스크랩"
            style={{
              color: value === "스크랩" ? "#a82126" : null,
              textShadow: "2px 2px 2px lightgray",
            }}
            icon={<BookmarkAddIcon />}
          />
          <BottomNavigationAction
            label="Q&A"
            value="Q&A"
            style={{
              color: value === "Q&A" ? "#a82126" : null,
              textShadow: "2px 2px 2px lightgray",
            }}
            icon={<QuestionAnswerIcon />}
          />
          <BottomNavigationAction
            label="공지사항"
            value="공지사항"
            style={{
              color: value === "공지사항" ? "#a82126" : null,
              textShadow: "2px 2px 2px lightgray",
            }}
            icon={<CampaignIcon />}
          /> */}
                </BottomNavigation>
            </Box>
        </div>
    );
};
