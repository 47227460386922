import axios from "axios";

/* axios instance 생성 */
export const instance = axios.create({
  baseURL: "https://young-ga.com:3000",
});

export const getData = async (routeName) => {
  const data = await instance.get(`${routeName}`);

  return data;
};

export const postData = async (routeName, params) => {
  const data = await instance.post(`${routeName}`, params);

  return data;
};

export const putData = async (routeName, params) => {
  const data = await instance.put(`${routeName}`, params);

  return data;
};

export const deleteData = async (routeName, params) => {
  const data = await instance.delete(`${routeName}`, params);

  return data;
};

export function currentDate() {
  let date = new Date();
  let year = date.getFullYear();

  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;

  let day = date.getDate();
  day = day >= 10 ? day : "0" + day;

  return year + "-" + month + "-" + day;
}

export function currentDateTime() {
  let date = new Date();
  let year = date.getFullYear();

  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;

  let day = date.getDate();
  day = day >= 10 ? day : "0" + day;

  let hours = date.getHours(); // 시
  let minutes = date.getMinutes(); // 분
  let seconds = date.getSeconds(); // 초

  return (
    year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  );
}

export function getDate(date) {
  var sYear = date.getFullYear();
  var sMonth = date.getMonth() + 1;
  var sDate = date.getDate();

  sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
  sDate = sDate > 9 ? sDate : "0" + sDate;
  return sYear + "-" + sMonth + "-" + sDate;
}

export function returnDate(date_str) {
  var yyyyMMdd = String(date_str);
  var sYear = yyyyMMdd.substring(0, 4);
  var sMonth = yyyyMMdd.substring(5, 7);
  var sDate = yyyyMMdd.substring(8, 10);

  //alert("sYear :"+sYear +"   sMonth :"+sMonth + "   sDate :"+sDate);
  return new Date(Number(sYear), Number(sMonth) - 1, Number(sDate));
}

export function getYoil() {
  let d = new Date();
  let week = ["일", "월", "화", "수", "목", "금", "토"];

  return week[d.getDay()];
}

export function timeForToday(value) {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );
  if (betweenTime < 1) return "방금전";
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일전`;
  }

  return `${Math.floor(betweenTimeDay / 365)}년전`;
}

export function recode(u_seq, l_text, l_browser, l_pcm, l_ip, l_mtype) {
  let obj = {
    u_seq: u_seq,
    l_text: l_text,
    l_browser: l_browser,
    l_pcm: l_pcm,
    l_ip: l_ip,
    l_mtype: l_mtype,
  };
  const res = postData(`log`, { obj: obj });
}

export function isUseDay(strDay) {
  if (strDay) {
    let Dayarr = strDay.split("-");
    let rYY = Number(Dayarr[0]);
    let rMM = "";
    if (Number(Dayarr[1]) < 10) {
      rMM = Number(Dayarr[1].replace("0", " ")) - 1;
    } else {
      rMM = Number(Dayarr[1]) - 1;
    }

    let rDD = "";
    if (Number(Dayarr[2]) < 10) {
      rDD = Number(Dayarr[2].replace("0", " "));
    } else {
      rDD = Number(Dayarr[2]);
    }

    let currarr = currentDate().split("-");

    let currDate = new Date(
      currarr[0],
      Number(currarr[1]) < 10
        ? Number(currarr[1].replace("0", " ")) - 1
        : Number(currarr[1]) - 1,
      Number(currarr[2]) < 10
        ? Number(currarr[2].replace("0", " "))
        : Number(currarr[2])
    );
    let tDate = new Date(rYY, rMM, rDD);

    // 입력한 시간이 현재일자 시간 이전이면 false
    if (tDate - currDate < 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export const getDateDiff = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const diffDate = date1.getTime() - date2.getTime();

  return diffDate / (1000 * 60 * 60 * 24); // 밀리세컨 * 초 * 분 * 시 = 일
};
