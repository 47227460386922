/* eslint-disable react/jsx-no-bind */
import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useContext,
} from 'react';
import { Container, Button } from 'react-bootstrap';
import PrintIcon from '@mui/icons-material/Print';
import useNoSleep from 'use-no-sleep';
import {
    Link,
    UNSAFE_NavigationContext as NavigationContext,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { Header } from '../components/Header';
import { Timer } from '../components/Timer';
import { Timer_v2 } from '../components/Timer_v2';
import { getData, postData, putData } from '../common/common';

import testStyles from '../css/test.module.css';
import { CheckForm } from '../components/CheckForm';
import correct from '../img/correct.png';
import wrong from '../img/wrong.png';
import { Confirm } from '../components/Confirm';
import { Toast } from '../components/Toast';
import { HoedockNext } from '../components/HoedockNext';

import parser from 'html-react-parser';

//zustand
import { userinfoStore } from '../zustand/userinfoStore';
import { ResultComponent } from '../components/ResultComponent';
import { EmptyList } from '../components/EmptyList';

import {
    ArrowDownCircleFill,
    ArrowDownUp,
    ArrowUpCircleFill,
    Printer,
    JournalX,
    ListUl,
    UiRadiosGrid,
} from 'react-bootstrap-icons';
import { PDFExport } from '@progress/kendo-react-pdf';
import { defineFont } from '@progress/kendo-drawing/pdf';
import { Box, Modal, Typography } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { Close } from '@mui/icons-material';
import { motion } from 'framer-motion';
import axios from 'axios';
// defineFont({
//   "Arial Unicode MS": "../fonts/ARIALUNI.TTF",
// });

export function Test(props) {
    const user_info = userinfoStore(state => state.user_info);
    const componentRef = useRef();
    const componentWrongRef = useRef();
    useEffect(() => {
        if (!user_info.u_seq) {
            window.sessionStorage.setItem('quizUrl', window.location.href);
            window.location.replace('/login');
        }
    }, []);
    /* 시험 쳤는지 유무 */
    const [isEnd, setEnd] = useState(false);
    /* -------------------------*/

    //중복제거
    const [isDupli, setIsDupli] = useState(false);

    const [isSuccess, setSuccess] = useState(false);
    const totalRef = useRef(0);
    const [isLoading, setLoading] = useState(false);
    const [omrState, SetOmrState] = useState(true);
    const [title, setTitle] = useState('');

    const [testInfo, setTestInfo] = useState({});
    const [data, setData] = useState([]);

    const [timeAttack, SetTimeAttack] = useState('N');

    const [cor, setCor] = useState([]);

    const [detail, setDetail] = useState([]);

    let params = useParams();

    const navigate = useNavigate();

    const [min, setMin] = useState(0);
    const [min2, setMin2] = useState(0);
    const [isConfirm, setConfirm] = useState(false);
    const [isToast, setToast] = useState(false);
    const [isToastM, setToastM] = useState(false);

    const [isStep, setStep] = useState('');

    const [tempTime, setTempTime] = useState({
        isTime: 'N',
        value: '',
    });

    const [isWhen, setWhen] = useState(false);

    useNoSleep(true);

    function useBlocker(blocker) {
        const { navigator } = useContext(NavigationContext);

        useEffect(() => {
            if (!isWhen) return;

            const unblock = navigator.block(tx => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };
                blocker(autoUnblockingTx);
            });
            return unblock;
        }, [navigator, blocker, isWhen]);
    }

    function usePrompt() {
        const blocker = useCallback(
            tx => {
                cancelTest();
                // if (testInfo.t_type === "전국모의고사") {

                // } else {
                //   navigate(-1);
                // }
            },
            [testInfo.t_type],
        );

        useBlocker(blocker, isWhen);
    }

    usePrompt();

    const [oneData, setOneData] = useState([]);

    const juganFetchData = async () => {
        setIsDupli(true);
        const tempData = [...data];
        const tempDetail = [...detail];
        const scrapArray = [];
        var cnt = 0,
            totalcnt = 0;
        tempData.forEach(function (v) {
            tempDetail.forEach(function (vv) {
                if (Number(vv.td_num) === Number(v.q_seq)) {
                    totalcnt++;
                    if (Number(v.q_answer) === Number(vv.td_user_num)) {
                        cnt++;
                        vv.td_check = 1;
                    } else {
                        vv.td_check = 0;
                        var obj = {
                            type:
                                testInfo.t_type === '기출관리'
                                    ? '기출오답노트'
                                    : testInfo.t_type === '모의고사'
                                    ? '모의고사오답노트'
                                    : '오답노트',
                            id: v.q_seq,
                        };
                        scrapArray.push(obj);
                    }
                }
            });
        });

        const score = {
            b_seq:
                testInfo.t_type === '모의고사'
                    ? JSON.parse(testInfo.b_seq)[0]
                    : testInfo.b_seq,
            u_seq: user_info.u_seq,
            t_seq: params.id,
            s_curri: testInfo.t_curri
                ? testInfo.t_curri
                : testInfo.t_type === '모의고사'
                ? '모의고사'
                : '기출',
            s_hoedock: testInfo.t_hoedock ?? 0,
            s_avg: (cnt / totalcnt) * 100,
        };

        let postUrl = 'v2';
        if (params.type === 'link') {
            postUrl = 'v3';
        }

        if (testInfo.t_type === '전범위시험') {
            let totalScore = 0;

            tempDetail.forEach(function (v) {
                data.forEach(function (vv) {
                    if (Number(vv.q_seq) === Number(v.td_num)) {
                        if (Number(v.td_user_num) === Number(vv.q_answer)) {
                            totalScore++;
                        }
                    }
                });
            });

            totalRef.current = totalScore;
            //totalRef.current = 100;
        }

        if (!isDupli) {
            const res = await postData(`/test_detail/${postUrl}`, {
                obj: tempDetail,
                scrapArray: JSON.stringify(scrapArray),
                u_seq: user_info.u_seq,
                score: score,
            });

            if (res.data.type === 'success') {
                if (testInfo.t_type === '전범위시험') {
                    const response = await postData(`/test_detail/allTest`, {
                        obj: {
                            b_seq: testInfo.b_seq,
                            u_seq: user_info.u_seq,
                            ta_score: totalRef.current,
                        },
                    });

                    if (response.data === 'success') {
                        setSuccess(true);
                        endTest();
                        setConfirm(false);
                        setWhen(false);
                    }
                    //setSuccess(true);
                } else {
                    endTest();
                    setConfirm(false);
                    setWhen(false);
                }
            }
        }
    };

    const sendFetchData = async () => {
        if (
            confirmText.title === '제출하시겠습니까?' ||
            confirmText.title === '응시를 취소하시겠습니까?'
        ) {
            setIsDupli(true);
            const tempData = [...data];
            const tempDetail = [...detail];
            const scrapArray = [];
            var cnt = 0,
                totalcnt = 0;
            tempData.forEach(function (v) {
                tempDetail.forEach(function (vv) {
                    if (Number(vv.td_num) === Number(v.q_seq)) {
                        totalcnt++;
                        if (Number(v.q_answer) === Number(vv.td_user_num)) {
                            cnt++;
                            vv.td_check = 1;
                        } else {
                            vv.td_check = 0;
                            var obj = {
                                type:
                                    testInfo.t_type === '기출관리'
                                        ? '기출오답노트'
                                        : testInfo.t_type === '모의고사'
                                        ? '모의고사오답노트'
                                        : '오답노트',
                                id: v.q_seq,
                            };
                            scrapArray.push(obj);
                        }
                    }
                });
            });

            const score = {
                b_seq:
                    testInfo.t_type === '모의고사'
                        ? JSON.parse(testInfo.b_seq)[0]
                        : testInfo.b_seq,
                u_seq: user_info.u_seq,
                t_seq: params.id,
                s_curri: testInfo.t_curri
                    ? testInfo.t_curri
                    : testInfo.t_type === '모의고사'
                    ? '모의고사'
                    : '기출',
                s_hoedock: testInfo.t_hoedock ?? 0,
                s_avg: (cnt / totalcnt) * 100,
            };

            let postUrl = 'v2';
            if (params.type === 'link') {
                postUrl = 'v3';
            }

            if (testInfo.t_type === '전범위시험') {
                let totalScore = 0;

                tempDetail.forEach(function (v) {
                    data.forEach(function (vv) {
                        if (Number(vv.q_seq) === Number(v.td_num)) {
                            if (Number(v.td_user_num) === Number(vv.q_answer)) {
                                totalScore++;
                            }
                        }
                    });
                });

                totalRef.current = totalScore;
                //totalRef.current = 100;
            }
            if (!isDupli) {
                const res = await postData(`/test_detail/${postUrl}`, {
                    obj: tempDetail,
                    scrapArray: JSON.stringify(scrapArray),
                    u_seq: user_info.u_seq,
                    score: score,
                });

                if (res.data.type === 'success') {
                    if (testInfo.t_type === '전범위시험') {
                        const response = await postData(
                            `/test_detail/allTest`,
                            {
                                obj: {
                                    b_seq: testInfo.b_seq,
                                    u_seq: user_info.u_seq,
                                    ta_score: totalRef.current,
                                },
                            },
                        );

                        if (response.data === 'success') {
                            setSuccess(true);
                            endTest();
                            setConfirm(false);
                            setWhen(false);
                        }
                        //setSuccess(true);
                    } else {
                        endTest();
                        setConfirm(false);
                        setWhen(false);
                    }
                }
            }
        } else if (confirmText.title === '시험을 중단하시겠습니까?') {
            navigate(-1);
        } else {
            openTest();
        }
    };

    const openTest = () => {
        setStep('start');
        setMin(tempTime.value);
        SetTimeAttack(tempTime.isTime);

        setConfirmText({
            title: '제출하시겠습니까?',
            message: '결과조회 페이지에서 확인이 가능합니다.',
        });

        setConfirm(false);
    };

    const [confirmText, setConfirmText] = useState({
        title: '제출하시겠습니까?',
        message: '결과조회 페이지에서 확인이 가능합니다.',
    });

    const cancelHandler = () => {
        if (
            confirmText.title === '제출하시겠습니까?' ||
            confirmText.title === '응시를 취소하시겠습니까?'
        ) {
            setConfirm(prev => !prev);
        } else {
            navigate(-1);
        }
    };

    const insertTestUser = async () => {
        if (user_info.u_seq && params.target) {
            let testObj = {
                t_seq: params.id,
                u_seq: user_info.u_seq,
                tu_u_seq: params.target,
            };
            const res = await postData(`/test_user`, { obj: testObj });
        }
    };
    useEffect(() => {
        if (params.type === 'link') {
            insertTestUser();
        }
    }, []);

    useEffect(() => {
        if (isStep === 'normal') {
            setConfirmText({
                title: '',
                message: '시험을 시작하시겠습니까?',
            });
        } else if (isStep === 'start') {
            if (!isEnd) {
                setMin(tempTime.value);
                SetTimeAttack(tempTime.isTime);
            }
        }
    }, [isStep]);

    useEffect(() => {
        if (confirmText.title !== '제출하시겠습니까?') {
            setConfirm(true);
        }
    }, [confirmText.title]);

    useEffect(() => {
        if (params.type !== 'ox' && params.type !== 'end') {
            startTest();
        }

        drawTitle();
    }, []);

    useEffect(() => {
        if (isLoading) {
            endCheck();
        }
    }, [isLoading]);

    useEffect(() => {
        if (cor.length > 0) {
            setEnd(true);
        }
    }, [cor]);

    useEffect(() => {
        if (isEnd) {
            setData(drawCorrectAndWrong());
        }
    }, [isEnd]);

    const endCheck = async () => {
        const res = await getData(
            `/test_user/${params.id}/my?u_seq=${user_info.u_seq}`,
        );
        setLoading(false);
        if (res.data.isEnd) {
            if (res.data.cor) setCor(res.data.cor);
        }
    };

    const drawCorrectAndWrong = () => {
        setWhen(false);
        let tempData = [...data];
        tempData.forEach(function (v) {
            cor.forEach(function (vv) {
                if (vv.td_num === v.q_seq) {
                    v.correct = vv.td_check;
                    v.user_num = vv.td_user_num;
                }
            });
        });

        return tempData;
    };

    const [timesType, setTimesType] = useState('N');
    const [timesValue, setTimesValue] = useState(0);

    useEffect(() => {
        setTempTime({
            isTime: timesType,
            value: timesValue,
        });
    }, [timesValue]);

    const startTest = async () => {
        const sRes = await getData(
            `/test_user/${params.id}/start_check?u_seq=${user_info.u_seq}`,
        );

        if (sRes.data.oneData) {
            sRes.data.oneData.tu_u_seq = user_info.u_seq;
            setOneData(sRes.data.oneData);
        }

        if (!sRes.data.isStart) {
            await putData(
                `/test_user/${params.id}/start?u_seq=${user_info.u_seq}`,
            );
        }
    };

    console.log('testIn : ', testInfo);
    const drawTitle = async () => {
        console.log(' drawTitle asdd :  ', params);
        let getDataUrl = `/test/v2/${params.id}/?u_seq=${user_info.u_seq}`;

        const res = await getData(getDataUrl);

        //console.log("drawTitle res : ", res.data);

        //let timesValue = res.data.row.t_timer;
        let tempValue = 0;
        axios
            .post('https://young-ga.com:3001/subject/list', { kind: '' })
            .then(async function (resp) {
                if (
                    res.data.row.t_motype === '하프' ||
                    res.data.row.t_motype === '실전'
                ) {
                    console.log('res.data.row.b_seq : ', res.data.row.b_seq);
                    await axios
                        .post('https://young-ga.com:3000/test/getBookType', {
                            b_seq: res.data.row.b_seq,
                        })
                        .then(async function (inRes) {
                            console.log('resp.data.rows : ', resp.data.rows);
                            console.log('inRes.data.rows : ', inRes.data.rows);
                            await resp.data.rows.forEach(function (items) {
                                inRes.data.rows.forEach(function (inItems) {
                                    if (inItems.b_cate === items.subject) {
                                        console.log(
                                            'inItems.b_cate : ',
                                            inItems.b_cate,
                                        );
                                        console.log(
                                            'items.subject : ',
                                            items.subject,
                                        );

                                        console.log(
                                            '더해질 시간 :',
                                            items.time_half,
                                        );
                                        tempValue += items.time_half * 1;
                                    }
                                });
                            });
                        });
                    // resp.data.rows.forEach(function(da){

                    // })
                } else if (res.data.row.t_motype === '주간') {
                    await axios
                        .post('https://young-ga.com:3000/test/getBookType', {
                            b_seq: res.data.row.b_seq,
                        })
                        .then(async function (inRes) {
                            await resp.data.rows.forEach(function (items) {
                                inRes.data.rows.forEach(function (inItems) {
                                    if (inItems.b_cate === items.subject) {
                                        tempValue += items.time_week * 1;
                                    }
                                });
                            });
                        });
                }
                setTimesType(res.data.row.t_yn_time);
                if (tempValue > 0) {
                    setTimesValue(tempValue);
                } else {
                    setTimesValue(res.data.row.t_timer);
                }
            });

        // setTempTime({
        //   isTime: res.data.row.t_yn_time,
        //   value: timesValue,
        // });

        if (res.data.limit !== undefined) {
            if (res.data.limit !== 0) {
                if (params.type === 'end') {
                    setStep('start');
                } else {
                    if (res.data.limit < 0) {
                        //console.log("res.data.row >>>>", res.data.row.t_type);
                        if (res.data.row.t_type === '모의고사') {
                            setStep('wait');
                            setMin2(res.data.limit);
                        } else {
                            setStep('not');
                        }
                    } else {
                        setStep('wait');

                        setMin2(res.data.limit);

                        if (res.data.row.t_type === '모의고사') {
                            setWhen(true);
                        } else if (res.data.row.t_type === '암기관리') {
                            //setDirect(res.data.direct);
                        }
                    }
                }

                //-----------아래꺼 주석 해야되고 위에 꺼 다 풀면됨 나중에 ------------//
                // setStep("start");
                // setMin(res.data.row.t_timer);
                // SetTimeAttack(res.data.row.t_yn_time);
            } else {
                setStep('normal');
                //setStep("normal");
                //setStep("start");
                //setMin(res.data.row.t_timer);
                //SetTimeAttack(res.data.row.t_yn_time);
            }
        } else {
            if (params.type === 'end') {
                setStep('start');
            } else {
                setStep('normal');
            }
        }

        setTitle(res.data.row.t_name);
        setTestInfo(res.data.row);
    };

    useEffect(() => {
        drawContent();
    }, [testInfo]);

    const drawContent = async () => {
        const res = await getData(
            `/questions/${params.id}/all?type=${params.type}&u_seq=${user_info.u_seq}`,
        );
        //const temp = [res.data.content.map(v => ...v)]
        const temp = res.data.title.map(v => {
            if (testInfo.t_type === '암기관리') {
                return {
                    ...v,
                    content: JSON.parse(v.q_contents),
                };
            } else {
                if (v.q_title.indexOf('①') !== -1) {
                    var devide = v.q_title.split('①');

                    var num1 = devide[1].split('②');
                    var num2 = num1[1].split('③');
                    var num3 = num2[1].split('④');
                    var num4 = num3[1].split('⑤');

                    //["①", "②", "③", "④", "⑤"];
                    //devide[1].split('④')
                    return {
                        ...v,
                        q_title: devide[0],
                        content: [num1[0], num2[0], num3[0], num4[0]],
                    };
                } else {
                    return {
                        ...v,
                        content: JSON.parse(v.q_contents),
                    };
                }
            }
        });

        let dArray = [];
        temp.forEach(function (t) {
            const obj = {
                t_seq: params.id,
                u_seq: user_info.u_seq,
                td_num: t.q_seq,
                td_user_num: -1,
            };
            dArray.push(obj);
        });

        setDetail(dArray);
        setData(temp);

        setLoading(true);
    };

    const radioChange = e => {
        const obj = {
            t_seq: params.id,
            u_seq: user_info.u_seq,
            td_num: e.target.dataset.target,
            td_user_num: Number(e.target.value),
        };

        let temp = [...detail];
        let isChk = false;

        temp.forEach(function (v) {
            if (Number(v.td_num) === Number(e.target.dataset.target)) {
                isChk = true;
                return false;
            }
        });

        if (isChk) {
            const t = temp.map(v =>
                Number(v.td_num) === Number(e.target.dataset.target)
                    ? { ...v, td_user_num: e.target.value }
                    : v,
            );
            setDetail(t);
        } else {
            setDetail([...detail, obj]);
        }
    };

    console.log('detail >>', detail);

    const toggleOMR = () => {
        SetOmrState(prev => !prev);
    };

    useEffect(() => {
        if (!omrState) {
            if (detail.length > 0) {
                document
                    .querySelectorAll('.form-check-input')
                    .forEach(function (v) {
                        detail.forEach(function (vv) {
                            if (
                                Number(vv.td_num) === Number(v.dataset.target)
                            ) {
                                if (
                                    Number(vv.td_user_num) === Number(v.value)
                                ) {
                                    v.checked = true;
                                }
                            }
                        });
                    });
            }
        }
    }, [detail]);

    function button() {
        if (!omrState) {
            return (
                <Button
                    variant="success"
                    type="submit"
                    className={`${testStyles.omrbtn}`}
                    onClick={toggleOMR}>
                    <span className={testStyles.margin}>OMR</span>
                    {/* <UiRadiosGrid className={testStyles.margin} /> */}
                </Button>
            );
        } else {
            return (
                <Button
                    variant="success"
                    type="submit"
                    className={`${testStyles.omrbtn}`}
                    onClick={toggleOMR}>
                    <span className={testStyles.margin}>OMR</span>
                    {/* <ListUl className={testStyles.margin} /> */}
                </Button>
            );
        }
    }

    const [isScroll, setScroll] = useState(document.body.scrollHeight);
    const scrollCatch = () => {
        let scrollValue = '';
        if (window.pageYOffset > 0) {
            scrollValue = 0;
        } else {
            scrollValue = document.body.scrollHeight;
        }
        setScroll(scrollValue); // window 스크롤 값을 ScrollY에 저장
    };

    const scrollHandler = scrollValue => {
        // 클릭하면 스크롤이 위로 올라가는 함수
        window.scrollTo({
            top: scrollValue,
            behavior: 'smooth',
        });
        setScroll(0); // ScrollY 의 값을 초기화
    };

    useEffect(() => {
        const watch = () => {
            window.addEventListener('scroll', scrollCatch);
        };
        watch(); // addEventListener 함수를 실행
        return () => {
            window.removeEventListener('scroll', scrollCatch); // addEventListener 함수를 삭제
        };
    });

    function UpDown() {
        if (isScroll !== 0) {
            return (
                <ArrowDownCircleFill
                    className={testStyles.upDown}
                    onClick={() => scrollHandler(isScroll)}
                />
            );
        } else {
            return (
                <ArrowUpCircleFill
                    className={testStyles.upDown}
                    onClick={() => scrollHandler(isScroll)}
                />
            );
        }
    }

    const sendTest = () => {
        let isContinue = true;
        let qnumArr = [];

        // if (testInfo.t_yn_time !== "Y") {
        //   data.forEach(function (dd, ii) {
        //     detail.forEach(function (d, i) {
        //       if (dd.q_seq === d.td_num) {
        //         if (d.td_user_num === -1) {
        //           qnumArr.push(ii + 1);
        //           isContinue = false;
        //           setMsg(`${qnumArr.join(",")}번 문제에 정답을 체크해주세요.`);
        //           return false;
        //         }
        //       }
        //     });
        //   });
        // }

        data.forEach(function (dd, ii) {
            detail.forEach(function (d, i) {
                if (dd.q_seq === d.td_num) {
                    if (d.td_user_num === -1) {
                        let moIndex = ii + 1;
                        if (testInfo.t_motype === '주간') {
                            if (moIndex <= 40 && moIndex > 20) {
                                moIndex = moIndex - 20;
                            } else if (moIndex > 40) {
                                moIndex = moIndex - 40;
                            }
                        } else if (testInfo.t_motype === '실전') {
                            //
                            //sendTest
                            if (moIndex >= 41 && moIndex < 81) {
                                moIndex = moIndex - 40;
                            } else if (moIndex >= 81) {
                                moIndex = moIndex - 80;
                            }
                        } else {
                            if (moIndex <= 20 && moIndex > 10) {
                                moIndex = moIndex - 10;
                            } else if (moIndex > 20) {
                                moIndex = moIndex - 20;
                            }
                        }
                        if (testInfo.t_type !== '모의고사') {
                            qnumArr.push(moIndex);
                        } else {
                            qnumArr.push({ type: dd.b_cate, no: moIndex });
                        }

                        isContinue = false;
                        console.log('qnumArr : ', qnumArr);
                    }
                }
            });
        });

        if (testInfo.t_type === '모의고사') {
            let qn1 = [],
                qn2 = [],
                qn3 = [],
                qn4 = [],
                qn5 = [];
            let str = [];

            let police1 = [],
                police2 = [],
                police3 = [];

            qnumArr.forEach(function (v) {
                if (v.type === '국어') {
                    console.log('국어 chk: ');
                    qn1.push(v.no);
                } else if (v.type === '영어') {
                    console.log('영어 chk: ');
                    qn2.push(v.no);
                } else if (v.type === '한국사') {
                    qn3.push(v.no);
                } else if (v.type === '행정법총론') {
                    qn4.push(v.no);
                } else if (v.type === '행정학개론') {
                    qn5.push(v.no);
                } else if (v.type === '형사법') {
                    police1.push(v.no);
                } else if (v.type === '경찰학') {
                    police2.push(v.no);
                } else if (v.type === '헌법') {
                    police3.push(v.no);
                }
            });

            console.log('qn1 chk: ', qn1);
            console.log('qn2 chk: ', qn2);
            if (qn1.length > 0) {
                let obj = {
                    type: '국어',
                    list: qn1,
                };
                str.push(obj);
            }
            if (qn2.length > 0) {
                let obj = {
                    type: '영어',
                    list: qn2,
                };
                str.push(obj);
            }
            if (qn3.length > 0) {
                let obj = {
                    type: '한국사',
                    list: qn3,
                };
                str.push(obj);
            }

            if (qn4.length > 0) {
                let obj = {
                    type: '행정법총론',
                    list: qn4,
                };
                str.push(obj);
            }
            if (qn5.length > 0) {
                let obj = {
                    type: '행정학개론',
                    list: qn5,
                };
                str.push(obj);
            }

            if (police1.length > 0) {
                let obj = {
                    type: '형사법',
                    list: police1,
                };
                str.push(obj);
            }

            if (police2.length > 0) {
                let obj = {
                    type: '경찰학',
                    list: police2,
                };
                str.push(obj);
            }

            if (police3.length > 0) {
                let obj = {
                    type: '헌법',
                    list: police3,
                };
                str.push(obj);
            }
            console.log('str : ', str);
            if (str.length > 0) {
                setMsg(str);
            }
        } else {
            if (qnumArr.length > 0) {
                setMsg(`${qnumArr.join(',')}번 문제에 정답을 체크해주세요.`);
            }
        }

        if (isContinue) {
            setConfirm(true);
        }

        //setConfirm(true);
    };

    const endTest = async () => {
        const res = await putData(
            `/test_user/${params.id}/end?u_seq=${user_info.u_seq}`,
        );
        if (res.data === 'success') {
            //alert("제출 완료되었습니다.");
            setToast(true);
            setLoading(true);
            setIsDupli(false);
            //navigate(-1);
        }
    };

    const Grading = ({ gr, index }) => {
        if (gr === 1) {
            if ((index + 1) % 10 === 1) {
                return (
                    <img
                        src={correct}
                        className={`${testStyles.grading} ${
                            testInfo.t_type === '모의고사'
                                ? testStyles.down
                                : null
                        }`}
                        alt="correct"
                    />
                );
            } else {
                return (
                    <img
                        src={correct}
                        className={`${testStyles.grading}`}
                        alt="correct"
                    />
                );
            }
        } else if (gr === 0) {
            if ((index + 1) % 10 === 1) {
                return (
                    <img
                        src={wrong}
                        className={`${testStyles.grading} ${
                            testInfo.t_type === '모의고사'
                                ? testStyles.down
                                : null
                        }`}
                        alt="wrong"
                    />
                );
            } else {
                return (
                    <img
                        src={wrong}
                        className={`${testStyles.grading}`}
                        alt="wrong"
                    />
                );
            }
        } else {
            return <></>;
        }
    };

    const tdActive = (v, i, no) => {
        //console.log("v : ", v);
        let className = ``;

        if (isEnd) {
            if (Number(v.user_num) === i) className = `${testStyles.active}`;
            if (Number(v.q_answer) === i) className += ' text-danger';
        } else {
            detail.forEach(function (vv) {
                if (
                    Number(vv.td_user_num) === i &&
                    Number(vv.td_num) === Number(no)
                ) {
                    className = `${testStyles.active}`;
                }
                return false;
            });
        }

        return className;
    };

    const omrActive = (no, target) => {
        const obj = {
            t_seq: params.id,
            u_seq: user_info.u_seq,
            td_num: target,
            td_user_num: Number(no),
        };

        let temp = [...detail];
        let isChk = false;

        temp.forEach(function (v) {
            if (Number(v.td_num) === Number(target)) {
                isChk = true;
                return false;
            }
        });

        if (isChk) {
            const t = temp.map(v =>
                Number(v.td_num) === Number(target)
                    ? { ...v, td_user_num: no }
                    : v,
            );
            setDetail(t);
        } else {
            setDetail([...detail, obj]);
        }
    };

    const [msg, setMsg] = useState('');

    const toastMsg = () => {
        if (!msg) {
            return (
                <div className="toast-div">
                    <div>수고하셨습니다!</div>
                    <div>결과조회 페이지에서 확인이 가능합니다.</div>
                </div>
            );
        } else {
            if (testInfo.t_type === '모의고사') {
                return (
                    <div className="toast-div">
                        {msg.map((v, i) => (
                            <div key={i}>
                                <div>[{v.type}]</div>
                                <div>
                                    {v.list.join(',')}번 문제에 정답을
                                    체크해주세요.{' '}
                                </div>
                            </div>
                        ))}
                    </div>
                );
            } else {
                return (
                    <div className="toast-div">
                        <div>{msg}</div>
                    </div>
                );
            }
        }
    };

    const labelArray = ['①', '②', '③', '④', '⑤'];

    const [scrap, setScrap] = useState([]);

    useEffect(() => {
        getScrap();
    }, []);

    const getScrap = async () => {
        const res = await getData(
            `/scrap/${params.id}/?u_seq=${user_info.u_seq}`,
        );

        setScrap(res.data);
    };

    const onScrap = e => {
        const tempScrap = [...scrap];
        let isSCrapCheck = false;
        tempScrap.forEach(function (x) {
            if (Number(x.id) === Number(e)) {
                isSCrapCheck = true;
            }
        });

        if (isSCrapCheck) {
            const filter = tempScrap.filter(v => v.id !== e);

            setScrap([...filter]);
            setMsg('해당 문제가 스크랩 취소 되었습니다.');
        } else {
            setScrap([...tempScrap, { id: e, type: '스크랩' }]);
            setMsg('해당 문제가 스크랩 되었습니다.');
        }
    };

    useEffect(() => {
        if (msg) {
            if (msg.indexOf('스크랩') !== -1) {
                scrapHandler();
            } else {
                setToastM(true);
            }
        }
    }, [msg]);

    const scrapHandler = async () => {
        let obj = {
            u_seq: user_info.u_seq,
            s_group: JSON.stringify(scrap),
        };

        const res = await postData(`/scrap`, { obj: obj });

        if (res.data === 'success') {
            if (params.type === 'ox') {
                drawTitle();
            }
            setToastM(true);
        }
    };

    useEffect(() => {
        if (isToastM) {
            setTimeout(() => {
                setMsg('');
                setToastM(false);
            }, 2000);
        }
    }, [isToastM]);

    const cancelTest = () => {
        setConfirmText({
            title: '응시를 취소하시겠습니까?',
            message: '취소시 재응시가 불가능 합니다.',
        });
    };

    const backTest = () => {
        if (isStep === 'wait') {
            navigate(-1);
        } else {
            setConfirmText({
                title: '시험을 중단하시겠습니까?',
                message: '',
            });
        }
    };

    const pdfExportComponent = useRef(null);
    const handleExportWithComponent = () => {
        pdfExportComponent.current.save();
    };

    const testWord = ex => {
        let str = '';
        ex.split('').forEach(function (v) {
            if (v === '(') {
                str += `<span class="${testStyles.font} ${testStyles.under} text-primary">`;
            } else if (v === ')') {
                str += '</span>';
            } else if (v === '#') {
                str += '"';
            } else {
                str += v;
            }
        });

        return str;
    };

    async function limitUpdate(limit) {
        await putData(
            `/test_user/${params.id}/limit?u_seq=${user_info.u_seq}&tu_limit=${limit}`,
        );
    }

    function ScopeTest() {
        let times = Number(testInfo.t_times.split('회차')[0]);

        const range = testInfo.t_range;

        let strat_range;
        let end_range;

        if (times === 1) {
            strat_range = 1;
        } else {
            strat_range = range * times - range + 1;
        }
        end_range = range * times;

        let scope = data.sort((a, b) => a.q_num - b.q_num);

        /////////////////임시방편 범위

        //////////////////////////

        return testInfo.b_copyright === 0 ? (
            <p>
                <span className="fw-bold">{scope[0]?.q_cate}</span> ~{' '}
                <span className="fw-bold">
                    {scope[scope.length - 1]?.q_cate}
                </span>{' '}
                입니다.
            </p>
        ) : (
            // <p>
            //   <span className="fw-bold">
            //     {scope[0]?.q_num}. {scope[0]?.q_title}
            //   </span>{" "}
            //   ~{" "}
            //   <span className="fw-bold">
            //     {scope[scope.length - 1]?.q_num}. {scope[scope.length - 1]?.q_title}
            //   </span>{" "}
            //   입니다.
            // </p>
            <p>
                <span className="fw-bold">{strat_range}</span>번 ~{' '}
                <span className="fw-bold">{end_range}</span>번 입니다.
            </p>
        );
    }

    let moIndexValue = useRef(null);
    const indexHnadler = moIndex => {
        if (testInfo.t_motype === '주간') {
            if (moIndex <= 40 && moIndex > 20) {
                moIndex = moIndex - 20;
            } else if (moIndex <= 60 && moIndex > 40) {
                moIndex = moIndex - 40;
            } else if (moIndex <= 80 && moIndex > 60) {
                moIndex = moIndex - 60;
            } else if (moIndex <= 100 && moIndex > 80) {
                moIndex = moIndex - 80;
            } else if (moIndex <= 120 && moIndex > 100) {
                moIndex = moIndex - 100;
            }
        } else if (testInfo.t_motype === '하프') {
            if (moIndex <= 20 && moIndex > 10) {
                moIndex = moIndex - 10;
            } else if (moIndex <= 30 && moIndex > 20) {
                moIndex = moIndex - 20;
            } else if (moIndex <= 40 && moIndex > 30) {
                moIndex = moIndex - 30;
            } else if (moIndex <= 50 && moIndex > 40) {
                moIndex = moIndex - 40;
            } else if (moIndex <= 60 && moIndex > 50) {
                moIndex = moIndex - 50;
            }
        } else if (testInfo.t_motype === '실전') {
            if (moIndex >= 41 && moIndex < 81) {
                moIndex = moIndex - 40;
            } else if (moIndex >= 81) {
                moIndex = moIndex - 80;
            }
        }

        return moIndex;
    };
    const TitleComp = ({ index, v, cate }) => {
        if (testInfo.t_type === '모의고사') {
            let moIndex = index + 1;
            //moIndexValue.current = moIndex

            if (testInfo.t_motype === '주간') {
                if (moIndex <= 40 && moIndex > 20) {
                    moIndex = moIndex - 20;
                } else if (moIndex <= 60 && moIndex > 40) {
                    moIndex = moIndex - 40;
                } else if (moIndex <= 80 && moIndex > 60) {
                    moIndex = moIndex - 60;
                } else if (moIndex <= 100 && moIndex > 80) {
                    moIndex = moIndex - 80;
                } else if (moIndex <= 120 && moIndex > 100) {
                    moIndex = moIndex - 100;
                }
            } else if (testInfo.t_motype === '실전') {
                if (moIndex > 20) {
                    moIndex = moIndex - 20;
                    if (moIndex > 40) {
                        moIndex = moIndex - 40;
                    }
                }
            } else {
                if (moIndex <= 20 && moIndex > 10) {
                    moIndex = moIndex - 10;
                } else if (moIndex <= 30 && moIndex > 20) {
                    moIndex = moIndex - 20;
                } else if (moIndex <= 40 && moIndex > 30) {
                    moIndex = moIndex - 30;
                } else if (moIndex <= 50 && moIndex > 40) {
                    moIndex = moIndex - 40;
                } else if (moIndex <= 60 && moIndex > 50) {
                    moIndex = moIndex - 50;
                }
            }

            return (
                <pre className={`${testStyles.font}`}>
                    {moIndex}.{' '}
                    {parser(v.q_title.replace(/#/g, '"').replace(/<br>/g, ''))}
                </pre>
            );
        } else {
            if (testInfo.b_cate === '국어') {
                return (
                    <pre className={`${testStyles.font}`}>
                        {index + 1}. {v.q_gong} <br />{' '}
                        <span
                            className={testStyles.font}
                            style={{ fontSize: '14px' }}>
                            {' '}
                            {v.q_title.replace('•', '')}
                        </span>
                    </pre>
                );
            } else if (testInfo.b_cate === '영어') {
                return (
                    <p className={`${testStyles.font}`}>
                        {index + 1}.{' '}
                        {
                            <span
                                className={`${testStyles.font}`}
                                dangerouslySetInnerHTML={{
                                    __html: testWord(v.q_explain),
                                }}></span>
                        }
                    </p>
                );
            } else {
                return (
                    <pre className={`${testStyles.font}`}>
                        {index + 1}. {v.q_title.replace('•', '')}
                    </pre>
                );
            }
        }
    };

    const imageName = index => {
        if (index < 10) {
            index = '0' + index;
        }

        return testInfo.t_name + '-' + index;
    };

    const clearBook = async () => {
        const res = await putData(`/test_user/${params.id}/clear`, {
            obj: { tu_complete: 1 },
            u_seq: user_info.u_seq,
        });

        if (res.data === 'success') {
            alert('완독처리 되었습니다.');
            navigate(-1);
        }
    };

    const [wrongHide, setWrongHide] = useState(false);

    console.log('data : ', data);

    // console.log("min2 >>", min2);
    const [isPrint, setPrint] = useState(false);

    const TopResult = () => {
        if (testInfo.t_type !== '모의고사') {
            return (
                <div className="d-flex justify-content-center fw-bold mb-2 pt-3">
                    맞은갯수 :
                    <span className="text-success ms-1">
                        {data.filter(v => v.correct === 1).length}
                    </span>{' '}
                    / {data.length}
                </div>
            );
        } else {
            let result = data.filter(v => v.correct === 1);

            let cateArr_tmp = data.map(v => v.b_cate);
            let cateArr = cateArr_tmp.filter((element, index) => {
                return cateArr_tmp.indexOf(element) === index;
            });

            let cateObj = {};
            let resultObj = {};

            for (let c = 0; c < cateArr.length; c++) {
                cateObj[`data${c}`] = data.filter(
                    v => v.b_cate === cateArr[c],
                ).length;
            }

            for (let c = 0; c < cateArr.length; c++) {
                resultObj[`result${c}`] = result.filter(
                    v => v.b_cate === cateArr[c],
                ).length;
            }

            let totalScore = 0;
            for (let i = 0; i < cateArr.length; i++) {
                totalScore +=
                    (resultObj[`result${i}`] / cateObj[`data${i}`]) * 100;
            }

            let rr = totalScore / cateArr.length;

            return (
                <div className="mb-2 pt-3">
                    <table className="table table-bordered text-center mx-auto">
                        <thead>
                            <tr>
                                {cateArr.map((v, i) => (
                                    <th key={i}>{v}</th>
                                ))}
                                <th>총점평균</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {cateArr.map((v, i) => (
                                    <td key={i}>
                                        {(
                                            (resultObj[`result${i}`] /
                                                cateObj[`data${i}`]) *
                                            100
                                        ).toFixed(1)}
                                    </td>
                                ))}
                                <td className="text-danger">{rr.toFixed(1)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    };
    return (
        <motion.div
            className="testPage"
            intial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{ overflowY: 'hidden', position: 'relative' }}>
            {(params.type === 'ox' || params.type === 'end' || isEnd) &&
                testInfo.t_type === '기출관리' && (
                    <div className={testStyles.print}>
                        <ReactToPrint
                            trigger={() => (
                                <div className={`text-center`}>
                                    <PrintIcon style={{ fontSize: '18px' }} />
                                    <br />
                                    <span className={testStyles.printSpan}>
                                        전체문제
                                    </span>
                                </div>
                            )}
                            content={() => componentRef.current}
                        />
                        <hr />
                        <ReactToPrint
                            trigger={() => (
                                <div className={`text-center`}>
                                    <PrintIcon style={{ fontSize: '18px' }} />
                                    <br />
                                    <span className={testStyles.printSpan}>
                                        틀린문제
                                    </span>
                                </div>
                            )}
                            content={() => componentRef.current}
                            onBeforeGetContent={async () =>
                                await setWrongHide(true)
                            }
                        />
                    </div>
                )}
            {button()}
            <UpDown />
            {isEnd ? (
                <Header title={title} />
            ) : timeAttack === 'Y' ? (
                <Header
                    title={
                        min && (
                            <Timer
                                title={title}
                                bun={min}
                                sendFetchData={juganFetchData}
                            />
                        )
                    }
                />
            ) : (
                <Header title={title} />
            )}
            {isEnd ? (
                <div className={`${testStyles.bottombtn} p-3`}>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => navigate(-1)}>
                        목록으로
                    </Button>
                </div>
            ) : params.type === 'ox' ? (
                <div className={`${testStyles.bottombtn} p-3`}>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() => navigate(-1)}>
                        시험종료
                    </Button>
                </div>
            ) : (
                <div className={`${testStyles.bottombtn} p-3`}>
                    <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        onClick={() => sendTest()}>
                        제출하기
                    </Button>
                    <span className="mx-2"> </span>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-100"
                        onClick={() =>
                            testInfo.t_type === '모의고사'
                                ? cancelTest()
                                : backTest()
                        }>
                        {testInfo.t_type === '모의고사'
                            ? '응시취소'
                            : '목록으로'}
                    </Button>
                </div>
            )}
            <PDFExport ref={pdfExportComponent} paperSize="A4">
                <Container style={{ background: '#fff', maxWidth: '768px' }}>
                    <div
                        className={`${testStyles.nameWrap}`}
                        style={{
                            marginTop:
                                params.type === 'ox' ||
                                params.type === 'end' ||
                                isEnd
                                    ? '80px'
                                    : '60px',
                        }}>
                        {(params.type === 'end' || isEnd) && <TopResult />}
                        <span>응시일자 : {testInfo.t_start_date} / </span>
                        <span> {user_info.u_name} 님</span>
                    </div>
                    <main className={testStyles.main}>
                        <div className={testStyles.testTop}>
                            {testInfo.t_type} <span>{testInfo.b_cate}</span>
                        </div>
                        <div className={testStyles.testName}>{title}</div>
                        {params.type === 'ox' && data.length === 0 && (
                            <EmptyList
                                text={'등록된 스크랩이 존재하지 않습니다.'}
                            />
                        )}
                        {isStep === 'start' ||
                        isStep === 'nomral' ||
                        params.type === 'end' ? (
                            <div>
                                <div
                                    class={`${testStyles.omrdiv} ${
                                        omrState ? 'd-none' : null
                                    }`}>
                                    <Close
                                        className={testStyles.close}
                                        onClick={() => {
                                            SetOmrState(true);
                                        }}
                                    />
                                    <table className="table text-center mb-0">
                                        <tbody>
                                            {data.map((v, i) => (
                                                <tr className="tr-omr" key={i}>
                                                    <td
                                                        style={
                                                            v.b_motype && {
                                                                fontSize:
                                                                    '11px',
                                                            }
                                                        }>
                                                        {v.b_motype && v.b_cate}{' '}
                                                        {indexHnadler(i + 1)}
                                                    </td>
                                                    {labelArray.map(
                                                        (label, ii) =>
                                                            ii + 1 <=
                                                                v.content
                                                                    .length && (
                                                                <td
                                                                    className={tdActive(
                                                                        v,
                                                                        ii + 1,
                                                                        v.q_seq,
                                                                    )}
                                                                    data-no={
                                                                        ii + 1
                                                                    }
                                                                    key={ii}
                                                                    onClick={() =>
                                                                        omrActive(
                                                                            ii +
                                                                                1,
                                                                            v.q_seq,
                                                                        )
                                                                    }>
                                                                    {label}
                                                                </td>
                                                            ),
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    {data.map((v, i) => (
                                        <div
                                            className={`mb-5 position-relative ${
                                                wrongHide
                                                    ? Number(v.correct) === 1
                                                        ? 'no-print'
                                                        : null
                                                    : null
                                            }`}
                                            key={i}>
                                            <Grading gr={v.correct} index={i} />
                                            {v.b_motype && (
                                                <>
                                                    {i > 0 ? (
                                                        <>
                                                            {data[i - 1]
                                                                .b_cate !==
                                                                data[i]
                                                                    .b_cate && (
                                                                <>
                                                                    <hr />
                                                                    <div className="text-center">
                                                                        {
                                                                            v.b_cate
                                                                        }
                                                                    </div>
                                                                    <hr />
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <hr />
                                                            <div className="text-center">
                                                                {v.b_cate}
                                                            </div>
                                                            <hr />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            <TitleComp
                                                index={i}
                                                v={v}
                                                cate={v.b_cate}
                                            />
                                            <span
                                                className={`${testStyles.font} yes-print`}>
                                                정답 : {v.q_answer}
                                            </span>
                                            {v.q_img && (
                                                <div className="text-center no-print">
                                                    <img
                                                        src={v.q_img}
                                                        className="mb-4"
                                                        alt="보기 이미지"
                                                    />
                                                </div>
                                            )}

                                            {testInfo.t_type !== '암기관리' &&
                                                v.q_explain && (
                                                    <div
                                                        className={`${testStyles.jimoon} mb-4 p-3`}>
                                                        {parser(
                                                            `<pre class="jimoonWrap mb-0" style="font-family: 'ChosunSm';font-size:14px;font-weight:300 !important;">${v.q_explain.replace(
                                                                /#/g,
                                                                '"',
                                                            )}</pre>`,
                                                        )}
                                                    </div>
                                                )}

                                            <CheckForm
                                                index={i}
                                                radioChange={radioChange}
                                                v={v}
                                            />
                                            {(params.type === 'ox' ||
                                                params.type === 'end' ||
                                                isEnd) && (
                                                <>
                                                    {v.q_comment && (
                                                        <div
                                                            className={`${testStyles.preTitle} text-primary`}>
                                                            해설
                                                        </div>
                                                    )}

                                                    {v.q_comment && (
                                                        <pre
                                                            className={
                                                                testStyles.pre
                                                            }>
                                                            {parser(
                                                                v.q_comment,
                                                            )}
                                                        </pre>
                                                    )}

                                                    {v.q_heasuck && (
                                                        <div
                                                            className={`${testStyles.preTitle} text-primary`}>
                                                            해석
                                                        </div>
                                                    )}

                                                    {v.q_heasuck && (
                                                        <pre
                                                            className={
                                                                testStyles.pre
                                                            }>
                                                            {parser(
                                                                v.q_heasuck,
                                                            )}
                                                        </pre>
                                                    )}
                                                </>
                                            )}
                                            {/* {(params.type === "ox" || params.type === "end") && (
                            <ResultComponent
                              v={v}
                              scrap={scrap}
                              onScrap={onScrap}
                              type={params.type}
                              tid={params.id}
                            />
                          )} */}
                                        </div>
                                    ))}
                                </div>

                                <div
                                    ref={componentRef}
                                    className={`${testStyles.printDiv}`}>
                                    {data.map((v, i) => (
                                        <div className="row">
                                            <div
                                                className={`mb-5 position-relative col-6 ${
                                                    wrongHide
                                                        ? Number(v.correct) ===
                                                          1
                                                            ? 'no-print'
                                                            : null
                                                        : null
                                                }`}
                                                key={i}>
                                                <Grading
                                                    gr={v.correct}
                                                    index={i}
                                                />

                                                {v.b_motype && (
                                                    <>
                                                        {i > 0 ? (
                                                            <>
                                                                {data[i - 1]
                                                                    .b_cate !==
                                                                    data[i]
                                                                        .b_cate && (
                                                                    <>
                                                                        <hr />
                                                                        <div className="text-center">
                                                                            {
                                                                                v.b_cate
                                                                            }
                                                                        </div>
                                                                        <hr />
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <hr />
                                                                <div className="text-center">
                                                                    {v.b_cate}
                                                                </div>
                                                                <hr />
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                                <TitleComp index={i} v={v} />

                                                {v.q_img && (
                                                    <div className="text-center no-print">
                                                        <img
                                                            src={v.q_img}
                                                            className="mb-4"
                                                            alt="보기 이미지"
                                                        />
                                                    </div>
                                                )}

                                                {testInfo.t_type !==
                                                    '암기관리' &&
                                                    v.q_explain && (
                                                        <div
                                                            className={`${testStyles.jimoon} mb-4 p-3`}>
                                                            {parser(
                                                                `<pre class="jimoonWrap mb-0" style="font-family: 'ChosunSm';font-size:14px;font-weight:300 !important;">${v.q_explain.replace(
                                                                    /#/g,
                                                                    '"',
                                                                )}</pre>`,
                                                            )}
                                                        </div>
                                                    )}

                                                {isPrint && (
                                                    <CheckForm
                                                        index={i}
                                                        radioChange={
                                                            radioChange
                                                        }
                                                        v={v}
                                                    />
                                                )}

                                                {/* {(params.type === "ox" || params.type === "end") && (
                            <ResultComponent
                              v={v}
                              scrap={scrap}
                              onScrap={onScrap}
                              type={params.type}
                              tid={params.id}
                            />
                          )} */}
                                            </div>
                                            <div
                                                className={`mb-5 position-relative col-6 ${
                                                    wrongHide
                                                        ? Number(v.correct) ===
                                                          1
                                                            ? 'no-print'
                                                            : null
                                                        : null
                                                }`}
                                                key={i}>
                                                <div className="p-2 border">
                                                    <span
                                                        className={`${testStyles.font} yes-print`}>
                                                        정답 : {v.q_answer}
                                                    </span>
                                                    {(params.type === 'ox' ||
                                                        params.type === 'end' ||
                                                        isEnd) && (
                                                        <>
                                                            {v.q_comment && (
                                                                <div
                                                                    className={`${testStyles.preTitle} text-primary`}>
                                                                    해설
                                                                </div>
                                                            )}

                                                            {v.q_comment && (
                                                                <pre
                                                                    className={
                                                                        testStyles.pre
                                                                    }>
                                                                    {parser(
                                                                        v.q_comment,
                                                                    )}
                                                                </pre>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : isStep === 'not' ? (
                            <div className="text-center my-5">
                                <p>이미 시험이 시작되었습니다.</p>
                            </div>
                        ) : testInfo.t_type === '암기관리' ? (
                            <div className="text-center my-5">
                                <p className="mb-0">이번 시험의 범위는</p>
                                <ScopeTest />

                                <div>
                                    {min2 && (
                                        <Timer_v2
                                            bun={min2}
                                            setStep={setStep}
                                            limitUpdate={limitUpdate}
                                            direct={1}
                                        />
                                    )}
                                    <p className="mt-3">
                                        이후 시험이 시작됩니다.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center my-5">
                                <p>잠시 후 시험이 시작됩니다.</p>
                                <div>
                                    <p className="m-0">남은시간</p>
                                    {min2 && (
                                        <Timer_v2
                                            bun={min2}
                                            setStep={setStep}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </main>
                </Container>
            </PDFExport>
            {params.type === 'end' || (
                <Confirm
                    title={confirmText.title}
                    message={confirmText.message}
                    isConfirm={isConfirm}
                    setConfirm={setConfirm}
                    setConfirmText={setConfirmText}
                    sendHandler={sendFetchData}
                    cancelHandler={cancelHandler}
                />
            )}
            {isToast && (
                <Toast
                    text={() => toastMsg()}
                    type="info"
                    time={2000}
                    isToast={isToast}
                    setToast={setToast}
                />
            )}
            {isToastM && (
                <Toast
                    text={() => toastMsg('')}
                    type="info"
                    time={2000}
                    isToast={isToastM}
                    setToast={setToastM}
                />
            )}

            <Modal
                open={isSuccess}
                onClose={() => setSuccess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: '13px', fontWeight: 'bold' }}>
                        점수 :{' '}
                        <span className="text-danger">
                            {' '}
                            {totalRef.current}{' '}
                        </span>
                        점
                        <div
                            className="text-secondary"
                            style={{ fontSize: '12px' }}>
                            - 99점 이상 획득시 완독처리가 가능합니다.
                        </div>
                    </Typography>

                    {totalRef.current >= 99 ? (
                        <div>
                            <span
                                className="btn btn-success w-100"
                                onClick={clearBook}>
                                완독처리
                            </span>
                            <span
                                className="btn btn-secondary w-100 mt-2"
                                onClick={() => setSuccess(false)}>
                                닫기
                            </span>
                        </div>
                    ) : (
                        <div>
                            <HoedockNext
                                oneData={oneData}
                                getList={() => {
                                    navigate(
                                        `/BookDetailView/${oneData.b_seq}/${oneData.b_type}`,
                                    );
                                }}
                                SetNextModalOpen={() => {}}
                            />
                            <span
                                className="btn btn-secondary w-100 mt-2"
                                onClick={() => setSuccess(false)}>
                                닫기
                            </span>
                        </div>
                    )}
                </Box>
            </Modal>
        </motion.div>
    );
}
