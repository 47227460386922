import React, { useCallback, useEffect, useRef, useState } from "react";
import { Header } from "../components/Header";
import { Container, Row } from "react-bootstrap";
import { Wrapper } from "../components/Wrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListUl, GridFill } from "react-bootstrap-icons";
import oxStyles from "../css/ox.module.css";

import {
  currentDate,
  returnDate,
  getYoil,
  getData,
  postData,
  putData,
} from "../common/common";

import defaultImage from "../img/young_defalut_thumb.png";
import { EmptyList } from "../components/EmptyList";
import { useNavigate } from "react-router-dom";
import { Toast } from "../components/Toast";
import { CalendarModal } from "../components/CalendarModal";
import { Paging } from "../components/Paging";

//zustand
import { userinfoStore } from "../zustand/userinfoStore";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import GradeIcon from "@mui/icons-material/Grade";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TestModal from "../components/TestModal";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import { motion } from "framer-motion";

export const Oxnote = () => {
  const componentRef = useRef();

  const user_info = userinfoStore((state) => state.user_info);

  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [scrap, setScrap] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [totalcount, setTotalcount] = useState(0);

  const [msg, setMsg] = useState("");
  const [isToastM, setToastM] = useState(false);

  const [oxType, setType] = useState("오답노트");

  const [impValue, setImpValue] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [isEnd, setEnd] = useState(true);

  const [wordOpen, setWordOpen] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isHide, setHide] = useState(false);

  const wordObj = useRef({
    book: "",
    word: "",
    mean: "",
  });

  const [formError, setFormError] = useState({
    book: "",
    word: "",
    mean: "",
  });

  const [appendType, setAppendType] = useState("단어추가");
  const [bookList, setBookList] = useState([]);

  useEffect(() => {
    wordObj.current.book = "";
    wordObj.current.word = "";
    wordObj.current.mean = "";

    setFormError({
      book: "",
      word: "",
      mean: "",
    });
  }, [appendType]);

  const wordHandleClose = () => setWordOpen(false);
  const wordHandleOpen = () => {
    setWordOpen(true);
  };

  useEffect(() => {
    getBookData();
  }, []);

  useEffect(() => {
    getUserWord();
  }, [isHide, impValue]);
  useEffect(() => {
    getScrap();
  }, [page, keyword, oxType, impValue, shuffle, isHide]);

  const getUserWord = async () => {
    const res = await getData(
      `user_word/${user_info.u_seq}/?uw_hide=${isHide}&impValue=${impValue}`
    );
    setUserData(res.data);
  };

  const getScrap = async () => {
    setEnd(true);

    const res = await getData(
      `/scrap/0/all?u_seq=${user_info.u_seq}&page=${page}&ppp=999999&keyword=${keyword}&oxType=${oxType}&impValue=${impValue}&shuffle=${shuffle}&isHide=${isHide}`
    );
    console.log('res.data.row : ', res.data.row)
    if (res.data.row.length === 0) {
      setEnd(false);
      if (oxType === "기출오답노트" || oxType === "기출스크랩") {
        setData([...res.data.row]);
      }
    } else {
      if (impValue || shuffle || oxType) {
        setData([...res.data.row]);
      } else {
        setData([...data, ...res.data.row]);
      }
    }

    setScrap(res.data.scrap);
    setTotalcount(res.data.totalCount);
  };

  const getBookData = async () => {
    const res = await getData(`/user_book/${user_info.u_seq}`);
    console.log("res.data ??  : ", res.data);
    setBookList(res.data);
  };

  const chnageOxType = (oxText) => {
    setType(oxText);
  };

  const labelArray = ["①", "②", "③", "④", "⑤"];

  const getIcon = (no) => labelArray.filter((v, i) => i + 1 === no && v)[0];

  const onScrap = (e) => {
    if (window.confirm("해당 문제를 스크랩 취소 하시겠습니까?")) {
      const tempScrap = [...scrap];

      let isSCrapCheck = false;
      tempScrap.forEach(function (x) {
        if (Number(x.id) === Number(e)) {
          isSCrapCheck = true;
        }
      });
      if (isSCrapCheck) {
        const filter = tempScrap.filter((v) => v.id !== e);

        setScrap([...filter]);
        setMsg("해당 문제가 스크랩 취소 되었습니다.");
      } else {
        setScrap([...tempScrap, { id: e, type: oxType }]);
        setMsg("해당 문제가 스크랩 되었습니다.");
      }
    }
  };

  useEffect(() => {
    if (msg) {
      scrapHandler();
    }
  }, [msg]);

  const scrapHandler = async () => {
    let obj = {
      u_seq: user_info.u_seq,
      s_group: JSON.stringify(scrap),
    };

    const res = await postData(`/scrap`, { obj: obj });

    if (res.data === "success") {
      getScrap();
      setToastM(true);
    }
  };

  useEffect(() => {
    if (isToastM) {
      setTimeout(() => {
        setMsg("");
        setToastM(false);
      }, 1000);
    }
  }, [isToastM]);

  function commentOpen(q_seq) {
    let tmp = [...data];
    const mapTemp = tmp.map((v) =>
      Number(v.q_seq) === Number(q_seq) ? { ...v, isOpen: !v.isOpen } : v
    );
    setData(mapTemp);
  }

  const [slt, setSlt] = useState(false);
  const [testGroup, setGroup] = useState([]);
  const [testSlt, setTestSlt] = useState("");

  const nextTest = () => {
    handleOpen();
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!open) {
      setGroup([]);
      setTestSlt("");
    }
  }, [open]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();
  const goTest = () => {
    let obj = {};
    let testArray = [];
    testGroup.forEach(function (v) {
      testArray.push(v.id);
    });

    obj.test = JSON.stringify(testArray);

    navigate(`/quickTest/${JSON.stringify(testArray)}`);
  };

  const groupHandler = (count) => {
    setTestSlt(count);
    if (count === "전체") {
      setGroup(arrayShuffle(scrap));
    } else {
      setGroup(arrayShuffle(scrap).slice(0, Number(count)));
    }
  };

  function arrayShuffle(oldArray) {
    var newArray = oldArray.slice();
    var len = newArray.length;
    var i = len;
    while (i--) {
      var p = parseInt(Math.random() * len);
      var t = newArray[i];
      newArray[i] = newArray[p];
      newArray[p] = t;
    }
    return newArray;
  }

  const category = ["영어", "국어"];

  const hideHandler = () => {
    setSlt((prev) => !prev);
  };

  const cookieHandler = async (id) => {
    const temp = scrap.map((v) =>
      v.id === id ? { ...v, imp: v.imp ? false : true } : v
    );

    let obj = {
      u_seq: user_info.u_seq,
      s_group: JSON.stringify(temp),
    };

    const res = await postData(`/scrap`, { obj: obj });

    if (res.data === "success") {
      getScrap();
    }
    //setData(temp.map((v) => (v.q_title === word ? { ...v, imp: true } : v)));
  };

  const userCookieHandler = async (id, imp) => {
    let obj = {
      u_seq: user_info.u_seq,
      uw_imp: imp === 1 ? 0 : 1,
    };

    const res = await putData(`/user_word/${id}`, { obj: obj });

    if (res.data === "success") {
      getUserWord();
    }
    //setData(temp.map((v) => (v.q_title === word ? { ...v, imp: true } : v)));
  };

  const userDeleteHandler = async (id, hid) => {
    let obj = {
      u_seq: user_info.u_seq,
      uw_hide: hid === "N" ? "Y" : "N",
    };

    const res = await putData(`/user_word/${id}`, { obj: obj });

    if (res.data === "success") {
      getUserWord();
    }
  };

  console.log("scrap :", scrap);

  const deleteHandler = async (id) => {
    const temp = scrap.map((v) =>
      v.id === id ? { ...v, hide: v.hide ? false : true } : v
    );

    let obj = {
      u_seq: user_info.u_seq,
      s_group: JSON.stringify(temp),
    };

    const res = await postData(`/scrap`, { obj: obj });

    if (res.data === "success") {
      getScrap();
    }
  };
  const GetWrongPercent = ({ id }) => {
    const [wrong, setWrong] = useState(0);
    useEffect(() => {
      getWrong();
    }, []);
    const getWrong = async () => {
      const res = await getData(`/test_detail/${id}/wrong`);
      setWrong(res.data.wrong);
    };

    return <span className="text-danger">{wrong}</span>;
  };

  const imp = (id) => {
    let isCheck = false;
    scrap.forEach(function (v) {
      if (v.id === id) {
        if (v.imp) isCheck = true;
      }
    });

    return isCheck;
  };

  const fetchMore = () => {
    if (isEnd) {
      setPage(page + 1);
    }
  };

  const goMemorize = () => {
    navigate(`/memorize`, {
      state: { oxType: oxType, shuffle: shuffle, impValue: impValue },
    });
  };

  const appendBook = () => {
    setAppendType((prev) => !prev);
  };

  const appendWord = useCallback(() => {
    //setWordObj
    //setFormError
    console.log("wordObj : ", wordObj);

    let isBook = true,
      isWord = true,
      isMean = true;

    if (appendType === "단어추가") {
      if (!wordObj.current.book) {
        isBook = false;
      }
      if (!wordObj.current.word) {
        isWord = false;
      }
      if (!wordObj.current.mean) {
        isMean = false;
      }

      setFormError({
        ...formError,
        book: isBook ? "" : "교재를 선택해주세요.",
        word: isWord ? "" : "단어를 입력해주세요.",
        mean: isMean ? "" : "뜻을 입력해주세요.",
      });

      if (isBook && isWord && isMean) {
        insertWord();
      }
    } else {
      if (!wordObj.current.book) {
        isBook = false;
      }

      setFormError({
        ...formError,
        book: isBook ? "" : "교재를 입력해주세요.",
      });

      if (isBook) {
        insertBookName();
      }
    }
  }, [appendType]);

  const insertBookName = async () => {
    let obj = {
      ub_book: wordObj.current.book,
      u_seq: user_info.u_seq,
    };
    console.log("obj : ", obj);
    const res = await postData(`/user_book`, { obj: obj });

    if (res.data === "success") {
      alert("교재가 등록되었습니다.");
      getBookData();
    }
  };

  const insertWord = async () => {
    let obj = {
      ub_seq: wordObj.current.book,
      uw_word: wordObj.current.word,
      uw_mean: wordObj.current.mean,
      u_seq: user_info.u_seq,
    };
    console.log("obj : ", obj);
    const res = await postData(`/user_word`, { obj: obj });

    if (res.data === "success") {
      alert("단어가 등록되었습니다.");
      wordHandleClose();
      getUserWord();
    }
  };

  const formHandler = useCallback((e) => {
    const { name, value } = e.target;
    console.log("value  z: ", value);
    console.log("name  z: ", name);
    wordObj.current[name] = value;
  });
  console.log("formError.mean : ", formError.mean);

  const goDelete = () => {
    setDelete((prev) => !prev);
  };

  const widthHandler = () => {
    let str = "w-50";
    if (oxType === "기출오답노트" || oxType === "기출스크랩") {
      str = "w-100";
    }
    return str;
  };

  const SCrap = () => {
    return (
      <>
        <div className={oxStyles.topWrap}>
          <div>
            <span
              className={`${oxStyles.topSpan} ${
                impValue ? oxStyles.spanOn : null
              }`}
              onClick={() => {
                setPage(1);
                setImpValue((prev) => !prev);
              }}
            >
              <GradeIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />
              {oxType !== "기출오답노트" && oxType !== "기출스크랩"
                ? "중요단어"
                : "중요문제"}
            </span>
            {oxType !== "기출오답노트" && oxType !== "기출스크랩" && (
              <span
                className={`${oxStyles.topSpan} ${
                  slt ? oxStyles.spanOn : null
                }`}
                onClick={() => hideHandler()}
              >
                <VisibilityOffIcon
                  sx={{ fontSize: "14px", marginTop: "-2px" }}
                />
                {/* <VisibilityIcon sx={{ fontSize: "18px", color: "black" }} /> */}
                감추기
                {/* VisibilityIcon */}
              </span>
            )}

            <span
              className={`${oxStyles.topSpan} ${
                shuffle ? oxStyles.spanOn : null
              }`}
              onClick={() => setShuffle((prev) => !prev)}
            >
              <ShuffleIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />
              순서섞기
            </span>
            {oxType !== "기출오답노트" && oxType !== "기출스크랩" && (
              <span
                className={oxStyles.topSpan}
                onClick={() => wordHandleOpen()}
              >
                <LibraryAddIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />
                단어추가
              </span>
            )}

            <span
              className={`${oxStyles.topSpan} ${
                isHide ? oxStyles.spanOn : null
              }`}
              onClick={() => setHide((prev) => !prev)}
            >
              <DeleteIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />
              휴지통
            </span>
          </div>
          <div className="d-flex pt-2">
            <div className="w-25">
              <p className="mb-0">
                총 <span className="text-danger">{totalcount}</span> 개
              </p>
            </div>
            <div className="w-75 text-end">
              <span
                className="btn btn-sm btn-secondary me-2"
                style={{ fontSize: "12px" }}
                onClick={() => goDelete()}
              >
                <DeleteIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />{" "}
                {!isHide ? "삭제하기" : "되돌리기"}
              </span>
              {oxType !== "기출오답노트" && oxType !== "기출스크랩" && (
                <span
                  className="btn btn-sm btn-primary me-2"
                  style={{ fontSize: "12px" }}
                  onClick={() => goMemorize()}
                >
                  <SlideshowIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />{" "}
                  암기하기
                </span>
              )}

              <span
                className="btn btn-sm btn-danger"
                style={{ fontSize: "12px" }}
                onClick={() => setOpen((prev) => !prev)}
              >
                <QuizIcon sx={{ fontSize: "14px", marginTop: "-2px" }} />
                시험보기
              </span>
            </div>
          </div>
        </div>
        {/* <div className={oxStyles.test} onClick={() => nextTest()}>
          시험보기
        </div> */}
        <div></div>
        {open && (
          <TestModal
            open={open}
            handleClose={handleClose}
            data={data}
            scrap={scrap}
          />
        )}

        <div>
          <Modal
            open={wordOpen}
            onClose={wordHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="fw-bold mb-4"
              >
                <span
                  className={`pointer ${
                    appendType === "단어추가" ? oxStyles.appendTitle : null
                  }`}
                  onClick={() => setAppendType("단어추가")}
                >
                  단어추가
                </span>
                <span> / </span>
                <span
                  className={`pointer ${
                    appendType === "교재추가" ? oxStyles.appendTitle : null
                  }`}
                  onClick={() => setAppendType("교재추가")}
                >
                  교재추가
                </span>
              </Typography>
              {appendType === "교재추가" && (
                <div>
                  <TextField
                    required
                    id="book"
                    name="book"
                    label="교재명"
                    variant="standard"
                    className="w-100"
                    error={formError.book !== "" || false}
                    onChange={formHandler}
                  />
                  <FormHelperText>{formError.book}</FormHelperText>
                </div>
              )}

              {appendType === "단어추가" && (
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">교재</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="book"
                      label="교재"
                      onChange={formHandler}
                      error={formError.book !== "" || false}
                      className="w-100"
                    >
                      {bookList.length > 0 &&
                        bookList.map((v, i) => (
                          <MenuItem key={i} value={v.ub_seq}>
                            {v.ub_book}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormHelperText>{formError.book}</FormHelperText>
                  <TextField
                    required
                    id="word"
                    name="word"
                    label="단어"
                    variant="standard"
                    className="w-100"
                    error={formError.word !== "" || false}
                    onChange={formHandler}
                  />
                  <FormHelperText>{formError.word}</FormHelperText>
                  <TextField
                    required
                    id="mean"
                    name="mean"
                    label="뜻"
                    variant="standard"
                    className="w-100"
                    error={formError.mean !== "" || false}
                    onChange={formHandler}
                  />
                  <FormHelperText>{formError.mean}</FormHelperText>
                </div>
              )}
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, textAlign: "center" }}
              >
                <span className={oxStyles.mSpan2} onClick={() => appendWord()}>
                  추가하기
                </span>
              </Typography>
            </Box>
          </Modal>
        </div>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMore}
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f4f8f9",
            overflow: "hidden",
          }} //To put endMessage and loader to the top.
          inverse={false} //
          hasMore={isEnd}
          loader={<h4 style={{ background: "#f4f8f9" }}>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          ref={componentRef}
        >
          {data.map((v, i) => (
            <div key={i} className={oxStyles.wrap}>
              <div
                className="d-flex"
                style={{ borderBottom: "1px dotted #ddd", padding: "5px 0" }}
              >
                <div className={`fw-bold ${widthHandler()}`}>
                  {isDelete ? (
                    <HighlightOffIcon
                      sx={{
                        fontSize: "14px",
                        marginTop: "-2px",
                        color: "red",
                      }}
                      onClick={() => deleteHandler(v.q_seq)}
                    />
                  ) : (
                    <GradeIcon
                      sx={{
                        fontSize: "14px",
                        marginTop: "-2px",
                        color: imp(v.q_seq) ? "orange" : "#ddd",
                      }}
                      onClick={() => cookieHandler(v.q_seq)}
                    />
                  )}{" "}
                  {i + 1}. {v.q_title}
                  {!v.isOpen ? (
                    <ZoomInIcon
                      sx={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        marginTop: "-2px",
                        color: "green",
                      }}
                      onClick={() => commentOpen(v.q_seq)}
                    />
                  ) : (
                    <CloseIcon
                      sx={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        marginTop: "-2px",
                        color: "red",
                      }}
                      onClick={() => commentOpen(v.q_seq)}
                    />
                  )}
                </div>
                {v.q_img && (
                  <img src={v.q_img} className="w-100" alt="보기 이미지" />
                )}
                {!slt && oxType !== "기출오답노트" && oxType !== "기출스크랩" && (
                  <div
                    className="w-50"
                    style={{ fontSize: "13px", wordBreak: "keep-all" }}
                  >
                    {v.q_mean}
                  </div>
                )}
              </div>

              {v.isOpen && (
                <div className={`${oxStyles.comment} p-2`}>
                  <div className="mb-1">
                    [오답률] <GetWrongPercent id={v.q_seq} /> %
                  </div>
                  <div className="mb-1">[예문] {v.q_explain}</div>
                  <div className="mb-1">[해설] {v.q_comment}</div>
                </div>
              )}
            </div>
          ))}

          {oxType !== "기출오답노트" &&
            oxType !== "기출스크랩" &&
            userData.map((v, i) => (
              <div key={i} className={oxStyles.wrap}>
                <div
                  className="d-flex"
                  style={{ borderBottom: "1px dotted #ddd", padding: "5px 0" }}
                >
                  <div className="fw-bold w-50">
                    {isDelete ? (
                      <HighlightOffIcon
                        sx={{
                          fontSize: "14px",
                          marginTop: "-2px",
                          color: "red",
                        }}
                        onClick={() => userDeleteHandler(v.uw_seq, v.uw_hide)}
                      />
                    ) : (
                      <GradeIcon
                        sx={{
                          fontSize: "14px",
                          marginTop: "-2px",
                          color: v.uw_imp ? "orange" : "#ddd",
                        }}
                        onClick={() => userCookieHandler(v.uw_seq, v.uw_imp)}
                      />
                    )}{" "}
                    {i + +data.length + 1}. {v.uw_word}
                    {/* {!v.isOpen ? (
                    <ZoomInIcon
                      sx={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        marginTop: "-2px",
                        color: "green",
                      }}
                      onClick={() => commentOpen(v.uw_seq)}
                    />
                  ) : (
                    <CloseIcon
                      sx={{
                        fontSize: "16px",
                        marginLeft: "4px",
                        marginTop: "-2px",
                        color: "red",
                      }}
                      onClick={() => commentOpen(v.uw_seq)}
                    />
                  )} */}
                  </div>
                  {!slt && (
                    <div
                      className="w-50"
                      style={{ fontSize: "13px", wordBreak: "keep-all" }}
                    >
                      {v.uw_mean}
                    </div>
                  )}
                </div>

                {/* {v.isOpen && (
                <div className={`${oxStyles.comment} p-2`}>
                  <div className="mb-1">
                    [오답률] <GetWrongPercent id={v.uw_seq} /> %
                  </div>
                  <div className="mb-1">[예문] {v.q_explain}</div>
                  <div className="mb-1">[해설] {v.q_comment}</div>
                </div>
              )} */}
              </div>
            ))}
        </InfiniteScroll>
      </>
    );
  };

  const toastMsg = () => {
    if (!msg) {
      return (
        <div className="toast-div">
          <div>수고하셨습니다!</div>
          <div>결과조회 페이지에서 확인이 가능합니다.</div>
        </div>
      );
    } else {
      return (
        <div className="toast-div">
          <div>{msg}</div>
        </div>
      );
    }
  };

  const dropDownHander = (e) => {
    if (e.target.value === "단어장") {
      setType("오답노트");
    } else {
      setType("기출오답노트");
    }
  };

  return (
    <motion.div
    className="oxPage"
    intial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    style={{ overflowY: "hidden", position: "relative" }}
    >
      <ReactToPrint
        trigger={() => (
          <div className={oxStyles.print}>
            <PrintIcon />
          </div>
        )}
        content={() => componentRef.current}
      />

      <Header centerText="단어장" dropDown dropDownHander={dropDownHander} />
      <div className={oxStyles.keywordWrap}>
        <div
          className="text-center"
          style={{ display: "flex", background: "#fff" }}
        >
          <div
            className={`w-50 py-2 pointer ${
              oxType === "오답노트" ? oxStyles.oxOn : null
            }`}
            onClick={() => chnageOxType("오답노트")}
          >
            오답노트
          </div>
          <div
            className={`w-50 py-2 pointer ${
              oxType === "스크랩" ? oxStyles.oxOn : null
            }`}
            onClick={() => chnageOxType("스크랩")}
          >
            스크랩
          </div>
        </div>
        {/* <input
          type="text"
          className={`form-control ${oxStyles.keyword}`}
          placeholder="키워드를 입력해주세요."
          onChange={(e) => onKeyword(e)}
        /> */}
      </div>
      <Wrapper margin={187} addClass>
        <Row>
          <SCrap />

          {/* <Paging
            activePage={page}
            itemsCountPerPage={10}
            totalItemsCount={totalcount}
            onChange={goPage}
          ></Paging> */}
        </Row>
      </Wrapper>
      {isToastM && (
        <Toast
          text={() => toastMsg("")}
          type="info"
          time={2000}
          isToast={isToastM}
          setToast={setToastM}
        />
      )}
    </motion.div>
  );
};
