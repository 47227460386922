/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Form, Container, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import loginBg from "../img/login/login_bg.png";
import { instance, getData } from "../common/common";
import loginStyles from "../css/login.module.css";

export function Findid(props) {
  const type = useParams();
  const [data, setData] = useState({
    u_name: "",
    u_phone: "",
    u_id: "",
    c_seq: "",
  });

  const { u_name, u_phone, u_id, c_seq } = data;

  const SignupOnChange = (e) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [name]: value,
    });
  };

  const [centerList, SetCenterList] = useState([]);
  async function fetchCenterData() {
    const res = await getData(`center`);
    SetCenterList(res.data);
  }

  useEffect(() => {
    fetchCenterData();
  }, []);

  function FindClick() {
    if (!u_name) {
      alert("성명을 입력해주세요.");
      return false;
    } else if (type.id === "pass" && !u_id) {
      alert("아이디를 입력해주세요.");
      return false;
    } else if (!c_seq) {
      alert("지점센터를 선택해주세요.");
      return false;
    } else {
      if (
        window.confirm(
          `${type.id === "id" ? "아이디" : "비밀번호"}를 찾으시겠습니까?`
        )
      ) {
        instance
          .post(`/user/find`, {
            u_name: u_name,
            u_id: u_id,
            c_seq: c_seq,
          })
          .then((res) => {
            if (res.data) {
              if (type.id === "id") {
                alert(`아이디는 ${res.data.u_id}입니다.`);
              } else {
                window.location.replace(`/newpass/${res.data.u_seq}`);
              }
            } else {
              alert(`회원정보를 찾을 수 없습니다.`);
            }
          });
      }
    }
  }

  return (
    <div className={loginStyles.wrap}>
      <div className={loginStyles.white_box}>
        <h1>0823 OLS</h1>
        <Form.Group className="mb-3">
          <Form.Label className="mb-3">
            {type.id === "id" ? "아이디" : "비밀번호"} 찾기
          </Form.Label>
          <Form.Control
            className="mb-3"
            placeholder="성명"
            name="u_name"
            type="text"
            onChange={(e) => SignupOnChange(e)}
          />
          <Form.Select
            className="mb-3"
            placeholder="지점센터"
            name="c_seq"
            onChange={(e) => SignupOnChange(e)}
          >
            <option value="">지점센터 선택</option>
            {centerList.map((centerlist, i) => (
              <option key={i} value={centerlist.c_seq}>
                {centerlist.c_name}
              </option>
            ))}
          </Form.Select>
          {type.id === "pass" && (
            <Form.Control
              className="mb-3"
              placeholder="아이디"
              name="u_id"
              type="text"
              onChange={(e) => SignupOnChange(e)}
            />
          )}
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="w-100"
          onClick={() => FindClick()}
        >
          찾기
        </Button>
        <hr />
        <div className="my-2 mt-4">
          <Link to="/login">
            <span className={`${loginStyles.fs_14} text-secondary`}>
              로그인 하기
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
