import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// pages
import { Main } from "./pages/Main";
import { Page } from "./pages/Page";
import { Login } from "./pages/Login";
import { Test } from "./pages/Test";
import { Test_ju } from "./pages/Test_ju";

import { Signup } from "./pages/Signup";
import { Findid } from "./pages/Findid";
import { Newpass } from "./pages/Newpass";
import { Mypage } from "./pages/Mypage";
import { List } from "./pages/List";
import { Result } from "./pages/Result";
import { Oxnote } from "./pages/Oxnote";
import { Qna } from "./pages/Qna";
import { QnaDetail } from "./pages/QnaDetail";
import { QnaWrite } from "./pages/QnaWrite";
import { Audio } from "./components/Voice";
import { Work } from "./pages/Work";
import { Faq } from "./pages/Faq";
import { Notice } from "./pages/Notice";
import { NoticeDetail } from "./pages/NoticeDetail";
import { BottomNavi } from "./components/BottomNavi";
import { Quiz } from "./pages/Quiz";
import { BookDetailView } from "./pages/BookDetailView";
import { BookApply } from "./pages/BookApply";
import { Test2 } from "./pages/Test2";
import { Test3 } from "./pages/Test3";
import { AllTest } from "./pages/AllTest";
import { QuickTest } from "./pages/QuickTest";
import { All } from "./pages/All";
import { Memorize } from "./pages/Memorize";
import { Gichul } from "./pages/Gichul";
import { Home } from "./pages/Home";
import { MoDetailView } from "./pages/MoDetailView";

import { AnimatePresence } from "framer-motion";

export function RoutesList(props) {
  const list = [
    {
      id: "home",
      path: "/",
      element: <Home />,
    },
    {
      id: "main",
      path: "/main",
      element: <Main />,
    },
    {
      id: "gichul",
      path: "/gichul",
      element: <Gichul />,
    },
    {
      id: "login",
      path: "/login",
      element: <Login />,
    },
    {
      id: "page",
      path: "/page/:id",
      element: <Page />,
    },
    {
      id: "test",
      path: "/test/:id",
      element: <Test />,
    },
    {
      id: "test_ju",
      path: "/test_ju/:id",
      element: <Test_ju />,
    },
    {
      id: "ox",
      path: "/test/:id/:type",
      element: <Test />,
    },
    {
      id: "link",
      path: "/test/:id/:type/:target",
      element: <Test />,
    },
    {
      id: "inspect",
      path: "/inspect/:id",
      element: <Test2 />,
    },
    {
      id: "testresult",
      path: "/result/:id/:type",
      element: <Test3 />,
    },
    {
      id: "allTest",
      path: "/allTest/:bid/:id",
      element: <AllTest />,
    },
    {
      id: "quickTest",
      path: "/quickTest/:id",
      element: <QuickTest />,
    },
    {
      id: "signup",
      path: "/signup/:id",
      element: <Signup />,
    },
    {
      id: "findid",
      path: "/findid/:id",
      element: <Findid />,
    },
    {
      id: "newpass",
      path: "/newpass/:id",
      element: <Newpass />,
    },
    {
      id: "mypage",
      path: "/mypage",
      element: <Mypage />,
    },
    {
      id: "list",
      path: "/list",
      element: <List />,
    },
    {
      id: "quiz",
      path: "/quiz",
      element: <Quiz />,
    },
    {
      id: "all",
      path: "/all",
      element: <All />,
    },
    {
      id: "result",
      path: "/result",
      element: <List />,
    },
    {
      id: "oxnote",
      path: "/oxnote",
      element: <Oxnote />,
    },
    {
      id: "memorize",
      path: "/memorize",
      element: <Memorize />,
    },
    {
      id: "qna",
      path: "/qna/:type",
      element: <Qna />,
    },
    {
      id: "qna_detail",
      path: "/qnaDetail/:type/:id",
      element: <QnaDetail />,
    },
    {
      id: "qna_write",
      path: "/qnaWrite/:type/:tid/:id",
      element: <QnaWrite />,
    },
    {
      id: "work",
      path: "/work",
      element: <Work />,
    },
    {
      id: "faq",
      path: "/faq",
      element: <Faq />,
    },
    {
      id: "notice",
      path: "/notice",
      element: <Notice />,
    },
    {
      id: "notice_detail",
      path: "/notice_detail/:id",
      element: <NoticeDetail />,
    },
    {
      id: "BookDetailView",
      path: "/BookDetailView/:id/:type",
      element: <BookDetailView />,
    },
    {
      id: "BookApply",
      path: "/BookApply/:id",
      element: <BookApply />,
    },
    {
      id: "MoDetailView",
      path: "/MoDetailView/:id",
      element: <MoDetailView />,
    },
  ];

  const location = useLocation();

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          {list.map((v) => (
            <Route key={v.id} path={v.path} element={v.element} />
          ))}
        </Routes>
      </AnimatePresence>

      {window.location.href.indexOf("login") !== -1 ||
      window.location.href.indexOf("test") !== -1 ||
      window.location.href === "http://localhost:3000/" ||
      window.location.href === "https://young-ga.com:81/" ||
      window.location.href === "https://young-ga.com/" ? null : (
        <BottomNavi />
      )}
    </>
  );
}
