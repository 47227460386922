import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Modal, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    id: "time",
    label: "시간 설정",
    type: "number",
  },
  {
    id: "scope",
    label: "범위 설정",
    type: "number",
  },
  {
    id: "count",
    label: "갯수 설정",
    type: "number",
  },
];

export default function TestModal({ open, handleClose, data, scrap }) {
  const testData = React.useRef({
    time: 20,
    scope_start: 0,
    scope_end: 0,
    count: 0,
  });

  const formHandler = (e) => {
    const { name, value } = e.target;
    console.log("value : ", value);
    testData.current[name] = value;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const navigate = useNavigate();

  const goTest = () => {
    console.log("testData : ", testData.current);
    let isNext = true;
    if (
      testData.current.scope_start === 0 ||
      testData.current.scope_end === 0
    ) {
      isNext = false;
      alert("범위를 입력해주세요.");
    } else if (!testData.current.count) {
      isNext = false;
      alert("갯수를 입력해주세요.");
    }

    if (testData.current.scope_start && testData.current.scope_end) {
      if (testData.current.scope_end * 1 < testData.current.scope_start * 1) {
        isNext = false;
        alert("종료 범위를 시작 범위 보다 큰 수를 입력해주세요.");
      }
    }

    if (isNext) {
      console.log("시험 ㄱㄱ : ", data);
      //console.log("시험 ㅣ: 2 ;", scrap);

      const filterData = data.slice(
        testData.current.scope_start - 1,
        testData.current.scope_end
      );

      const completedArray = arrayShuffle(filterData).slice(
        0,
        testData.current.count
      );
      console.log("completedArray : ", completedArray);

      let testArray = [];
      completedArray.forEach(function (v) {
        testArray.push(v.q_seq);
      });

      navigate(`/quickTest/${JSON.stringify(testArray)}`);

      function arrayShuffle(oldArray) {
        var newArray = oldArray.slice();
        var len = newArray.length;
        var i = len;
        while (i--) {
          var p = parseInt(Math.random() * len);
          var t = newArray[i];
          newArray[i] = newArray[p];
          newArray[p] = t;
        }
        return newArray;
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="fw-bold mb-3"
        >
          시험 보기
        </Typography>
        {/* <div className="mb-2">
          <TextField
            id={"time"}
            name={"time"}
            label={"시간 설정"}
            variant="standard"
            className="w-100"
            type={"number"}
            onChange={formHandler}
          />
        </div> */}
        <div
          className="d-flex mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="text-start" style={{ width: "45%" }}>
            <TextField
              id={"scope_start"}
              name={"scope_start"}
              label={"시작 범위 설정(1)"}
              variant="standard"
              className="w-100"
              type={"number"}
              onChange={formHandler}
            />
          </div>
          <div className="text-end" style={{ width: "45%" }}>
            <TextField
              id={"scope_end"}
              name={"scope_end"}
              label={`종료 범위 설정(${scrap.length})`}
              variant="standard"
              className="w-100"
              type={"number"}
              onChange={formHandler}
            />
          </div>
        </div>
        <div className="mb-2">
          <TextField
            id={"count"}
            name={"count"}
            label={"갯수 설정"}
            variant="standard"
            className="w-100"
            type={"number"}
            onChange={formHandler}
          />
        </div>
        <div>
          <span className="btn btn-success w-100 mt-4" onClick={goTest}>
            시험 시작
          </span>
        </div>
      </Box>
    </Modal>
  );
}
