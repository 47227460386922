import React, { useEffect, useState } from "react";
import Calendar from "react-widgets/Calendar";
import "react-widgets/styles.css";
import { getDate } from "../common/common";

export const CalendarModal = ({ value, setValue, onChange = null }) => {
  const [isNavi, setNavi] = useState(false);
  useEffect(() => {
    let arr = document.getElementsByClassName("rw-cell");
    console.log("arr :", arr);
    for (let i = 0; i < arr.length; i++) {
      arr[i].children[0].innerHTML = arr[i].children[0].innerHTML.replace(
        "일",
        " "
      );
    }

    let yoil = document.getElementsByClassName("rw-head-cell");
    for (let i = 0; i < yoil.length; i++) {
      if (yoil[i].innerText === "일") {
        yoil[i].classList.add("text-danger");
      }

      if (yoil[i].innerText === "토") {
        yoil[i].classList.add("text-purple");
      }
    }

    let days = document.getElementsByClassName("rw-calendar-row");

    for (let i = 1; i < days.length; i++) {
      for (let j = 0; j < days[i].children.length; j++) {
        //console.log("days[" + i + "].children : ", days[i].children[0]);
        if (!days[i].children[0].classList.contains("rw-cell-off-range")) {
          days[i].children[0].classList.add("text-danger");
        }

        if (!days[i].children[6].classList.contains("rw-cell-off-range")) {
          days[i].children[6].classList.add("text-purple");
        }
      }
    }
  }, [isNavi]);

  const onNavigate = (e, e2, e3) => {
    console.log("e :", e);
    console.log("e2 :", e2);
    console.log("e3 :", e3);
    if (e3 === "month") {
      setNavi((prev) => !prev);
    }
  };
  if (onChange) {
    return (
      <Calendar
        value={value}
        onChange={onChange}
        onNavigate={(e, e2, e3) => onNavigate(e, e2, e3)}
      />
    );
  } else {
    return (
      <Calendar
        value={value}
        onChange={(value) => setValue(getDate(value))}
        onNavigate={(e, e2, e3) => onNavigate(e, e2, e3)}
      />
    );
  }
};
