import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getData, postData } from "../common/common";
import useMoveScroll from "../hook/UseMoveScroll";
import { useEffect } from "react";

import { userinfoStore } from "../zustand/userinfoStore";

function Row(props) {
  const { row, u_seq, isOpen } = props;
  const [open, setOpen] = React.useState(isOpen);

  const user_info = userinfoStore((state) => state.user_info);

  const times = (t) => {
    return t.replace("회차", "");
  };

  const [per, setPer] = React.useState("");
  async function loadPer() {
    let arr2 = row.list.map((v) => v.t_seq);
    const res = await postData(`/test_user/totalList/per`, {
      obj: arr2,
      u_seq: user_info.u_seq,
    });
    setPer(res.data);
  }

  React.useEffect(() => {
    loadPer();
  }, [open]);

  const TestInfo = ({ vv, index }) => {
    const [tData, settData] = React.useState({
      wrong: 0,
      correct: 0,
    });

    const getInfo = async () => {
      const res = await getData(
        `/test_detail/${vv.t_seq}/total?u_seq=${u_seq}`
      );

      settData(res.data);
    };

    React.useEffect(() => {
      getInfo();
    }, []);

    const myRef = React.createRef();

    // React.useEffect(() => {
    //   myRef?.current?.scrollIntoView();
    // }, []);
    const colorTd = (isColor) => {
      let str = { color: "#ddd" };
      if (isColor > 0) {
        str = { color: "blue" };
      }

      return str;
    };
    return (
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          style={colorTd(tData.wrong + tData.correct)}
        >
          {times(vv.t_times)}
        </TableCell>
        <TableCell style={colorTd(tData.wrong + tData.correct)}>
          {tData.wrong + tData.correct}
        </TableCell>
        <TableCell style={colorTd(tData.wrong + tData.correct)}>
          {tData.correct}
        </TableCell>
        <TableCell style={colorTd(tData.wrong + tData.correct)}>
          {tData.wrong}
        </TableCell>
        <TableCell style={colorTd(tData.wrong + tData.correct)}>
          {vv.t_start_date}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell sx={{ width: "60px", padding: 0, background: "purple" }}>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon style={{ color: "#fff" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="center"
          sx={{
            padding: 0,
            background: "purple",
            color: "#fff",
            fontSize: "13px",
          }}
        >
          [{row.hd}] {row.curri}{" "}
          <span className="ms-4">정답률 : {per || "ꞏꞏꞏ"} %</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                style={{
                  marginBottom: "14px",
                  // border: "1px solid red",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>회차</TableCell>
                    <TableCell>전체문제</TableCell>
                    <TableCell>맞은개수</TableCell>
                    <TableCell>틀린개수</TableCell>
                    <TableCell>시험일자</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.list.map((vv, i) => (
                    <TestInfo vv={vv} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export const CollapseTable = ({ row, u_seq, isOpen }) => {
  return (
    <>
      <TableContainer component={Paper} className="mb-3">
        <Table aria-label="collapsible table">
          {/* <TableHead>
            <TableRow>
              <TableCell style={{ background: "purple" }} />
              <TableCell
                align="center"
                style={{ background: "purple" }}
              ></TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            <Row row={row} u_seq={u_seq} isOpen={isOpen} />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
