import create from "zustand";
import { persist } from "zustand/middleware";

export const userinfoStore = create(
  persist(
    (set) => ({
      user_info: [],
      setUserinfo: (info) => set(() => ({ user_info: info })),
    }),
    {
      name: "user_info", // unique name
      getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
    }
  )
);
